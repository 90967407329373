import React, {useState, useEffect, useRef, useMemo, useCallback, lazy, Suspense} from "react";
import ClearIcon from "@mui/icons-material/Clear";
import {Popover, Chip} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {useParams} from "react-router-dom";
import Modal from "react-modal";
import "./CryptoPage.css"; // Assuming you have a CSS file for styling
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import {styled} from "@mui/material/styles";
import {ReactComponent as NotificationIcon} from "../pages/account/profile/profile_icon/notification-icon.svg";

import TableBody from "@mui/material/TableBody";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import EmailPopUp from "./EmailPopUp.js";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import {BottomNavigation, Button} from "@mui/material";
import SidebarMenuList from "../includes/sidebarMenuList.jsx";

import IconButton from "@mui/material/IconButton";
import {Grid} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import {useLocation, Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import LoginPopUp from "./loginpopup";
import {Tabs, Tab} from "@mui/material";
import {getCryporCurrencyListHome} from "../apihelper/homepage";

import Dialog from "@mui/material/Dialog";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {Card, TextField} from "@mui/material";
import {updateCryptoWatchlist} from "../apihelper/profile";
import {getCryptoWatchlistAPIView} from "../apihelper/homepage";
import {DialogTitle, DialogContent, DialogActions} from "@mui/material"; // Import Material-UI components

import {addToPortfolio, copyToPortfolio} from "../apihelper/portfolio";

import CloseIcon from "@mui/icons-material/Close";

import CreatableSelect from "react-select/creatable";
import {getTotalunit, getFetchdropdown} from "../apihelper/cryptocurrencylist";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
// import Footer from "../common/footer/Footer";
import MetaProperty from "../common/meta_property/MetaProperty";

  const SkeletonLoaderMobile = () => {
    return (
      <div
        style={{
          background: "linear-gradient(180deg, rgba(24, 119, 242, 0.05) 0%, rgba(255, 255, 255, 1) 100%)",
          borderRadius: "12px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          border: "1.5px solid #1877F2",
          margin: "20px 0",
          fontFamily: "Arial, sans-serif",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {/* Forecaster Name */}
        <div
          style={{
            fontWeight: "bold",
            fontSize: "16px",
            marginBottom: "0px",
            color: "#000",
            borderBottom: "4px solid #1877F2",
            padding:"10px"
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "35px",
                height: "35px",
                borderRadius: "50%",
                background: "#ddd",
                marginRight: "8px",
              }}
            ></div>
            <div
              style={{
                width: "120px",
                height: "16px",
                background: "#ddd",
                borderRadius: "5px",
              }}
            ></div>
          </div>
          
        </div>
  
        {/* Content Skeleton */}
        <div style={{ padding: "15px", display: "flex", flexDirection: "column", gap: "10px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "100px", height: "14px", background: "#ddd", borderRadius: "5px" }}></div>
            <div style={{ width: "60px", height: "14px", background: "#ddd", borderRadius: "5px" }}></div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "120px", height: "14px", background: "#ddd", borderRadius: "5px" }}></div>
            <div style={{ width: "80px", height: "14px", background: "#ddd", borderRadius: "5px" }}></div>
          </div>
          <div style={{ width: "140px", height: "14px", background: "#ddd", borderRadius: "5px" }}></div>
        </div>
      </div>
    );
  };
  
  
  

  const SkeletonLoaderMobileList = () => {
    return (
      <>
        {[...Array(5)].map((_, index) => (
          <SkeletonLoaderMobile key={index} />
        ))}
      </>
    );
  };
  

  const MobileForecastCard = ({ data }) => {
   
    return (
      <div
        style={{
          background: "linear-gradient(180deg, rgba(24, 119, 242, 0.05) 0%, rgba(255, 255, 255, 1) 100%)",
          borderRadius: "12px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          border: "1.5px solid #1877F2",
          margin: "20px 0",
          fontFamily: "Arial, sans-serif",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {/* Forecaster Name */}
        <div
          style={{
            fontWeight: "bold",
            fontSize: "16px",
            marginBottom: "0px",
            color: "#000",
            borderBottom: "4px solid #1877F2",
          }}
        >
          <div style={{
            background:" var(--try-gradient, linear-gradient(90deg, rgba(24, 119, 242, 0.17) 0.02%, rgba(24, 119, 242, 0.00) 123.27%))",
            borderRadius:"20px",
            padding:"7px 10px", display:"flex",
            justifyContent:"space-between"}}>
        
                
                {data.forecaster_name}
          
          </div>
        </div>
  
        {/* Target & % Achieved */}
        <div style={{ display: "flex", justifyContent: "space-between", fontSize: "14px", fontWeight: "500", color: "#000",marginLeft:"10px",marginRight:"10px",marginTop:"10px" }}>
          <span>Target {data.forecast_price}</span>
          <span>%Target Achieved {data.target_percent}</span>
        </div>
  
        {/* Past Accuracy & Year */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "14px",
            fontWeight: "500",
            color: "#000",
            marginTop: "5px",
            marginLeft:"10px",
            marginRight:"10px",
            marginBottom :"10px"
          }}
        >
          <span>Past Accuracy {data.accuracy}</span>
          <span>Forecast For {data.target_date}</span>
        </div>
      </div>
  );
};

const drawerWidth = 292;
const CryptoPage = (props) => {
    const {window1} = props;
    const {crypto} = useParams();
    const [dropdownVisible, setDropdownVisible] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortType, setSortType] = useState("fd_asc");
    const [sortDropdownVisible, setSortDropdownVisible] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [loader, setLoader] = useState(false);
    const [slug, setSlug] = useState(false);
    const [tfhrs, settfhrs] = useState("");
    const [sevenDays, setSevenDays] = useState(false);
    const [thirtyDays, setThirtyDays] = useState(false);
    const [yearDays, setYearDays] = useState(false);
    const [buyPercent, setBuyPercent] = useState(false);
    const [holdings, setHoldings] = useState(false);
    const [description, setDescription] = useState("");
    const [symbolLogo, setSymbolLogo] = useState(false);
    const [currentPrice, setCurrentPrice] = useState(false);
    const [marketCap, setMarketCap] = useState(false);
    const [rowsToShow, setRowsToShow] = useState(2);
    const [selectedSort, setSelectedSort] = useState("");
    const [hashtags, setHashtags] = useState(false);
    const sortRef = useRef();
    const navigate = useNavigate();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const location = useLocation();
    const loc = location.pathname;
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const [isLoginModalOpen, setLoginModalOpen] = useState(false);
    const [cryptoName, setCryptoName] = useState(false);
    const [sortOrder, setSortOrder] = useState({
        // Track ascending/descending for each column
        forecaster: "asc",
        target: "asc",
        accuracy: "asc",
        percent: "asc",
        targetDate: "asc",
    });
    const [isAtBottom, setIsAtBottom] = useState(false);
    const formattedCrypto = crypto.charAt(0).toUpperCase() + crypto.slice(1);


    // Render row based on the action color
    const renderAction = (action) => {
        switch (action) {
            case "Buy":
            case "Low":
            case "Bullish":
            case "bullish":
            case "Partially Bullish":
            case "Outperformer":
            case "Strong Buy":
                return <span style={{color: "green", fontWeight: ""}}> {capitalizeFirstLetter(action)}</span>;

            case "Sell":
            case "High":
            case "Bearish":
            case "Partially Bearish":
            case "Underperformer":
            case "Strong Sell":
                return <span style={{color: "red", fontWeight: ""}}> {capitalizeFirstLetter(action)}</span>;
            case "Neutral":
            
            case "Moderate Performer":
                return <span style={{color: "orange", fontWeight: ""}}>Neutral</span>;
            case "Hold":
                return <span style={{color: "orange", fontWeight: ""}}>Hold</span>;
            case "Medium":
                return <span style={{color: "orange", fontWeight: ""}}>Medium</span>;

            default:
                return null;
        }
    };

    function capitalizeFirstLetter(text) {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    }

    const mediaQueryVar = useMediaQuery(
        json2mq({
            minWidth: 900,
        })
    );

    function formatDate(dateString) {
        if (!dateString) return ""; // Handle cases where dateString is undefined or null
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0"); // Add leading zero to day
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero to month (0-based index)
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    const handleLoginClick = () => {
        // Mask the URL by adding /login at the end without navigating
        window.history.pushState({}, "", "/login");
        localStorage.setItem("isPopupManuallyOpened", "true");
        // Open the login modal
        setLoginModalOpen(true);
    };
    const handleModalClose = () => {
        // Close the modal and reset the URL to the welcome page
        setLoginModalOpen(false);
        navigate(location.pathname, {replace: true});
    };

    const mediaQueryVar1 = useMediaQuery(
        json2mq({
            minWidth: 400,
        })
    );

    const StyledTableCell = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black,
            border: 0,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            border: 0,
        },
    }));

    const StyledTableRow = styled(TableRow)(({theme}) => ({
        // hide last border

        "& td, & th": {
            border: 0,
        },
    }));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div className="d-flex flex-column h-100" style={{overflowY: "hidden"}}>
            <SidebarMenuList />
        </div>
    );

    const [predictionLoader, setPredictionLoader] = useState(false);
    const [oscillatorData, setOscillatorData] = useState(false);
    const [movingAveragesData, setMovingAveragesData] = useState(false);
    const [timestamp, setTimestamp] = useState("");
    const [pivotdata, setpivotdata] = useState("");
    const [evaluation_data, setevaluation_data] = useState("");
    const [analysis_data, setanalysis_data] = useState("");
    const [outlookData, setOutlookData] = useState("");
    const [paid_member, setpaid_member] = useState("no");
    const [currentCrypto, setCurrentCrypto] = useState();
    const [category, setCategory] = useState();
    const [CryptoWatchlist, setCryptoWatchlist] = useState([]);
    const [starredCryptos, setStarredCryptos] = useState({});

     const getCryptoWatchlist = useCallback(() => {
            if (userData) {
                getCryptoWatchlistAPIView({
                    user_id: userData.user_id,
                    Token: userData.token,
                }).then((res) => {
                    if (res.code === 200) {
                        setCryptoWatchlist(Array.isArray(res.data) ? res.data : []);
    
                    } else {
                        console.error("Data fetching error");
                    }
                }).catch((error) => console.error("Error fetching watchlist", error));
            }
        }, [userData]); // Add userData as dependency
        
        useEffect(() => {
            getCryptoWatchlist();
        }, [getCryptoWatchlist]);
        
        useEffect(() => {
            const savedStarredCryptos = JSON.parse(localStorage.getItem("starred_cryptos")) || {};
            setStarredCryptos(savedStarredCryptos);
        }, []);
    const handleNavigation = () => {
        const userData = localStorage.getItem("cw_portfolio_user");
        if (!userData) {
            const originalUrl = "/subscriptions/screener360";
            sessionStorage.setItem("originalUrl", originalUrl);
            window.history.pushState({}, "", "/login");
            localStorage.setItem("isPopupManuallyOpened", "true");

            // Open the login modal
            setLoginModalOpen(true);
            //window.location.href = `${process.env.REACT_APP_HOME_URL}subscriptions/screener360`;

            return;
        } else if (userData && paid_member == "no") {
            window.location.href = `${process.env.REACT_APP_HOME_URL}subscriptions/screener360`;
        }
    };
    const [votes, setVotes] = useState({ bullish: 0, bearish: 0 });
    const [totalVotes, setTotalVotes] = useState(0);
    const [showPercentage, setShowPercentage] = useState(false);

  

    const handleSentimentClick = async (type) => {
        const lastVoteTime = localStorage.getItem(`lastVoteTime_${slug}`);
      
        if (lastVoteTime) {
            const timeDifference = Date.now() - parseInt(lastVoteTime, 10);
            if (timeDifference < 24 * 60 * 60 * 1000) {
                setShowPercentage(true);
                alert(`You have already voted for ${slug}. You can vote again in 24 hours.`);
                return;
            }
        }
    
        try {
            // Send vote to WordPress API
            const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/updateCryptoVotes', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ symbol: slug, type }),
            });
    
            const data = await response.json();
            console.log("doge response:",data)
    
            if (data.error) {
                alert(data.error);
                return;
            }
    
            
            setVotes({ bullish: data.bullish_percentage, bearish: data.bearish_percentage });
            setShowPercentage(true);
    
            // Store vote timestamp in localStorage
            localStorage.setItem(`lastVoteTime_${slug}`, Date.now().toString());
        } catch (error) {
            console.error("Error submitting vote:", error);
            alert("Something went wrong. Please try again later.");
        }
    };
    
    const calculatePercentage = (type) => {
    
        const votePercentage = type === "bullish" ? votes.bullish : votes.bearish;
        return votePercentage;
    };
    
    const [hashtags2, setNewHashtags] = useState(false);
    const [risk, setRisk] = useState(null);
    const [top100Holders, setTop100Holders] = useState(null);   

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        const userId = userData?.user_id; // Use optional chaining to safely access user_id
        const cameFromAddToPortfolio = sessionStorage.getItem("loginFromAddToPortfolio");

        const fetchData = async () => {
            try {
                const response = await fetch(
                    "https://crowdwisdom.live/wp-json/api/v1/showForecasterFutureDataInvesting",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            searchVal: crypto, // Adjust if necessary
                            sortType: sortType,
                            userId: userId || null, // Send null if userId is not available
                        }),
                    }
                );

                const result = await response.json();
                setCurrentCrypto(result);
                if (result.code === "200") {
                    if (result.response.length === 0) {
                        setPredictionLoader(true);
                    }
                    console.log("response",result);
                    setLoader(true);
                    setSlug(result.symbol);
                    setCurrentPrice(result.current_price);
                    setSymbolLogo(result.symbol_logo);
                    settfhrs(result.tfhrs);
                    setSevenDays(result.last_7_days);
                    setThirtyDays(result.last_30_days);
                    setYearDays(result.last_365_days);
                    setBuyPercent(result.buy_percent);
                    setHoldings(result.holdings);
                    setDescription(result.crypto_desc);
                    setMarketCap(result.market_cap);
                    setHashtags(result.hashtags);
                    setData(result.response);
                    setpaid_member(result.paid_member);
                    setOscillatorData(result.oscillator_data); // Store oscillator data
                    setMovingAveragesData(result.moving_averages_data); // Store moving averages data
                    setTimestamp(result.timestamp);
                    setpivotdata(result.pivot_data);
                    setevaluation_data(result.evaluation_data);
                    setanalysis_data(result.analysis_data);
                    setOutlookData(result.outlook_data);
                    setCryptoName(result.crypto_name);
                    setCategory(result.category);
                    setVotes({ bullish: result.bullish, bearish: result.bearish });
                    setTotalVotes(result.totalVotes);
                    setNewHashtags(result.new_hashtags || []);
                    setRisk(result.risk); // Set the risk value
                    setTop100Holders(result.top_100_holders); 


                    const storedData = JSON.parse(sessionStorage.getItem("slugDataForPortfolio"));
                    if (storedData) {
                        setSlugpopupdata(storedData);
                        setOpen(true);
                    }
                    // Clear after restoring
                    sessionStorage.removeItem("loginFromAddToPortfolio");
                    sessionStorage.removeItem("slugDataForPortfolio");
                    
                } else {
                    setError(result.message);
                }
            } catch (err) {
                setError("An error occurred while fetching data.");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [crypto, sortType]);
    


    const container = window1 !== undefined ? () => window1().document.body : undefined;
    useEffect(() => {
        if (typeof window !== "undefined") {
            const path = window.location.pathname;
            const extractedSlug = path.split("/").pop(); // Get the last part of the URL
            setSlug(extractedSlug);
        }
    }, []);

    useEffect(() => {
        if (!slug) return;

        const updateVisitCount = async () => {
            try {
                await fetch("https://crowdwisdom.live/wp-json/api/v1/updateCryptoVisits", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ crypto }),
                });
            } catch (error) {
                console.error("Error updating visit count:", error);
            }
        };

        updateVisitCount();
    }, [slug]);
    const [topCryptos, setTopCryptos] = useState([]);

    // useEffect(() => {
    //     const fetchTopCryptos = async () => {
    //         try {
    //             const response = await fetch("https://crowdwisdom.live/wp-json/api/v1/getTopVisitedCryptos", {
    //                 method: "POST",  // ✅ Change from GET to POST
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                 },
    //                 body: JSON.stringify({}) // ✅ Include empty object if no params needed
    //             });
    
    //             const data = await response.json();
    //             console.log("API Response:", data); // Debugging log
    
    //             if (Array.isArray(data)) {
    //                 setTopCryptos(data);
    //             } else {
    //                 console.warn("Unexpected API Response:", data);
    //             }
    //         } catch (error) {
    //             console.error("Error fetching top cryptos:", error);
    //         }
    //     };
    
    //     fetchTopCryptos();
    // }, []);
    const [sortedSlugdata, setSortedSlugdata] = useState([]);

// useEffect(() => {
//     if (topCryptos.length > 0) {
//         setSortedSlugdata(topCryptos);
//     }
// }, [topCryptos]);
const [indexData, setIndexData] = useState([]);

    useEffect(() => {
        console.log("This slug:",crypto);
        const fetchIndexData = async () => {
            try {
                const response = await fetch("https://crowdwisdom.live/wp-json/api/v1/get_meme_coins_bullish", {
                    method: "POST",  // Change from GET to POST if needed by your API
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ crypto }), // Pass an empty object if no params needed
                });
    
                const data = await response.json();
                console.log("API Response:", data); // Debugging log
    
                if (Array.isArray(data)) {
                    setIndexData(data);  // Update state with the data
                } else {
                    console.warn("Unexpected API Response:", data);
                }
            } catch (error) {
                console.error("Error fetching meme coins:", error);
            }
        };
    
        fetchIndexData();
    }, []);

    const [indexPortfolios, setIndexPortfolios] = useState([]);

    useEffect(() => {
        const fetchIndexPortfolios = async () => {
            try {
                const response = await fetch("https://crowdwisdom.live/wp-json/api/v1/get_top_index_portfolios", {
                    method: "POST",  // Change from GET to POST if needed by your API
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ roi_var: "positive" }) // Example: Pass ROI variable as a parameter (e.g., 'positive')
                });
    
                const data = await response.json();
                console.log("API Response:", data); // Debugging log
    
                if (Array.isArray(data)) {
                    setIndexPortfolios(data);  // Update state with the data
                } else {
                    console.warn("Unexpected API Response:", data);
                }
            } catch (error) {
                console.error("Error fetching index portfolios:", error);
            }
        };
    
        fetchIndexPortfolios();
    }, []);


    
    const sanitizedDescription = description;
    const mediaQueryVar2 = useMediaQuery(
        json2mq({
            maxWidth: 420,
        })
    );
    const mediaQueryVar3 = useMediaQuery(
        json2mq({
            minWidth: 375,
        })
    );

    // Function to extract indentation level and style accordingly
    const getIndentationStyle = (htmlString) => {
        const div = document.createElement("div");
        div.innerHTML = htmlString;

        const items = div.querySelectorAll("li");
        items.forEach((item) => {
            const match = item.className.match(/ql-indent-(\d+)/);
            if (match) {
                const level = parseInt(match[1], 10);
                // Set padding based on indent level for content, reducing by 10px
                item.style.paddingLeft = `${20 * level - 20}px`; // Adjusted padding
                // Adjust margin for bullet alignment
                item.style.marginLeft = `${40 * level}px`; // Bullet alignment
            }
        });

        return div.innerHTML; // Return modified HTML
    };

    const processedDescription = getIndentationStyle(sanitizedDescription);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sortRef.current && !sortRef.current.contains(event.target)) {
                setSortDropdownVisible(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const toggleDropdown = (index) => {
        setDropdownVisible(dropdownVisible === index ? null : index);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSortChange = (sortType) => {
        setSortType(sortType);
        setSortDropdownVisible(false);
        setSelectedSort(sortType);
    };

    const openModal = async (data) => {
        if (data.accuracy.toLowerCase() !== "pending") {
            try {
                const response = await fetch("https://crowdwisdom.live/wp-json/api/v1/showForecasterPastData", {
                    // Replace with your actual API endpoint
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        searchVal: crypto,
                        forecasterName: data.forecaster_name,
                    }),
                });

                const result = await response.json();

                if (result.code === "200") {
                    setModalData(result.response);
                    setModalIsOpen(true);
                } else {
                    setError(result.message);
                }
            } catch (err) {
                setError("An error occurred while fetching data.");
            }
        }
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setModalData(null);
    };

    const filteredData = data.filter((forecaster) =>
        forecaster.forecaster_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleLoadMore = () => {
        navigate(`/crypto/${crypto}/predictions`);
        window.scrollTo({top: 0, behavior: "smooth"});
    };

    const [isTableView, setIsTableView] = useState(true);
    const [selectedButton, setSelectedButton] = useState("CoinPrices");

    const handleButtonClick = (buttonName) => {
        // If the same button is clicked again, don't toggle the view
        if (selectedButton === buttonName) {
            return;
        }

        // Toggle the view based on the clicked button
        setSelectedButton(buttonName);
        setIsTableView(buttonName === "CoinPrices" ? false : true); // Show table view only if 'CoinPrices' is selected
    };

    let url = `/predict-now`;
    const parseDate = (dateStr) => {
        const [month, day, year] = dateStr.split(" ");
        const monthMap = {
            Jan: "01",
            Feb: "02",
            Mar: "03",
            Apr: "04",
            May: "05",
            Jun: "06",
            Jul: "07",
            Aug: "08",
            Sep: "09",
            Oct: "10",
            Nov: "11",
            Dec: "12",
        };
        const formattedDate = `${year}-${monthMap[month]}-${day.padStart(2, "0")}`;
        return new Date(formattedDate);
    };

    const parseForecastPrice = (price) => {
        return parseFloat(price.replace(/[^0-9.-]+/g, ""));
    };

    const parseAccuracy = (accuracy) => {
        if (typeof accuracy !== "string") return NaN; // Return NaN if input is not a string

        // Extract the number from the accuracy string
        const percentage = accuracy.split("%")[0].trim(); // Get the part before '%'
        const parsedValue = parseFloat(percentage.split(" ")[0]); // Convert to float

        return isNaN(parsedValue) ? 0 : parsedValue; // Return 0 for NaN for safety
    };

    const compareValues = (val1, val2, order = "asc") => {
        // If either value is NaN, treat it as less than any valid number
        if (isNaN(val1) && isNaN(val2)) return 0; // Both are NaN
        if (isNaN(val1)) return 1; // Treat NaN as the largest value
        if (isNaN(val2)) return -1; // Treat NaN as the largest value

        // Perform comparison based on order
        if (order === "asc") return val1 - val2;
        return val2 - val1;
    };

    const handleSort = (column) => {
        const newSortOrder = sortOrder[column] === "asc" ? "desc" : "asc"; // Toggle sort order
        setSortOrder({...sortOrder, [column]: newSortOrder});

        // Set the sort type based on the column
        switch (column) {
            case "forecaster":
                setSortType(newSortOrder === "asc" ? "fc_asc" : "fc_desc");
                break;
            case "target":
                setSortType(newSortOrder === "asc" ? "tg_asc" : "tg_desc");
                break;
            case "accuracy":
                setSortType(newSortOrder === "asc" ? "ac_asc" : "ac_desc");
                break;
            case "percent":
                setSortType(newSortOrder === "asc" ? "pr_asc" : "pr_desc");
                break;
            case "targetDate":
                setSortType(newSortOrder === "asc" ? "fd_asc" : "fd_desc");
                break;
            default:
                break;
        }
    };

    const sortedData = [...filteredData].sort((a, b) => {
        switch (sortType) {
            case "fc_asc":
                return a.forecaster_name.localeCompare(b.forecaster_name);
            case "fc_desc":
                return b.forecaster_name.localeCompare(a.forecaster_name);
            case "tg_asc":
                return parseForecastPrice(a.forecast_price) - parseForecastPrice(b.forecast_price);
            case "tg_desc":
                return parseForecastPrice(b.forecast_price) - parseForecastPrice(a.forecast_price);
            case "ac_asc": {
                const accuracyA = parseAccuracy(a.accuracy);
                const accuracyB = parseAccuracy(b.accuracy);
                return compareValues(accuracyA, accuracyB, "asc");
            }
            case "ac_desc": {
                const accuracyA = parseAccuracy(a.accuracy);
                const accuracyB = parseAccuracy(b.accuracy);
                return compareValues(accuracyA, accuracyB, "desc");
            }
            case "pr_asc":
                return parseFloat(a.target_percent) - parseFloat(b.target_percent);
            case "pr_desc":
                return parseFloat(b.target_percent) - parseFloat(a.target_percent);
            case "fd_asc":
                return parseDate(a.target_date) - parseDate(b.target_date);
            case "fd_desc":
                return parseDate(b.target_date) - parseDate(a.target_date);
            default:
                return 0;
        }
    });

    const [isPredictionDataLoaded, setIsPredictionDataLoaded] = useState(false);
    useEffect(() => {
        if (sortedData && sortedData.length > 0) {
            setIsPredictionDataLoaded(true); // Set to true when data is available
        }
    }, [sortedData]);

    const countEvaluations = (data, evaluationKeys) => {
        let buys = 0,
            sells = 0,
            neutrals = 0;

        data.forEach((item) => {
            evaluationKeys.forEach((key) => {
                if (item[key] === "Buy") buys += 1;
                else if (item[key] === "Sell") sells += 1;
                else if (item[key] === "Neutral") neutrals += 1;
            });
        });

        return {buys, sells, neutrals};
    };

    const handleTabClick = () => {
        navigate("/predict-now", {state: {cryptoValue: "btc"}});
    };

    const oscillatorEvaluationKeys = [
        "rsi_evaluation",
        "cci_evaluation",
        "ao_evaluation",
        "mom_evaluation",
        "macd_evaluation",
        "stochrsi_evaluation",
        "willr_evaluation",
        "ultosc_evaluation",
    ];

    const movingAverageEvaluationKeys = [
        "sma_10_evaluation",
        "sma_50_evaluation",
        "sma_100_evaluation",
        "sma_200_evaluation",
        "ema_10_evaluation",
        "ema_50_evaluation",
        "ema_100_evaluation",
        "ema_200_evaluation",
    ];

    const isDataLoaded = oscillatorData && movingAveragesData;

    const oscillatorCounts = isDataLoaded
        ? countEvaluations(oscillatorData, oscillatorEvaluationKeys)
        : {buys: 0, sells: 0, neutrals: 0};

    const movingAverageCounts = isDataLoaded
        ? countEvaluations(movingAveragesData, movingAverageEvaluationKeys)
        : {buys: 0, sells: 0, neutrals: 0};

    const {buys: oscillatorBuys, sells: oscillatorSells, neutrals: oscillatorNeutrals} = oscillatorCounts;
    const {buys: movingAverageBuys, sells: movingAverageSells, neutrals: movingAverageNeutrals} = movingAverageCounts;

    const getSummary = (buys, sells) => {
        if (buys > sells) return "Strong Buy";
        if (sells > buys) return "Strong Sell";
        return "Neutral";
    };

    const oscillatorSummary = isDataLoaded ? getSummary(oscillatorBuys, oscillatorSells) : "-";
    const movingAverageSummary = isDataLoaded ? getSummary(movingAverageBuys, movingAverageSells) : "-";

    const getOverallSummary = () => {
        if (oscillatorSummary === "Strong Buy" && movingAverageSummary === "Strong Buy") return "Bullish";
        if (oscillatorSummary === "Strong Sell" && movingAverageSummary === "Strong Sell") return "Bearish";
        if (oscillatorSummary === "Strong Buy" || movingAverageSummary === "Strong Buy") return "Partially Bullish";
        if (oscillatorSummary === "Strong Sell" || movingAverageSummary === "Strong Sell") return "Partially Bearish";
        if (!oscillatorSummary || !movingAverageSummary) return "Neutral";
        return "";
    };

    const overallSummary = isDataLoaded ? getOverallSummary() : "-";

    // Check if any data is available for oscillators or moving averages
    const hasOscillatorData = oscillatorBuys + oscillatorSells + oscillatorNeutrals > 0;
    const hasMovingAverageData = movingAverageBuys + movingAverageSells + movingAverageNeutrals > 0;

    // Define statements based on sentiment
    const bullish =
        `A breakout above its initial resistance is likely to draw in more buyers. ` +
        `The next level of resistance at ${pivotdata.pivot_r2} may be retested or surpassed if buying pressure intensifies. `;

    const bearish =
        `If ${slug} fails to hold above its first level of support, ${pivotdata.pivot_s2} ` +
        `will be the immediate support. `;

    const neutral =
        `${slug} is likely to trade in the range between ${pivotdata.pivot_s1} and ${pivotdata.pivot_r1} ` + `for now.`;

    // Main statement based on overall sentiment
    const sentimentStatement =
        overallSummary === "Bullish" || overallSummary === "Partially Bullish"
            ? bullish
            : overallSummary === "Bearish" || overallSummary === "Partially Bearish"
            ? bearish
            : neutral;

    const [data_replacements, setData_replacements] = useState([]);
    const [loading_replacements, setLoading_replacements] = useState(true);
    const [predictionLoader_replacements, setPredictionLoader_replacements] = useState(false);
    const [isPredictionDataLoaded_replacements, setIsPredictionDataLoaded_replacements] = useState(false);
    useEffect(() => {
        const fetchCryptoData = async () => {
            try {
                const response = await fetch("https://crowdwisdom.live/wp-json/api/v1/showCryptoReplacements", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        crypto: crypto,
                        sortType: sortType,
                    }),
                });

                const result = await response.json();
                if (result.code === "200") {
                    setData_replacements(result.response);
                    if (result.response.length === 0) {
                        setPredictionLoader_replacements(true);
                    }
                } else {
                    setError(result.message);
                }
            } catch (err) {
                setError("An error occurred while fetching data.");
            } finally {
                setLoading_replacements(false);
            }
        };

        fetchCryptoData();
    }, [sortType, crypto]);

    const [showModal, setShowModal] = useState(false); // Change the state name to showModal

    const filteredData1 = data_replacements;

    const sortedData1 = [...filteredData1].sort((a, b) => {
        switch (sortType) {
            case "ac_asc":
                return compareValues(a.accuracy, b.accuracy, "asc");
            case "ac_desc":
                return compareValues(a.accuracy, b.accuracy, "desc");
            default:
                return 0;
        }
    });

    const [ignoredRows, setIgnoredRows] = useState([]);

    const [open, setOpen] = useState(false);
    const fetchDropdownOptions = async () => {
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));

        getFetchdropdown({
            user_id: userData.user_id,

            Token: userData.token,
        }).then((res) => {
            if (res.code === "200") {
                const newOptions = res.data.map((item) => ({
                    value: item.id,
                    label: item.name,
                }));
                setOptions(newOptions);
                setuser_role(res.user_role);
            } else {
                console.error("Data fething error");
            }
        });
    };

    const handleIgnoreClick = (index) => {
        const updatedIgnoredRows = [...ignoredRows, index];
        setIgnoredRows(updatedIgnoredRows);

        // Check if all currently displayed rows are ignored
        const allIgnored = sortedData1.slice(0, rowsToShow).every((_, idx) => updatedIgnoredRows.includes(idx));

        // If all rows are ignored, call handleLoadMore
        if (allIgnored) {
            handleLoadMore();
        }
    };

    const allRowsIgnored = sortedData1.slice(0, rowsToShow).every((_, index) => ignoredRows.includes(index));

    useEffect(() => {
        if (sortedData1 && sortedData1.length > 0) {
            setIsPredictionDataLoaded_replacements(true); // Set to true when data is available
        }
    }, [sortedData1]);

    const [unit, setUnit] = useState("");
    const [selectedOption, setSelectedOption] = useState([]);
    const [price, setPrice] = useState("");
    const [totalUnits, setTotalUnits] = useState(0);
    const [selectedDate, handleDateChange] = useState("");
    const [Usablewallet, setUsablewallet] = useState("");
    const [portfolioTypeId, setPortfolioTypeId] = useState("");
    const handleChange = async (selectedOption, slug) => {
        if (selectedOption && selectedOption._isNew_) {
            setIsDialogOpen(true);
            setSelectedOption(null);
            setdataslug(slug);
        } else if (!selectedOption) {
            // Handle the case when the selection is cleared
            setSelectedOption(null);
            setShowAlert(false);
            // Perform any other necessary actions
            return;
        } else {
            const selectedValue = selectedOption.value;
            if (selectedValue.includes("-")) {
                setErrorMessage("Hyphens are not allowed in the option."); // Set error message
                setShowAlert(true); // Show the alert
                return; // Abort further processing
            }
            setShowAlert(false);
            setSelectedOption(selectedOption);
            const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
            getTotalunit({
                user_id: userData.user_id,
                slug: slug, // Assuming value contains the slug
                portfolio_type_id: selectedOption.value, // Adjust as needed
                Token: userData.token,
            }).then((res) => {
                if (res.code === "200") {
                    setTotalUnits(res.total_uint);
                    setUsablewallet(res.wallet_amount);
                } else {
                    console.error("Data fething error");
                }
            });
        }
    };
    useEffect(() => {
        if (open || showModal) {
            fetchDropdownOptions();
        }
    }, [open, showModal]);
    const handleCreate = async (inputValue) => {
        setTotalUnits(0);
        setUsablewallet("");

        if (options.find((option) => option.value === inputValue)) {
            return; // Exit early if the option already exists
        }
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        const newOption = {value: inputValue, label: inputValue};
        const newOptions = [...options, newOption]; // Create a new array with the existing options plus the new one
        setOptions(newOptions); // Update the options state
        const createdOption = newOptions.find((option) => option.value == inputValue);
        if (createdOption) {
            handleChange(createdOption);
        }
    };
    const [showAlert, setShowAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const formatToNonZero = (number) => {
        number = parseFloat(number);

        if (number >= 1) {
            return number.toFixed(2);
        } else if (number >= 0.1) {
            return number.toFixed(1);
        } else if (number >= 0.01) {
            return number.toFixed(4);
        } else if (number >= 0.001) {
            return number.toFixed(5);
        } else if (number >= 0.0001) {
            return number.toFixed(6);
        } else if (number >= 0.00001) {
            return number.toFixed(7);
        } else if (number >= 0.000001) {
            return number.toFixed(8);
        } else if (number >= 0.0000001) {
            return number.toFixed(9);
        } else if (number >= 0.00000001) {
            return number.toFixed(10);
        } else if (number >= 0.000000001) {
            return number.toFixed(11);
        }
        return number.toFixed(1);
    };
    const [buySell, setbuySell] = React.useState("");
    const handleClick = (buySell) => {
        setSnackbaropen(true);
        setOpen(true);
        setSeverity("info");
        setIsFormSubmitted(false);
        // setSnackbarcolor("success");
        // setSnackbarmessage("Data saved successfully");
        setMsgforUpdatePortfolio("loading...");
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        const portfolioId =
            selectedOption && !isNaN(parseInt(selectedOption.value)) ? parseInt(selectedOption.value) : 0;
        const existingPortfolio = portfolioId > 0 ? "yes" : "no"; // Determine existing_portfolio based on portfolioId

        addToPortfolio({
            portfolio_type_id: portfolioId,
            portfolio_name: selectedOption ? selectedOption.label : "", // Ensure selectedOption is defined

            existing_portfolio: existingPortfolio,
            user_id: userData.user_id,
            slug: slugpopupdata.slugname,
            unit: unit,
            acquisition_price: price,
            date_time: selectedDate,
            type: buySell,
            Token: userData.token,
        }).then((res) => {
            if (res.code === "200") {
                // setOpen(false);
                setSeverity("info");
                setMsgforUpdatePortfolio(res.message);
                setPortfolioTypeId(res.portfolio_type_id);
                setIsFormSubmitted(true);
                // navigate(
                //   `/my-portfolio/${res.portfolio_type_id}/${encodeURIComponent(
                //     selectedOption.label
                //   )}`
                // );
            } else {
                setSeverity("error");
                // setOpen(true)
                setMsgforUpdatePortfolio(res.message);
            }
        });
    };
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [newOptionValue, setNewOptionValue] = useState("");
    const handleDialogSubmit = () => {
        // Handle submitting the dialog (e.g., creating the new option)

        // Update options state with the new option
        const newOption = {value: newOptionValue, label: newOptionValue};
        setOptions([...options, newOption]);
        setSelectedOption(newOption);
        // Close the dialog
        setIsDialogOpen(false);

        // setSelectedOption(selectedOption);
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        getTotalunit({
            user_id: userData.user_id,
            slug: dataslug, // Assuming value contains the slug
            portfolio_type_id: newOption.value, // Adjust as needed
            Token: userData.token,
        }).then((res) => {
            if (res.code === "200") {
                setTotalUnits(res.total_uint);
                setNewOptionValue("");
                setUsablewallet(res.wallet_amount);
            } else {
                console.error("Data fething error");
            }
        });
    };
    const [snackbaropen, setSnackbaropen] = React.useState(false);
    const closesnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackbaropen(false);
    };
    const [severity, setSeverity] = useState("info");
    const [msgforUpdatePortfolio, setMsgforUpdatePortfolio] = useState("loading...");
    const handleClose = () => setOpen(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [dataslug, setdataslug] = useState("");
    const [options, setOptions] = useState([]);
    const handleChange2 = (e) => {
        const value = e.target.value;
        // Check if the input contains a hyphen
        if (value.includes("-")) {
            setShowAlert(true); // Show the alert if there's a hyphen
        } else {
            setShowAlert(false); // Hide the alert if there's no hyphen
            setNewOptionValue(value); // Update the state with the new value
        }
    };
    const [slugdata, setSlugdata] = useState([]);
    const [user_role, setuser_role] = useState("");
    const [slugpopupdata, setSlugpopupdata] = useState({
        slugname: "",
        slug: "",
        units: 0,
    });

    const handleAddToPortfolio = (idx) => {

        getCryporCurrencyListHome({
            slug: idx,
        }).then((res) => {
            if (res.code === "200") {

                // Update slugdata
                setSlugdata(res.data);
                setuser_role(res.user_role);

                // Use res.data directly here
                const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
                if (userData) {
                    setSlugpopupdata({
                        slugname: res.data[0].slug,
                        slug: res.data[0].name,
                        units: res.data[0].total_unit,
                        pricetoday: res.data[0].price_today,
                        logo: res.data[0].logo,
                    });
                    setOpen(true);
                } else {
                    // Save the source in sessionStorage
                    const originalUrl = window.location.pathname + window.location.search;
                    sessionStorage.setItem("originalUrl", originalUrl);

                    // Before redirecting to login
                    sessionStorage.setItem("loginFromAddToPortfolio", "true");
                    sessionStorage.setItem("slugDataForPortfolio", JSON.stringify({
                        slugname: res.data[0].slug,
                        slug: res.data[0].name,
                        units: res.data[0].total_unit,
                        pricetoday: res.data[0].price_today,
                        logo: res.data[0].logo,
                    }));

                   
                    window.history.pushState({}, "", "/login");
                    localStorage.setItem("isPopupManuallyOpened", "true");
                    setLoginModalOpen(true);
                }
            } else {
                console.error("Data fetching error");
            }
        });
    };

    const [selectedFilter, setSelectedFilter] = useState(null);
    const handleClickROIHL = () => {
        setSortType("roi_desc");
    };
    const handleClickROILH = () => {
        setSortType("roi_asc");
    };
    const handleClearAll = () => {
        setSortType("roi_asc");
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const openfilter = Boolean(anchorEl);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseeoption = () => {
        setAnchorEl(null);
    };

  const handleStarClick = (slug) => {
          console.log("Slug being added to watchlist", slug);
          if (userData) {
              const user_id = userData.user_id;
              const token = userData.token;
      
              setStarredCryptos((prevState) => {
                  const isStarred = prevState[slug];
      
                  const updatedState = {
                      ...prevState,
                      [slug]: !isStarred,
                  };
                  localStorage.setItem("starred_cryptos", JSON.stringify(updatedState));
      
                  updateCryptoWatchlist({
                      user_id,
                      slug,
                      token,
                      action: isStarred ? "remove" : "add",
                  }).then((res) => {
                      if (res.code === 200) {
                          // Store updated watchlist correctly
                          setCryptoWatchlist(res.data.watchlist ? res.data.watchlist.split(",") : []);
                      } else {
                          console.error("Data Fetching Error");
                      }
                  }).catch((error) => console.error("Error updating watchlist", error));
      
                  return updatedState;
              });
          } else {
              const originalUrl = window.location.pathname;
              sessionStorage.setItem("originalUrl", originalUrl);
              handleLoginClick();
          }
      };
      
  
  
      const [unreadAlerts, setUnreadAlerts] = useState(0);
      useEffect(() => {
      
                  const userId = userData?.user_id || null;
      
                  const fetchAlertsCount = async () => {
                    try {
                      const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/crypto_alerts_count', {
                        method: "POST", // Use POST instead of GET
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ user_id: userId }), // Send user_id in the body
                      });
                
                      if (!response.ok) {
                        throw new Error(`Error: ${response.status}`);
                      }
                
                      const data = await response.json();
                      console.log(data);
                      setUnreadAlerts(data.unread_notifications);    
      
                    } catch (error) {
                      console.error("Failed to fetch alerts:", error);
                    } finally {
                    }
                  };
                
                  fetchAlertsCount();
                }
                , []);

    return (
        <div
            className="crypto-page"
            style={{
                padding: mediaQueryVar ? 0 : "10px",
                margin: 0,
                backgroundColor: mediaQueryVar ? "#fff" : "#fff",
            }}
        >
            <MetaProperty
    title={`${crypto.charAt(0).toUpperCase() + crypto.slice(1)} Price Analysis - Crowdwisdom360`}
    description={`${crypto.charAt(0).toUpperCase() + crypto.slice(1)} - Price Analysis, Technical Analysis, Sentiment Analysis and Predictions`}
    logo={currentCrypto?.symbol_logo}
/>
<Helmet>
                <title>{`${formattedCrypto} Price Analysis - Crowdwisdom360`}</title>
                <meta
                    name="description"
                    content={`${formattedCrypto} - Price Analysis, Technical Analysis, Sentiment Analysis and Predictions`}
                />
                <meta property="og:title" content={`${formattedCrypto} Price Analysis - Crowdwisdom360`} />
                <meta
                    property="og:description"
                    content={`${formattedCrypto} - Price Analysis, Technical Analysis, Sentiment Analysis and Predictions`}
                />
            </Helmet>


            <Modal
                isOpen={open}
                onRequestClose={handleClose}
                overlayClassName={{
                    overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black overlay
                        backdropFilter: "blur(5px)", // Optional: Add a blur effect to the overlay
                    },
                }}
                className={{
                    content: {
                        position: "relative",
                        backgroundColor: "white", // Set background for inner popup
                        padding: "20px", // Add padding for content
                        borderRadius: "8px", // Rounded corners
                        maxWidth: mediaQueryVar ? "100%" : "100%", // Set a max width for popup
                        margin: "0", // Center the popup horizontally
                        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)", // Optional: Popup shadow
                    },
                }}
            >
                <div className="CardOpener" style={{overflow: "hidden"}}>
                    {isFormSubmitted ? (
                        <div
                            style={{
                                position: "fixed", // Fix the popup to the top of the viewport
                                top: 0, // Align it to the top
                                left: 0,
                                width: "100%",
                                height: "100vh",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional: darken the background for emphasis
                                zIndex: 1000, // High z-index to overlay other elements
                            }}
                        >
                            <Card className="card__container">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "column",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <CheckCircleOutlineIcon sx={{fontSize: 50, mr: 1}} />
                                    </div>
                                    <Typography
                                        severity="success"
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        Portfolio added successfully!
                                    </Typography>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-evenly",
                                            marginTop: "10px",
                                            width: "100%",
                                        }}
                                    >
                                        <Button
                                            onClick={() => {
                                                setIsFormSubmitted(false);
                                                setOpen(false);
                                                setUnit("");
                                                setSelectedOption("");
                                                setPrice("");
                                                setTotalUnits(0);
                                                handleDateChange("");
                                                setUsablewallet("");
                                            }}
                                            color="primary"
                                            style={{
                                                backgroundColor: "rgba(67, 97, 238, 0.15)",
                                                borderRadius: "6px",
                                                border: "none",
                                                textTransform: "none",
                                                borderStyle: "solid",
                                                borderColor: "rgba(67, 97, 238, 0.15)",
                                                borderWidth: "1px",
                                                color: "#4361ee",
                                                whiteSpace: "nowrap",
                                                float: "inline-end",
                                                width: mediaQueryVar === true ? "150px" : "100px",
                                                height: mediaQueryVar === false ? "30px" : "",
                                                fontSize: mediaQueryVar === false ? "10px" : "11px",
                                            }}
                                        >
                                            Add More Cryptos
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                navigate(
                                                    `/my-portfolio/${portfolioTypeId}/${encodeURIComponent(
                                                        selectedOption.label.replace(/\s+/g, "-")
                                                    )}`
                                                )
                                            }
                                            variant="outlined"
                                            style={{
                                                backgroundColor: "rgba(67, 97, 238, 0.15)",
                                                borderRadius: "6px",
                                                border: "none",
                                                textTransform: "none",
                                                borderStyle: "solid",
                                                borderColor: "rgba(67, 97, 238, 0.15)",
                                                borderWidth: "1px",
                                                color: "#4361ee",
                                                whiteSpace: "nowrap",
                                                float: "inline-end",
                                                width: mediaQueryVar === true ? "100px" : "100px",
                                                height: mediaQueryVar === false ? "30px" : "",
                                                fontSize: mediaQueryVar === false ? "10px" : "11px",
                                            }}
                                            color="primary"
                                        >
                                            View Portfolio
                                        </Button>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    ) : (
                        <Dialog
                            PaperProps={{
                                sx: {
                                    position: mediaQueryVar ? "static" : "fixed",
                                    bottom: mediaQueryVar ? "" : 0,
                                    left: mediaQueryVar ? "" : 0,
                                    right: mediaQueryVar ? "" : 0,
                                    marginLeft: mediaQueryVar ? "" : "0px",
                                    marginRight: mediaQueryVar ? "" : "0px",
                                    marginTop: "1%",
                                    maxWidth: "100%",
                                    minHeight: mediaQueryVar ? "60%" : "", // Adjust max height if needed
                                    borderRadius: mediaQueryVar ? "24px" : "24px 24px 0 0",
                                    marginBottom: mediaQueryVar ? "" : "0px",
                                    padding: mediaQueryVar ? "10px" : "1px",
                                    alignItems: "center",
                                    overflow: "scroll",
                                    "&::-webkit-scrollbar": {
                                        display: "none",
                                    },
                                    "-ms-overflow-style": "none",
                                    "scrollbar-width": "none", // Ensure no scrollbar
                                },
                            }}
                            open={open}
                            onClose={handleClose}
                        >
                            <div
                                style={{
                                    padding: "20px",
                                    width: mediaQueryVar ? "400px" : "100%",
                                    margin: "0",
                                    marginTop: "-5px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginBottom: "20px",
                                        borderBottom: "1px #E3E3E3 solid",
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontWeight: "500",
                                            fontSize: "20.767px",
                                            paddingLeft: "100px",
                                        }}
                                        gutterBottom
                                    >
                                        Add To Portfolio
                                    </Typography>

                                    <div className="primaryButton">
                                        <img
                                            className="frameIcon"
                                            alt="frameIcon"
                                            onClick={handleClose}
                                            src={window.constants.asset_path + "/images/frame17.svg"}
                                            style={{
                                                background: "#F3F3F3",
                                                width: "25px",
                                                height: "25px",
                                            }}
                                        />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginBottom: "5px",
                                        marginLeft: mediaQueryVar === false ? "10px" : "",
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontSize: "13.845px",
                                            fontWeight: "500",
                                            marginBottom: "1px",
                                            color: "black",
                                            marginTop: "-8px",
                                        }}
                                    >
                                        Select Portfolio
                                    </Typography>
                                    <div style={{marginTop: "5px"}}>
                                        <CreatableSelect
                                            isClearable
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderRadius: "12px",
                                                    border: "1px solid #1877F2",
                                                    boxShadow: "none",
                                                    minHeight: "40px",
                                                    "&:hover": {
                                                        borderRadius: "12px",
                                                        border: "2px solid #1877F2",
                                                    },
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    borderRadius: "8px",
                                                }),
                                                placeholder: (provided) => ({
                                                    ...provided,
                                                    color: "",
                                                }),
                                                dropdownIndicator: (provided) => ({
                                                    ...provided,
                                                    color: "#1877F2",
                                                    border: "1px solid #1877F2",
                                                    padding: "5px",
                                                    margin: "8px",
                                                    borderRadius: "3.786px",
                                                    "&:hover": {
                                                        color: "#1877F2",
                                                    },
                                                }),
                                                indicatorSeparator: (provided) => ({
                                                    display: "none",
                                                }),
                                            }}
                                            placeholder=""
                                            onChange={(options) => handleChange(options, slugpopupdata.slugname)}
                                            onCreateOption={handleCreate}
                                            options={[
                                                {
                                                    value: "",
                                                    label: "Create new portfolio",
                                                    _isNew_: true,
                                                }, // Dynamic "create new option" item
                                                ...options, // Existing options
                                            ]}
                                            value={selectedOption}
                                        />
                                    </div>
                                    {showAlert && (
                                        <Alert severity="error" sx={{marginBottom: "10px"}}>
                                            {errorMessage}
                                        </Alert>
                                    )}
                                </div>
                                <Typography
                                    style={{
                                        fontSize: "13.845px",
                                        fontWeight: "500",
                                        color: "#000",
                                        marginTop: "10px",
                                        marginBottom: "5px",
                                    }}
                                >
                                    Usable Wallet Value: {Usablewallet}
                                </Typography>
                                <div
                                    style={{
                                        padding: mediaQueryVar ? "8px" : "5px",
                                        background: "#F3F3F3",
                                        borderRadius: "8px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        marginBottom: "5px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            flex: 0,
                                            minWidth: 0,
                                        }}
                                    >
                                        <img
                                            style={{
                                                marginRight: "10px",
                                                border: "2px solid #000",
                                                borderRadius: "19px",
                                                height: "27.69px",
                                                width: "27.69px",
                                            }}
                                            alt="logo"
                                            src={slugpopupdata.logo}
                                        />
                                        <div style={{display: "flex", flexDirection: "column"}}>
                                            <Typography
                                                sx={{
                                                    fontWeight: "500",
                                                    marginBottom: "0px",
                                                    fontSize: "14px",
                                                    color: "#7F7F80",
                                                }}
                                            >
                                                {slugpopupdata.slugname}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            marginLeft: "20%",
                                            flex: 1,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: "500",
                                                marginBottom: "4px",
                                                fontSize: "13px",
                                                color: "#7F7F80",
                                            }}
                                        >
                                            {" "}
                                            Price
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "12.114px",
                                                fontWeight: "500",
                                                color: "black",
                                                margin: "0",
                                                marginLeft: "5%",
                                            }}
                                        >
                                            {slugpopupdata.pricetoday}
                                        </Typography>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            marginLeft: "20px",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: "500",
                                                marginBottom: "4px",
                                                fontSize: "13px",
                                                color: "#7F7F80",
                                            }}
                                        >
                                            {" "}
                                            Units
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "12.114px",
                                                fontWeight: "500",
                                                color: "black",
                                                margin: "0",
                                            }}
                                        >
                                            {totalUnits}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="card__container__row3">
                                    <div style={{marginLeft: "-5px"}}>
                                        <p style={{fontSize: "14px", fontWeight: "500"}}>Enter number of Units</p>
                                        <input
                                            style={{
                                                backgroundColor: "white",
                                                color: "black",
                                                padding: "20px",
                                                border: "1px solid #1877F2",
                                                borderRadius: "10.384px",
                                                width: mediaQueryVar ? "350px" : "100%",
                                                height: "45px",
                                                margin: 0,
                                            }}
                                            min="1"
                                            max="10"
                                            type="number"
                                            placeholder=""
                                            value={unit}
                                            onChange={(e) => setUnit(e.target.value)}
                                        ></input>
                                    </div>
                                    <div>
                                        $
                                        {formatToNonZero(
                                            unit *
                                                parseFloat(
                                                    (slugpopupdata.pricetoday || "")
                                                    .replace(/^\$/, "") // Remove the dollar sign
                                                    .replace(/,/g, "") // Remove commas
                                                )
                                        )}
                                    </div>
                                    {user_role === "superuser" && (
                                        <>
                                            <div className="card__container__row3__r2">
                                                <div style={{marginLeft: "-5px"}}>
                                                    <p style={{fontSize: "14px", fontWeight: "500"}}>
                                                        Buy/Sell Price($)
                                                    </p>
                                                    <input
                                                        style={{
                                                            backgroundColor: "white",
                                                            color: "black",
                                                            padding: "20px",
                                                            border: "1px solid #1877F2",
                                                            borderRadius: "10.384px",
                                                            width: "345px",
                                                            height: "45px",
                                                        }}
                                                        className="card__container__row3__r2__input"
                                                        min="1"
                                                        max="10"
                                                        type="number"
                                                        placeholder="Enter Price"
                                                        value={price}
                                                        onChange={(e) => setPrice(e.target.value)}
                                                    ></input>
                                                </div>
                                            </div>
                                            <div style={{marginBottom: "10px", marginLeft: "-5px"}}>
                                                <p style={{fontSize: "14px", fontWeight: "500"}}>Date</p>
                                                <TextField
                                                    type="date"
                                                    value={selectedDate}
                                                    onChange={(e) => handleDateChange(e.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    sx={{
                                                        "& .MuiOutlinedInput-root": {
                                                            borderRadius: "10.384px",
                                                            border: "1px solid #1877F2",
                                                            boxShadow: "none",
                                                            width: "190%",
                                                            height: "45px",
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </>
                                    )}
                                    <div className="card__container__row3__r4" style={{marginTop: "10px"}}>
                                        <Button
                                            className={buySell === "buy" ? "buttonToggleButActive" : "buttonToggleBuy"}
                                            value="Buy"
                                            style={{
                                                lineHeight: "20px",
                                                width: mediaQueryVar ? "159px" : "130px",
                                                borderRadius: "27px",
                                                border: "1px solid #1877F2",
                                                padding: mediaQueryVar ? "15px 85px" : "13px 75px",
                                                marginLeft: "-6px",
                                                backgroundColor: buySell === "buy" ? "" : "#1877F2",
                                                color: buySell === "buy" ? "" : "white",
                                                textTransform: "none",
                                                fontSize: "18px",
                                                fontWeight: "700",
                                            }}
                                            onClick={() => handleClick("buy")}
                                        >
                                            Buy
                                        </Button>
                                        <Button
                                            className={
                                                buySell === "sell" ? "buttonToggleSellActive" : "buttonToggleSell"
                                            }
                                            value="sell"
                                            style={{
                                                lineHeight: "20px",
                                                width: mediaQueryVar ? "159px" : "130px",
                                                borderRadius: "27px",
                                                border: "1px solid #1877F2",
                                                marginLeft: "10px",
                                                padding: mediaQueryVar ? "15px 85px" : "13px 75px",
                                                backgroundColor: buySell === "sell" ? "" : "#1877F2",
                                                color: buySell === "sell" ? "" : "white",
                                                textTransform: "none",
                                                fontSize: "18px",
                                                fontWeight: "700",
                                            }}
                                            onClick={() => handleClick("sell")}
                                        >
                                            Sell
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    )}
                </div>
            </Modal>

            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} fullWidth maxWidth="sm">
                <DialogTitle>
                    Create New Portfolio
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            setIsDialogOpen(false);
                            setNewOptionValue("");
                        }}
                        sx={{position: "absolute", right: 13, top: 8}}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div>
                        <TextField
                            autoFocus
                            label="Enter Name"
                            value={newOptionValue}
                            onChange={handleChange2}
                            fullWidth
                            margin="dense"
                        />
                        {showAlert && (
                            <Alert severity="error" sx={{marginTop: "8px"}}>
                                Hyphens are not allowed in the name.
                            </Alert>
                        )}
                    </div>
                </DialogContent>
                <DialogActions sx={{marginRight: "14px"}}>
                    <Button
                        onClick={() => {
                            setIsDialogOpen(false);
                            setNewOptionValue("");
                        }}
                        sx={{fontWeight: "bold"}}
                        variant="outlined"
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDialogSubmit}
                        variant="contained"
                        color="primary"
                        sx={{fontWeight: "bold"}}
                        disabled={!newOptionValue.trim()}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbaropen}
                autoHideDuration={5000}
                onClose={closesnackbar}
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
            >
                <Alert severity={severity}>{msgforUpdatePortfolio}</Alert>
            </Snackbar>

            <Grid item xs={12} align="left">
                <EmailPopUp />
                {mediaQueryVar ? null : (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between", // Changed from space-between to flex-end
                            marginTop: "0px",
                            marginLeft: "0px",
                            fontSize: "20px",
                        }}
                    >
                        
                        <Typography style={{fontSize: "20px", fontWeight: "500"}}>
                        <img
          src={window.constants.asset_path + "/images/left-arrow.svg"}
          style={{ marginRight: "10px" }}
          width="16"
          height="16"
          onClick={() => window.history.back()} // Go back to the previous page
          alt="Back"
        />
                             Price Analysis </Typography>
                             <div style={{display:"flex", alignItems:"center"}}>
                             <div className="notification-section position-relative"  onClick={() => navigate("/alerts")}>
                                                                <NotificationIcon className="notification-icon fa-2x" />
                                                                <span className="badge rounded-pill badge-notification bg-danger position-absolute top-0 start-100 ">
                                                                      {unreadAlerts}
                                                                </span>
                                                            </div>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            onClick={handleDrawerToggle}
                            sx={{display: {sm: "none"}}}
                        >
                            <img
                                src="/images/menubar.svg"
                                alt="Menu Icon"
                                style={{
                                    width: "24px",
                                    height: "24px",
                                    marginRight: "10px",
                                    marginTop: "10px",
                                }}
                            />
                        </IconButton>
                        </div>
                    </div>
                )}
            </Grid>
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: {xs: "block", sm: "none"},
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: "400",
                        backgroundColor: "white",
                        color: "#727376",
                    },
                }}
            >
                {drawer}
            </Drawer>
            {mediaQueryVar ? null : (
                <Box
                    sx={{
                        backgroundColor: "#1877F2", // Background color for the container
                        padding: "3.105px", // Padding inside the container
                        borderRadius: "38.042px", // Border radius for the container
                        display: "flex", // Ensure that the box is flexible
                        alignItems: "center", // Center the content vertically
                        display: "inline-block",
                        width: "auto",
                        height: "37px",
                        border: "none",
                        marginLeft: mediaQueryVar3 ? "-1px" : "-3px",
                        marginTop: "9%",
                        marginBottom: "3%",
                    }}
                >
                    <Tabs
                        value={selectedButton}
                        onChange={(event, newValue) => handleButtonClick(newValue)}
                        aria-label="basic tabs example"
                        sx={{
                            flexGrow: 1, // Make the tabs take up remaining space
                            "& .MuiTabs-indicator": {
                                display: "none",
                            },
                            display: "flex", // Ensure that the tabs are displayed in a row
                            overflow: "hidden", // Prevent the tabs from spilling out of the border radius
                        }}
                    >
                        <Tab
                            label={isPredictionDataLoaded ? `${slug} Price Analysis` : "Price Analysis"}
                            value="CoinPrices"
                            sx={{
                                whiteSpace: "nowrap",
                                borderRadius: "20.962px",
                                minHeight: "31px",
                                height: "31px",
                                marginLeft: "1.5px",
                                marginTop: "0.2px",
                                border: "1px solid black",
                                fontWeight: selectedButton === "CoinPrices" ? "700" : "500",
                                marginRight: "0px",
                                fontSize: mediaQueryVar2 ? "11px" : "13px",
                                paddingRight: "-8px",
                                textTransform: "none",
                                backgroundColor: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // White background for selected tab
                                color: selectedButton === "CoinPrices" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
                                border: "none", // Remove border for tabs
                            }}
                        />
                        {/* <Tab
              label={userData ? "My Portfolios" : "Boost Portfolio"}
              value="AuditPortfolio"
              onClick={() => {
        navigate(userData ? "/my-portfolio" : "/audit-my-portfolio");
      }}
              
              sx={{
                whiteSpace: "nowrap",
                borderRadius: "20.962px",
                minHeight: "31.5px",
                height: "31.5px",
                fontWeight:
                  selectedButton === "AuditPortfolio" ? "700" : "500",
                marginLeft: "-10px",
                paddingLeft: "-15px",
                marginTop: "0.5px",
                border: "1px solid black",
                marginBottom: "-5px",
                fontSize: mediaQueryVar2 ? "13px" : "15px",
                marginRight: "-25px",
                backgroundColor:
                  selectedButton === "AuditPortfolio"
                    ? "#FFFFFF"
                    : "transparent", // White background for selected tab
                color:
                  selectedButton === "AuditPortfolio"
                    ? "#000000 !important"
                    : "#FFFFFF", // Black text for selected tab
                textTransform: "none",
                border: "none",
              }}
            /> */}

<Tab
                                        label="Portfolio Audit"
                                        value="PortfolioAudit"
                                        onClick={() => {
                                                navigate("/audit-my-portfolio");
                                        }}
                                        sx={{
                                            whiteSpace: "nowrap",
                                            bottom: "0px",
                                            marginTop: "1.1px",
                                            marginBottom: "0px",
                                            marginLeft: "3px",
                                            marginRight: "3px",
                                            paddingBottom: "2px",
                                            paddingTop: "0px",
                                            paddingLeft: "20px",
                                            paddingRight: "20px",
                                            minHeight: "20px",
                                            lineHeight: {xs: "28px", sm: "36px"},
                                            borderRadius: selectedButton === "PortfolioAudit" ? "20px" : "20px", // Rounded corners for selected tab
                                            textTransform: "none",
                                            fontSize: {xs: "13px", sm: "17px"},
                                            fontStyle: "normal",
                                            fontWeight: selectedButton === "PortfolioAudit" ? "700" : "500",

                                            backgroundColor:
                                                selectedButton === "PortfolioAudit" ? "#FFFFFF" : "transparent", // White background for selected tab
                                            color:
                                                selectedButton === "PortfolioAudit" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
                                            border: "none", // Remove border for tabs
                                            background: selectedButton === "PortfolioAudit" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
                                        }}
                                    />
                    </Tabs>
                </Box>
            )}
            {mediaQueryVar ? null : (
    <div
        style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
            width: "100%",
        }}
    >
        <button
            onClick={() => handleAddToPortfolio(slug)}
            style={{
                border: "1px solid #1877F2",
                borderRadius: "48px",
                padding: "6px 14px",
                backgroundColor: "white",
                color: "#1877F2",
                fontSize: "14px",
                height: "33px",
                fontWeight: "500",
            }}
        >
            Add to Portfolio
        </button>

        <button
            onClick={() => navigate(`/crypto/${crypto}/portfolios`)}
            style={{
                textAlign: "center",
                color: "white",
                fontSize: "12px",
                fontWeight: "700",
                width: "141px",
                height: "35px",
                backgroundColor: "#1877F2",
                padding: "4px 4px",
                borderRadius: "6px",
                border: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            Best Portfolios
            <img
                src={window.constants.asset_path + "/images/open_in_new_tab.svg"}
                style={{ marginLeft: "8px", marginTop: "-2px" }}
                width={20}
                height={20}
                alt="open_in_new_tab"
            />
        </button>
    </div>
)}

            

            {mediaQueryVar ? null : (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "30px",
                        marginLeft: "0px",
                    }}
                >
                    <button
                        style={{
                            fontSize: "18px",
                            fontWeight: "500",
                            border: "1px solid var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                            borderRadius: "6px",
                            background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                            padding: "0px 4px",
                            marginBottom: "4px",
                            color: "black",
                        }}
                    >
                        Performance Metrics
                    </button>
                    <button
    onClick={() => handleStarClick(slug)}
    style={{
        border: `1px solid ${CryptoWatchlist.includes(slug) ? "red" : "#1877F2"}`,
        borderRadius: "48px",
        padding: mediaQueryVar3 ? "5px 12px" : "5px 10px",
        backgroundColor: CryptoWatchlist.includes(slug) ? "red" : "white",
        color: CryptoWatchlist.includes(slug) ? "white" : "#1877F2",
        fontSize: "12px",
        height: "33px",
        marginRight: "0px",
        fontWeight: "500",
        transition: "all 0.3s ease-in-out", // Smooth transition effect
    }}
>
    {CryptoWatchlist.includes(slug) ? "Remove from Watchlist" : "Add to Watchlist"}
</button>
                </div>
            )}

            {mediaQueryVar ? null : !loader ? (
                <div
                style={{
                    backgroundColor: "#e7f2fd",
                    borderRadius: "10px",
                    padding: "16px",
                    width: "100%",
                    fontFamily: "Satoshi, sans-serif",
                }}
            >
                {Array(6)
                .fill("")
                .map((_, index) => (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "8px",
                            marginTop: "10px",
                        }}
                        key={index}
                    >
                        <div
                            style={{
                                height: "16px",
                                backgroundColor: "#ccc",
                                borderRadius: "4px",
                                width: "40%",
                                animation: "shimmer 1.2s infinite linear",
                                background: "linear-gradient(to right, #e0e0e0 8%, #f0f0f0 18%, #e0e0e0 33%)",
                                backgroundSize: "800px 104px",
                            }}
                        />
                        <div
                            style={{
                                height: "16px",
                                backgroundColor: "#ccc",
                                borderRadius: "4px",
                                width: "60%",
                                animation: "shimmer 1.2s infinite linear",
                                background: "linear-gradient(to right, #e0e0e0 8%, #f0f0f0 18%, #e0e0e0 33%)",
                                backgroundSize: "800px 104px",
                            }}
                        />
                    </div>
                ))}
            </div>
        ) : (
            <div
                style={{
                    backgroundColor: "#E5F3FF",
                    borderRadius: "8px",
                    border: "0.3px solid #1877F2",
                    padding: "12px",
                    width: "100%",
                    fontFamily: "Satoshi, sans-serif",
                    marginTop: "10px",
                    fontWeight: "400",
                }}
            >
                <div
style={{
display: "flex",
justifyContent: "space-between",
alignItems: "center",
marginBottom: "8px",
}}
>
{/* Left-aligned content */}
<div style={{ display: "flex", alignItems: "center" }}>
<span style={{ fontWeight: "500" }}>Crypto</span> {/* "Crypto" text */}
<span style={{ marginLeft: "6px" }}>{slug}</span> {/* Crypto Slug */}
<img
  alt="logo"
  src={symbolLogo}
  width={30}
  height={30}
  style={{
    borderRadius: "19px",
    border: "2px solid #000",
    width: "30px",
    height: "30px",
    marginLeft: "8px",
  }}
/>
</div>

{/* Right-aligned content */}
<div style={{ display: "flex", alignItems: "center" }}>
<span style={{ fontWeight: "500", marginRight: "10px" }}>${currentPrice}</span>
<span
  style={{
    color: parseFloat(tfhrs) > 0 ? "green" : "red",
    fontWeight: "500",
  }}
>
  {parseFloat(tfhrs) > 0 ? `+${tfhrs}` : tfhrs}
</span>
</div>
</div>


                <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                    <div>7 Days</div>
                    <div style={{color: "green"}}>
                        <span
                            style={{
                                color: parseFloat(sevenDays) > 0 ? "green" : "red",
                            }}
                        >
                            {parseFloat(sevenDays) > 0 ? `+${sevenDays}` : sevenDays}
                        </span>
                    </div>
                </div>
                <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                    <div>30 Days</div>
                    <div style={{color: "green"}}>
                        <span
                            style={{
                                color: parseFloat(thirtyDays) > 0 ? "green" : "red",
                            }}
                        >
                            {parseFloat(thirtyDays) > 0 ? `+${thirtyDays}` : thirtyDays}
                        </span>
                    </div>
                </div>
                <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                    <div>12 Months</div>
                    <div style={{color: "green"}}>
                        <span
                            style={{
                                color: yearDays == "NA" ? "black" : parseFloat(yearDays) > 0 ? "green" : "red",
                            }}
                        >
                            {parseFloat(yearDays) > 0 ? `+${yearDays}` : yearDays}
                        </span>
                    </div>
                </div>
                
                <div style={{display: "flex", justifyContent: "space-between", marginBottom: "3px"}}>
                    <div>Market Cap</div>
                    <div> {marketCap}</div>
                </div>
                
            </div>
            )}
            {mediaQueryVar
  ? null
  : loader===true && category && (
    <div
  style={{
    display: "flex",
    justifyContent: "flex-start", // Centers the content horizontally
    alignItems: "center", // Centers the content vertically
     // Adjusts the height to the full viewport for vertical centering
  }}
>
  <div
    style={{
      display: "inline-flex",
      alignItems: "center",
      border: "1px solid #1877F2",
      padding: "7px 10px",
      borderRadius: "12px",
      maxWidth: "100%",
      boxSizing: "border-box",
      backgroundColor: "#FFF",
      flexWrap: "nowrap",
      gap: "25px",
      marginLeft: "0px",
      gap: "20px",
      marginTop:"12%"
    }}
  >
    {/* Category Label */}
    <span
      style={{
        fontWeight: "700",
        fontSize: "14px",
        whiteSpace: "nowrap",
      }}
    >
      Category
    </span>

    {/* Category Name */}
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
        background: "linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%)",
        padding: "4px 10px",
        borderRadius: "12px",
        fontWeight: "700",
        fontSize: "14px",
        whiteSpace: "nowrap",
        color: "#000",
        textAlign: "center",
      }}
    >
      <span>{category}</span>
      <img
        src={window.constants.asset_path + "/images/new-link-icon.svg"}
        alt="icon"
        style={{
          width: "16px",
          height: "16px",
          marginLeft: "20px",
          cursor: "pointer",
        }}
        onClick={() => {
            window.open(`/crypto-screener/${category.toLowerCase().replace(/\s+/g, "-")}`, "_blank");
          }}
          
      />
    </div>
  </div>
</div>

      

  )}
            

{mediaQueryVar
  ? null : loader === true && (risk !== null || top100Holders !== null) && (
    <div
      style={{
        backgroundColor: "#fff",
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        border: "1px solid #1877F2",
        padding: mediaQueryVar3?"8px 20px":"8px 15px",
        borderRadius: "12px",
        width: "100%", // Takes full width
        justifyContent: "space-between", // Ensures left-right alignment
        marginTop:"20px"
      }}
    >
      {/* Risk Box - Left Aligned */}
      <div
        style={{
          border: "1px solid #1877f2",
          padding: "6px 10px 6px 10px",
          borderRadius: "48px",
          fontSize: "14px",
          fontWeight: "700",
          color: "#000",
          background: "linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%)",
          whiteSpace: "nowrap",
          display: "inline-flex",
          width: "max-content",
          
        }}
      >
        <span>Risk: </span>
        <span style={{ fontSize: "14px", marginLeft: "5px" }}>{renderAction(risk)}</span>
      </div>

      {/* Top 100 Holders Box - Right Aligned */}
      <div
        style={{
          border: "1px solid #1877f2",
          padding: "6px 10px 6px 10px",
          borderRadius: "48px",
          fontSize: "14px",
          fontWeight: "700",
          color: "#000",
          background: "linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%)",
          whiteSpace: "nowrap",
          display: "inline-flex",
          width: "max-content",
        }}
      >
        <span>Top 100 Holders: </span>
        <span style={{ fontSize: "14px", marginLeft: "5px" }}>{top100Holders}</span>
      </div>
    </div>
  )}
   {mediaQueryVar
                ? null : loader==true && (
                    <button
      style={{
        backgroundColor: "#1877F2",
        color: "white",
        border: "none",
        padding: "10px 12px",
        fontSize: "14px",
        fontWeight: "700",
        borderRadius: "6px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        whiteSpace: "nowrap",
        marginRight:"6%",
        width:"100%",
        marginTop:"20px",
        marginBottom:"40px"
      }}
      onClick={() => {
        window.open(`/crypto/${crypto}/trends`, "_blank");
        window.scrollTo({ top: 0, behavior: "smooth" });
      }}
      
    >
      Explore Risk And Influencer Sentiments
      <span
        style={{
          marginLeft: "5px",
          display: "inline-flex",
        }}
      >
        <img
          src={window.constants.asset_path + "/images/new-white-link.svg"}
          style={{ marginLeft: "15px" }}
          width="18"
          height="18"
        />
      </span>
    </button>
                )} 

            {mediaQueryVar
                ? null
                : evaluation_data && (
                      <div
                          style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: hashtags2.length > 0?"3%":"10%",
                              marginLeft: "0px",
                              marginBottom: "10px",
                              marginTop:"1.5%"
                          }}
                      >
                          <button
                              style={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  border: "1px solid var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                  borderRadius: "6px",
                                  background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                  padding: "0px 4px",
                                  marginBottom: "4px",
                                  color: "black",
                              }}
                          >
                              Outlook Summary
                          </button>
                      </div>
                  )}
            
            {mediaQueryVar
  ? null
  : evaluation_data && (
      <div style={{ marginBottom: "10px" }}>
        <div
  style={{
    backgroundColor: "#E5F3FF",
    borderRadius: "8px",
    border: "0.3px solid #1877F2",
    padding: "12px 5px 12px 8px",
    width: "100%",
    fontFamily: "Satoshi, sans-serif",
    fontWeight: "400",
  }}
>
  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
    <div style={{ flex: "1" }}>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}>
        <div>Price Target</div>
        <div>{outlookData.target || "-"}</div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}>
        <div>Accuracy</div>
        <div>{outlookData.accuracy || "-"}</div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>Recommendation</div>
        <div>{renderAction(outlookData.recommendation) || "-"}</div>
      </div>
    </div>

    {/* Third Column: Show Charts Button (Rowspan Effect) */}
    <div style={{ display: "flex", alignItems: "center", marginLeft: mediaQueryVar3 ? "15px":"5px" }}>
      <button
        style={{
          backgroundColor: "#1877F2",
          color: "white",
          border: "none",
          borderRadius: "6px",
          padding: "8px 10px",
          fontSize: "12px",
          fontWeight: "700",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => {
            window.open(`/crypto/${crypto}/trends`, "_blank");
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
      >
        Show Charts
        <img
          src={window.constants.asset_path + "/images/new-white-link.svg"}
          style={{ marginLeft: "15px" }}
          width="15"
          height="15"
        />
      </button>
    </div>
  </div>
</div>


        {/* Blue Button Below */}
        <button
          style={{
            marginTop: "40px",
            width: mediaQueryVar2?"100%":"92%",
            backgroundColor: "#1877F2",
            color: "white",
            border: "none",
            borderRadius: "6px",
            padding: mediaQueryVar2?"12px 10px":"12px",
            fontSize: mediaQueryVar2?"14px":"16px",
            fontWeight: "700",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between", // Ensures text is left & icon is right
            marginLeft:mediaQueryVar2?"0%":"4%",
            marginBottom:"60px"
          }}
          onClick={() => {
            window.open(`/crypto/${crypto}/predictions`, "_blank");
          }}
          
        >
          <span>Explore Past Price Targets And Accuracy</span>
          <img src={window.constants.asset_path + "/images/new-white-link.svg"}
                                      width="18"
                                      height="18" />
        </button>
      </div>
  )}





            {mediaQueryVar
                ? null
                : evaluation_data && (
                      <div
                          style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: hashtags2.length>0 ? "5%":"10%",
                              marginLeft: "0px",
                              marginBottom: "10px",
                          }}
                      >
                          <button
                              style={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  border: "1px solid var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                  borderRadius: "6px",
                                  background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                  padding: "0px 4px",
                                  marginBottom: "4px",
                                  color: "black",
                              }}
                          >
                              Technical Signals
                          </button>
                      </div>
                  )}
            
            {mediaQueryVar
                ? null
                : evaluation_data && (
                      <div style={{marginBottom: "10px"}}>
                          <div
                              style={{
                                  backgroundColor: "#E5F3FF",
                                  borderRadius: "8px",
                                  border: "0.3px solid #1877F2",
                                  padding: "12px",
                                  width: "100%",
                                  fontFamily: "Satoshi, sans-serif",
                                  fontWeight: "400",
                              }}
                          >
                              <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                                  <div>RSI</div>
                                  <div>{renderAction(evaluation_data.rsi_evaluation) || "-"}</div>
                              </div>
                              <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                                  <div>Momentum</div>
                                  <div>{renderAction(evaluation_data.mom_oscillator_evaluation) || "-"}</div>
                              </div>
                              <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                                  <div>MACD</div>
                                  <div> {renderAction(evaluation_data.macd_evaluation) || "-"}</div>
                              </div>
                              <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                                  <div>OBV</div>
                                  <div> {renderAction(evaluation_data.obv_evaluation) || "-"}</div>
                              </div>
                              <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                                  <div>Bollinger Bands</div>
                                  <div> {renderAction(evaluation_data.bbands_evaluation) || "-"}</div>
                              </div>
                              <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                                  <div>Head and Shoulders</div>
                                  <div> {renderAction(evaluation_data.head_shoulders) || "-"}</div>
                              </div>
                              <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                                  <div>Triangles</div>
                                  <div> {renderAction(evaluation_data.triangles) || "-"}</div>
                              </div>
                              <div style={{display: "flex", justifyContent: "space-between", marginBottom: "0px"}}>
                                  <div>Fibonacci Retracement</div>
                                  <div> {renderAction(evaluation_data.fibonacci_evaluation) || "-"}</div>
                              </div>
                          </div>
                      </div>
                  )}
            {mediaQueryVar
                ? null
                : loader === true && sortedSlugdata.length>0 && ( <div
                style={{
                  backgroundColor: "#1877F2", // Blue color
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "400",
                  padding: "10px 12px",
                  width: "100%",
                  marginLeft:"0px",
                  height:"50px",
                  color:"#fff",
                  marginBottom:"1.5%",
                  marginTop:"40px"
                }}
              >
                Trending Cryptos
              </div>
            )}
            {mediaQueryVar
                ? null
                : loader === true && sortedSlugdata.length>0 && ( <Grid container spacing={0} sx={{
                    marginBottom: "-30px",
                    marginTop: "30px",
                    flexWrap: "nowrap",
                    overflowX: "auto",
                    scrollbarWidth: "none", // Hides scrollbar in Firefox
                    msOverflowStyle: "none", // Hides scrollbar in IE/Edge
                  }}>
              {sortedSlugdata.map((obj, idx) => (
                <Grid item xs={12} md={6} key={idx} sx={{ flex: "0 0 auto", width: mediaQueryVar1? "80%":"90%" }}>
                <div
                  key={idx}
                  style={{
                    width: "95%",
                    marginLeft: "4px",
                    borderRadius: "10px",
                    backgroundColor: "white",
                    border: "1px solid #E2E2E4",
                    padding: "15px",
                    marginBottom: "25px",
                    marginRight: "-15px",
                  }}
                >
                  {/* First column data */}
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        fontSize: mediaQueryVar === false ? "13px" : "",
                      }}
                    >
                      {/* Left Section - Logo & Name */}
                      <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
                        <img
                          className="b57ac673f06a4b0338a596817eb0a5Icon"
                          alt="logo"
                          src={obj.logo}
                          style={{
                            width: "38px",
                            height: "38px",
                            border: "2px solid black",
                            borderRadius: "20px",
                            marginRight: "10px",
                          }}
                        />
              
                        {/* Name or Link */}
                        {obj["article_link"] ? (
                          <a href={obj.article_link} style={{ color: "black", textDecoration: "underline" }}>
                            <div
                              style={{
                                fontSize: "18px",
                                fontWeight: "600",
                                fontFamily: "Satoshi, sans-serif",
                                color: "black",
                              }}
                            >
                              {obj.slug}
                            </div>
                          </a>
                        ) : (
                          <div
                            style={{
                              fontSize: "18px",
                              fontWeight: "600",
                              fontFamily: "Satoshi, sans-serif",
                              color: "black",
                              textDecoration: "underline",
                            }}
                          >
                            {obj.slug}
                          </div>
                        )}
                      </div>
              
                      {/* Center Section - Recommendation */}
                      <div
                        style={{
                          flex: 1,
                          textAlign: "center",
                          fontWeight: "500",
                          fontSize: "14px",
                          color:
                            obj.recommend === "Hold"
                              ? "orange"
                              : obj.recommend === "Buy"
                              ? "green"
                              : obj.recommend === "Sell"
                              ? "red"
                              : "black",
                        }}
                      >
                        {obj.recommend}
                      </div>
              
                      {/* Right Section - Button */}
                      <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
                        <Button
                          className="btn"
                          variant="outlined"
                          onClick={() => handleAddToPortfolio(obj.slug)}
                          style={{
                            backgroundColor: "white",
                            padding: "4px 10px",
                            fontFamily: "Satoshi, sans-serif",
                            borderRadius: "48px",
                            border: "1px solid #1877F2",
                            textTransform: "none",
                            fontSize: mediaQueryVar === false ? "10px" : "11px",
                            fontWeight: "500",
                            color: "#1877F2",
                            whiteSpace: "nowrap",
                            width: "120px",
                            height: "30px",
                          }}
                        >
                          Add to Portfolio
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              
              ))}
            

            </Grid>
            )}
            {mediaQueryVar
                ? null
                : evaluation_data && sortedSlugdata.length>0 && ( <div
                style={{
                  backgroundColor: "#1877F2", // Blue color
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "400",
                  padding: "10px 12px",
                  width: "100%",
                  marginLeft:"0px",
                  height:"25px",
                  color:"#fff",
                  marginBottom:"1.5%",
                  marginTop:"20px"
                }}
              >
                
              </div>
            )}
            {mediaQueryVar
                ? null : loader==true && (
                    <button
  style={{
    backgroundColor: "#1877F2",
    color: "white",
    border: "none",
    padding: "10px 12px",
    fontSize: "14px",
    fontWeight: "700",
    borderRadius: "6px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center", // Centers vertically
    justifyContent: "flex-start", // Aligns text to the left
    whiteSpace: "nowrap",
    marginRight: "6%",
    width: "100%",
    marginTop: "50px",
    marginBottom: "50px",
    textAlign: "left", // Ensures text starts from the left
  }}
  onClick={() => {
    window.open(`/crypto/${crypto}/trends`, "_blank");
    window.scrollTo({ top: 0, behavior: "smooth" });
  }}
>
  Explore RSI Charts  
  <span
    style={{
      marginLeft: "auto", // Pushes icon to the right
      display: "inline-flex",
    }}
  >
    <img
      src={window.constants.asset_path + "/images/new-white-link.svg"}
      style={{ marginLeft: "15px", marginRight:"15px" }}
      width="18"
      height="18"
    />
  </span>
</button>

                )} 
            {mediaQueryVar
                ? null
                : analysis_data && (
                      <div
                          style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: "10%",
                              marginLeft: "0px",
                              marginBottom: "10px",
                          }}
                      >
                          <button
                              style={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  border: "1px solid var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                  borderRadius: "6px",
                                  background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                  padding: "0px 4px",
                                  marginBottom: "4px",
                                  color: "black",
                              }}
                          >
                              Influencer Signals
                              {paid_member === "no" && (
                                  <img
                                      src={window.constants.asset_path + "/images/lock-new.svg"}
                                      width="24"
                                      height="24"
                                      style={{marginLeft: "6px", marginRight: "2px"}}
                                      alt="lock"
                                  />
                              )}
                          </button>
                      </div>
                  )}
            {mediaQueryVar
                ? null
                : analysis_data && (
                      <div
                          style={{
                              backgroundColor: "#E5F3FF",
                              borderRadius: "8px",
                              border: "0.3px solid #1877F2",
                              padding: "12px",
                              width: "100%",
                              fontFamily: "Satoshi, sans-serif",
                              fontWeight: "400",
                          }}
                      >
                          {paid_member === "yes" ? (
                              <>
                                  {/* Render the data if the user is a paid member */}
                                  <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                                      <div>All Influencers</div>
                                      <div>{renderAction(analysis_data.hashtags) || "-"}</div>
                                  </div>
                                  <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                                      <div>Top 10% Influencers</div>
                                      <div>{renderAction(analysis_data.top_10p_influencers) || "-"}</div>
                                  </div>
                                  <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                                      <div>Institutions</div>
                                      <div>{renderAction(analysis_data.indices) || "-"}</div>
                                  </div>
                                  <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                                      <div>Whales</div>
                                      <div>{renderAction(analysis_data.whales) || "-"}</div>
                                  </div>
                                 

                                  <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                                      <div>Individuals</div>
                                      <div>{renderAction(analysis_data.social_media) || "-"}</div>
                                  </div>

                                  <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                                      <div>Market Trend</div>
                                      <div>{renderAction(analysis_data.market_trend) || "-"}</div>
                                  </div>

                                  <div style={{display: "flex", justifyContent: "space-between", marginBottom: "0px"}}>
                                      <div>Grade</div>
                                      <div>{renderAction(analysis_data.grade) || "-"}</div>
                                  </div>
                              </>
                          ) : (
                              // Render the subscription button if the user is not a paid member
                              <div
                                  style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      backgroundColor: "#e7f2fd",
                                      borderRadius: "5px",
                                      padding: "2px",
                                      fontFamily: "Satoshi, sans-serif",
                                      width: "100%",
                                  }}
                              >
                                  {/* Left Column: List of Items */}
                                  <div style={{flex: 1}}>
                                      <div style={{marginBottom: "8px"}}>All Influencers</div>
                                      <div style={{marginBottom: "8px"}}>Top 10% Influencers</div>
                                      <div style={{marginBottom: "8px"}}>Indices</div>
                                      <div style={{marginBottom: "8px"}}>Whales</div>
                                      <div style={{marginBottom: "8px"}}>Individuals</div>
                                      <div style={{marginBottom: "0px"}}>Grade</div>
                                  </div>

                                  {/* Right Column: Button */}
                                  <div
                                      style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                      }}
                                  >
                                      <button
                                          style={{
                                              background: "linear-gradient(90deg, #1877F2 -19.84%, #519DFF 99.98%)",
                                              color: "#fff",
                                              fontSize: "12px",
                                              fontWeight: "700",
                                              borderRadius: "24.866px",
                                              padding: "10px 20px",
                                              border: "none",
                                              cursor: "pointer",
                                          }}
                                          onClick={handleNavigation}
                                      >
                                          Premium Signals @ $1 per month
                                      </button>
                                  </div>
                              </div>
                          )}
                      </div>
                  )}
            {mediaQueryVar
                ? null
                : loader === true && (
                    <button
                    onClick={() => {
                        window.open(`/crypto-screener`, "_blank");
                      }}
                      
  style={{
    padding: "14px 20px",
    fontSize: "22px",
    background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
    color: "#1E88E5",
    border: "2px solid #1E88E5",
    borderRadius: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center", // Centers content
    gap: "10px",
    cursor: "pointer",
    fontWeight: "700",
    marginBottom: "8%",
    width: "92%",
    height: "36px",
    marginTop:"15%",
    marginLeft:"4%",
  }}
>
  <span
    style={{
      position: "relative",
      display: "flex",
      alignItems: "center",
    }}
  >
    <span
      style={{
        width: "21px",
        height: "20px",
        backgroundImage: `url(${window.constants.asset_path}/images/down-graph.svg)`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    ></span>
    <span
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "23px",
        height: "23px",
        backgroundImage: `url(${window.constants.asset_path}/images/lens-new.svg)`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        marginLeft: "2px",
        marginBottom: "3px",
      }}
    ></span>
  </span>

  <span style={{ display: "flex", alignItems: "center", gap: "8px" }}>
    Crypto Screener
    <img
      src={window.constants.asset_path + "/images/link.svg"}
      width="22"
      height="22"
      alt="link"
    />
  </span>
</button>

                )}
                {mediaQueryVar
                ? null
                : loader == true && sortedData.length > 0 &&  (
                    <div
      style={{
        backgroundColor: "#1877F2", // Blue color
        color: "white",
        fontSize: "20px",
        fontWeight: "400",
        padding: "10px 12px",
        width: "100%",
        marginLeft:"0px",
        height:"50.461px",
        color:"#fff",
        marginBottom:"10%",
      }}
    >
Price Forecast
    </div>
                )}
    {mediaQueryVar
                ? null
                : 
                
                    <div>
                    {isPredictionDataLoaded === false || (loader==true && sortedData.length > 0) && (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "-20px",
                            marginBottom: 0,
                        }}
                    >
                        <span style={{fontSize: "17px", fontWeight: "700"}}>Price Forecasts</span>
                        <div
                            style={{
                                width: "47%",
                                display: "flex",
                                alignItems: "center",
                                border: "1px solid #1877F2",
                                borderRadius: "46px",
                                padding: "7px 10px",
                                background: "#F7F7F7",
                                marginBottom: "0px",
                                marginLeft: "15px",
                            }}
                        >
                            <img
                                src={window.constants.asset_path + "/images/search.svg"}
                                alt="Search Icon"
                                style={{
                                    marginLeft: "1%",
                                    marginRight: "2%",
                                    cursor: "pointer",
                                }}
                            />
                            <input
                                type="text"
                                placeholder="Search Forecaster"
                                style={{
                                    flex: 1, // Makes the input field take up the available space

                                    background: "transparent",
                                    color: "black",
                                    border: "none", // Remove default input border
                                    outline: "none", // Remove focus outline
                                }}
                                onChange={handleSearchChange}
                            />
                        </div>

                        <div>
                            <Box
                                sx={{
                                    borderRadius: "10px",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginBottom: "0px",
                                    color: "#1877F2",
                                    marginLeft: "-10px",
                                }}
                            >
                                <Button onClick={handlePopoverOpen}>
                                    <div
                                        style={{
                                            padding: "6px 2px",
                                        }}
                                    >
                                        <img width={20} height={20} 
        alt="filter2" src={window.constants.asset_path + "/images/filter2.svg"} />
                                    </div>
                                </Button>

                                <Popover
                                    open={openfilter}
                                    anchorEl={anchorEl}
                                    onClose={handleCloseeoption}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "center",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "center",
                                    }}
                                    style={{}}
                                >
                                    <MenuItem
                                        selected={selectedFilter === "fc_asc"}
                                        onClick={() => handleSort("forecaster")}
                                    >
                                        Forecaster {sortOrder.forecaster === "asc" ? "↑" : "↓"}
                                    </MenuItem>

                                    <MenuItem
                                        selected={selectedFilter === "tg_asc"}
                                        onClick={() => handleSort("target")}
                                    >
                                        Target {sortOrder.target === "asc" ? "↑" : "↓"}
                                    </MenuItem>
                                    <MenuItem
                                        selected={selectedFilter === "ac_asc"}
                                        onClick={() => handleSort("accuracy")}
                                    >
                                        Accuracy {sortOrder.accuracy === "asc" ? "↑" : "↓"}
                                    </MenuItem>

                                    <MenuItem
                                        selected={selectedFilter === "pr_asc"}
                                        onClick={() => handleSort("percent")}
                                    >
                                        Achieved {sortOrder.percent === "asc" ? "↑" : "↓"}
                                    </MenuItem>
                                    <MenuItem
                                        selected={selectedFilter === "fd_asc"}
                                        onClick={() => handleSort("targetDate")}
                                    >
                                        Target Date {sortOrder.targetDate === "asc" ? "↑" : "↓"}
                                    </MenuItem>

                                    <Box sx={{display: "flex", padding: "8px"}}>
                                        <Button
                                            size="small"
                                            sx={{textTransform: "capitalize", marginLeft: "10px"}}
                                            onClick={handleClearAll}
                                            startIcon={<ClearIcon />}
                                        >
                                            Clear All
                                        </Button>
                                    </Box>
                                </Popover>
                            </Box>
                        </div>
                    </div>
                    )}
                    
                    <div
                        className="predictions-table-mobile"
                        style={{
                            width: "100%",
                            borderCollapse: "separate",
                            borderSpacing: "0 10px",
                        }}
                    >
                        {isPredictionDataLoaded === false  && loader==false? (
                            <SkeletonLoaderMobileList />
                        ) : sortedData.length === 0 ? ( // Check if no data is found
                            <div>
                               
                                <div
                                    style={{
                                        border: "1px solid #B6B6B8",
                                        margin: "0px 2%",
                                        marginTop: "10px",
                                        textAlign: "center",
                                        color: "black",
                                        fontSize: "18px",
                                        backgroundColor: "white",
                                        padding: "2%",
                                        borderRadius: "8px",
                                        marginBottom: "3%",
                                    }}
                                >
                                    No Predictions available.
                                </div>
                               
                            </div>
                        ) : (
                            <div>
                                 {sortedData.slice(0, rowsToShow).map((data, index) => (
        <MobileForecastCard key={index} data={data} />
      ))}
                                <button
                            onClick={() => {
                                window.open(`/crypto/${crypto}/predictions`, "_blank");
                              }}
                              
                            style={{
                                padding: "6px 12px",
                                fontSize: "19px",
                                background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                color: "#1E88E5", // Blue text
                                border: "2px solid #1E88E5",
                                borderRadius: "20px",
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                                cursor: "pointer",
                                fontWeight: "700",
                                marginTop:"10%",
                                marginBottom:"2%",
                                width:"92%",
                                marginLeft:"4%"
                            }}
                        >
                            <span
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "28.769px", // Adjust width as needed
                                    height: "31.385px", // Adjust height as needed
                                    backgroundImage: `url(${
                                        window.constants.asset_path + "/images/round-arrow.svg"
                                    })`,
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: "18px", // Adjust size of dollar symbol
                                        color: "#1E88E5", // Match text color
                                        marginTop: "5px",
                                    }}
                                >
                                    $
                                </span>
                            </span>
                            <span
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    textAlign: "center",
                                    margin: "0px 14px",
                                }}
                            >
                                <span style={{marginLeft: "-17px"}}>Price Forecasts</span>
                                <span
                                    style={{
                                        color: "#1877F2",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        marginLeft: "5px",
                                    }}
                                >
                                    By Influencers and Publishers
                                </span>
                            </span>
                            <span style={{marginLeft: "auto"}}>
                                <img
                                    src={window.constants.asset_path + "/images/link.svg"}
                                    width="24"
                                    height="24"
                                    alt="link"
                                />
                            </span>
                        </button>
                            </div>
                        )}
                    </div>
                   {/* { !userData && (
                                                               <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                               <Button
    onClick={() => {
        const originalUrl = window.location.href; 
        sessionStorage.setItem("originalUrl", originalUrl);
        handleLoginClick();
    }}
                                                                   style={{
                                                                       color: "white",
                                                                       backgroundColor: "#1877F2",
                                                                       textTransform: "none",
                                                                       minWidth: mediaQueryVar ? "auto" : "200px",
                                                                       height: mediaQueryVar ? "auto" : "45px",
                                                                       marginBottom: "10px",
                                                                       fontWeight: "600",
                                                                       fontSize: "18px",
                                                                       borderRadius: "30px",
                                                                       marginTop: mediaQueryVar ? "10px" : "3%",
                                                                       padding: "8px 24px",
                                                                       boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                                                                       border: "none",
                                                                       cursor: "pointer",
                                                                   }}
                                                               >
                                                                   Register for Updates
                                                               </Button>
                                                           </div>
                                                           
                                                           )} */}
                </div>

    }
            {mediaQueryVar
                ? null
                : loader === true && (
                        <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: 1,
                          mt: 3,
                          margin: "30px 0.5% 10% 0.5%",
                          backgroundColor: "#fff",
                          padding: "20px 0px",
                          borderRadius: "8px",
                        }}
                      >
                        {/* Header Box */}
                        <Box
                          sx={{
                            backgroundColor: "#1877F2",
                            color: "#fff",
                            padding: "12px 24px",
                            fontSize: "16px",
                            fontWeight: "bold",
                            textAlign: "center",
                            width: "100%",
                            height: "44px",
                            marginBottom: "10px",
                          }}
                        >
                          Are You?
                        </Box>
                  
                        {/* Bearish Section */}
                        <Box sx={{ display: "flex", alignItems: "center", width: "100%", gap: 1, marginBottom: "5px" }}>
                          <img src={"/images/icons_bearish.svg"} alt="Bearish" style={{ width: "24px", height: "24px" }} />
                          <Button
                            sx={{
                              flex: 1,
                              background: "var(--Grad, linear-gradient(180deg, rgba(24, 119, 242, 0.40) -1.89%, rgba(24, 119, 242, 0.00) 60.38%))",
                              color: "#000",
                              borderRadius: "10px",
                              padding: "10px 20px",
                              height: "24px",
                              "&:hover": {
                                background: "linear-gradient(to right, #f0f0f0, #bbdefb)",
                              },
                              textTransform: "none",
                              fontSize: "14px",
                              fontWeight: "400",
                              display: "flex",
                              justifyContent: "space-between",
                              border: "1px solid #EEE",
                            }}
                            onClick={() => handleSentimentClick("bearish")}
                          >
                            <span>Bearish On {slug}</span>
                            {showPercentage && <Typography sx={{ fontSize: "14px", color: "#1877F2", fontWeight: "700" }}>{votes.bearish}%</Typography>}
                          </Button>
                        </Box>
                  
                        {/* Bullish Section */}
                        <Box sx={{ display: "flex", alignItems: "center", width: "100%", gap: 1 }}>
                          <img src={"/images/icons_bullish.svg"} alt="Bullish" style={{ width: "24px", height: "24px" }} />
                          <Button
                            sx={{
                              flex: 1,
                              background: "var(--Grad, linear-gradient(180deg, rgba(24, 119, 242, 0.40) -1.89%, rgba(24, 119, 242, 0.00) 60.38%))",
                              color: "#000",
                              borderRadius: "10px",
                              padding: "10px 20px",
                              height: "24px",
                              "&:hover": {
                                background: "linear-gradient(to right, #f0f0f0, #bbdefb)",
                              },
                              textTransform: "none",
                              fontSize: "14px",
                              fontWeight: "400",
                              display: "flex",
                              justifyContent: "space-between",
                              border: "1px solid #EEE",
                            }}
                            onClick={() => handleSentimentClick("bullish")}
                          >
                            <span>Bullish On {slug}</span>
                            {showPercentage && <Typography sx={{ fontSize: "14px", color: "#1877F2", fontWeight: "700" }}>{votes.bullish}%</Typography>}
                          </Button>
                        </Box>
                      </Box>
                  
                )}
            

            {mediaQueryVar
                ? null
                : overallSummary &&
                  loader && (
                      <div>
                          <div
      style={{
        backgroundColor: "#1877F2", // Blue color
        color: "white",
        fontSize: "20px",
        fontWeight: "400",
        padding: "10px 12px",
        width: "100%",
        marginLeft:"0px",
        height:"50.461px",
        color:"#fff",
        marginBottom:"8%",
        marginTop:"4%"
      }}
    >
Technical Summary
    </div>                <p style={{margin: "-10px 0 1% 1%", color:"#1877F2", fontSize:"14px", fontWeight:"700"}}>Summary</p>
                          <p style={{margin: "0px 0 1% 1%"}}>
                              In the next week, the overall sentiment for {slug} crypto price is {overallSummary}. The
                              first level of support for {slug} is at {pivotdata.pivot_s1}&nbsp; while the first level
                              of resistance for {slug} price is at {pivotdata.pivot_r1}.&nbsp;
                              {sentimentStatement}
                          </p>
                      </div>
                  )}
            {mediaQueryVar ? null : (
                <div style={{fontFamily: "Satoshi, sans-serif", marginBottom: "11%"}}>
                    {(hasOscillatorData || hasMovingAverageData) && (
                        <div>
                            
                            {(hasOscillatorData || hasMovingAverageData) && (
                                <h5
                                    style={{
                                        textAlign: "right",
                                        fontStyle: "italic",
                                        margin: "5px 1%",
                                        fontSize: "10px",
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                    }}
                                >
                                    {timestamp}&nbsp;UTC
                                </h5>
                            )}

                            {oscillatorData.length > 0 && (
                                <>
                                    {/* Check if either RSI or MACD is present */}
                                    {(oscillatorData[0].RSI || oscillatorData[4].MACD) && (
                                        <>
                                            {oscillatorData[0].RSI && (
                                                <p style={{fontSize: "16px", margin: "0% 2% 0% 0.5%"}}>
                                                    The RSI value is at {oscillatorData[0].RSI}, which indicates that
                                                    the {slug} market is in a {oscillatorData[0].rsi_evaluation}{" "}
                                                    position.
                                                </p>
                                            )}

                                            {oscillatorData[4].MACD && (
                                                <p
                                                    style={{
                                                        fontSize: "16px",
                                                        margin: "2% 1% 0% 0.5%",
                                                    }}
                                                >
                                                    The MACD value is {oscillatorData[4].MACD}.{" "}
                                                    {oscillatorData[4].macd_evaluation === "Buy" ? (
                                                        <>
                                                            The MACD line is above the signal line, and the green
                                                            histogram bars have started to increase on the positive
                                                            axis. This indicates that {slug} is in a buy position.
                                                        </>
                                                    ) : (
                                                        <>
                                                            The MACD line is below the signal line, and the red
                                                            histogram bars have started to increase on the negative
                                                            axis. This indicates that {slug} is in a sell position.
                                                        </>
                                                    )}
                                                    <br />
                                                    <br />
                                                </p>
                                            )}
                                        </>
                                    )}
                                </>
                            )}

                            {movingAveragesData.length > 0 && (
                                <>
                                    {(movingAveragesData[0]["SMA (10)"] ||
                                        movingAveragesData[4]["EMA (10)"] ||
                                        movingAveragesData[1]["SMA (50)"] ||
                                        movingAveragesData[5]["EMA (50)"] ||
                                        movingAveragesData[2]["SMA (100)"] ||
                                        movingAveragesData[3]["SMA (200)"] ||
                                        movingAveragesData[6]["EMA (100)"] ||
                                        movingAveragesData[7]["EMA (200)"]) && (
                                        <>
                                            <p
                                                style={{
                                                    fontSize: "16px",
                                                    margin: "2% 1% 0% 0.5%",
                                                    marginBottom: "10px",
                                                }}
                                            >
                                                {movingAveragesData[0]["SMA (10)"] && (
                                                    <>
                                                        For {slug}, the 10-day SMA is{" "}
                                                        {movingAveragesData[0]["SMA (10)"]}. As the price of {slug} is{" "}
                                                        {currentPrice < movingAveragesData[0]["SMA (10)"]
                                                            ? "below"
                                                            : "above"}{" "}
                                                        the 10-day SMA, it is{" "}
                                                        {currentPrice < movingAveragesData[0]["SMA (10)"]
                                                            ? "bearish"
                                                            : "bullish"}
                                                        . The short-term outlook is{" "}
                                                        {currentPrice < movingAveragesData[0]["SMA (10)"]
                                                            ? "negative"
                                                            : "positive"}
                                                        .
                                                    </>
                                                )}

                                                {movingAveragesData[1]["SMA (50)"] && (
                                                    <>
                                                        &nbsp;Additionally, the 50-day SMA is{" "}
                                                        {movingAveragesData[1]["SMA (50)"]}. {slug} is{" "}
                                                        {currentPrice < movingAveragesData[1]["SMA (50)"]
                                                            ? "below"
                                                            : "above"}{" "}
                                                        its 50-day SMA, it is{" "}
                                                        {currentPrice < movingAveragesData[1]["SMA (50)"]
                                                            ? "bearish"
                                                            : "bullish"}
                                                        .
                                                    </>
                                                )}

                                                {(movingAveragesData[2]["SMA (100)"] ||
                                                    movingAveragesData[3]["SMA (200)"]) && (
                                                    <>
                                                        &nbsp;The 100-day and 200-day SMAs representing the long-term
                                                        outlook of {slug} are&nbsp;
                                                        {movingAveragesData[2]["SMA (100)"] || "-"} and{" "}
                                                        {movingAveragesData[3]["SMA (200)"] || "-"} respectively.
                                                        Currently, the price of {slug} is{" "}
                                                        {currentPrice < movingAveragesData[2]["SMA (100)"] &&
                                                        currentPrice < movingAveragesData[3]["SMA (200)"]
                                                            ? "below"
                                                            : "above"}{" "}
                                                        both the 100-day and 200-day Moving Averages. It is{" "}
                                                        {currentPrice < movingAveragesData[2]["SMA (100)"] &&
                                                        currentPrice < movingAveragesData[3]["SMA (200)"]
                                                            ? "bearish"
                                                            : "bullish"}
                                                        .
                                                    </>
                                                )}
                                            </p>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </div>
            )}
            {mediaQueryVar
                ? null
                : loader === true && indexData.length > 0 && (
                    <div
      style={{
        backgroundColor: "#1877F2", // Blue color
        color: "white",
        fontSize: mediaQueryVar2? "16px":"20px",
        fontWeight: "400",
        padding: "10px 6px",
        width: "100%",
        marginLeft:"0px",
        height:"50.461px",
        color:"#fff",
        marginBottom:"1%",
        marginTop:"20px"
      }}
    >
Cryptos Influencers Are Currently Bullish On
    </div>

                )}
    {mediaQueryVar
                ? null
                : loader === true && indexData.length > 0 && (
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 3, padding: 3,  }}>
                    {indexData.map((data, index) => (
                      <Box
                        key={index}
                        sx={{
                          width: "fit-content", // Ensures content width
                          padding: "12px 16px",
                          borderRadius: "20px",
                          background: "linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%)",
                          textAlign: "center",
                          border: "1.5px solid #1877F2",
                          minWidth: mediaQueryVar2?"100%":"85%",
                          maxWidth: mediaQueryVar2?"100%":"85%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "stretch"
                        }}
                      >
                        <Typography sx={{ fontWeight: "500", mb: 1, textAlign: "center", fontSize: "22px" }}>
                        <img
      className="b57ac673f06a4b0338a596817eb0a5Icon"
      alt="logo"
      src={data.logo}
      style={{
        width: "38px",
        height: "38px",
        border: "2px solid black",
        borderRadius: "20px",
        marginRight: "8px",
      }}
    /><a
    href={`/crypto/${data.name.replace(/\s+/g, "-")}`}

    style={{color: "black"}}
    
>{data.title}</a>
                        </Typography>
                        <Box sx={{ width: "96%", height: "1.5px", background: "#1877F2", mb: 2, marginLeft: "7px" }} />
                        <Box sx={{ display: "flex", alignItems: "center", gap: 2, px: 1 }}>
                          <Box sx={{ display: "flex", flexDirection: "column", fontSize: "14px", textAlign: "left", flex: 1 }}>
                            <Typography style={{ marginTop: "5px" }}>Last 7 Days</Typography>
                            <Typography style={{ marginTop: "5px" }}>Last 30 Days</Typography>
                            <Typography style={{ marginTop: "5px" }}>Grade</Typography>
                          </Box>
                          <Box sx={{ width: "1.5px", background: "#1877F2", alignSelf: "stretch" }} />
                          <Box sx={{ display: "flex", flexDirection: "column", fontSize: "14px", textAlign: "left", flex: 1 }}>
                            <Typography style={{ marginTop: "5px" }}>{data.last7Days}</Typography>
                            <Typography style={{ marginTop: "5px" }}>{data.last30Days}</Typography>
                            <Typography sx={{ marginTop: "5px" }}>
                            {
    data.benchmark === 'Outperformer' ? (
    <button disabled style={{ fontWeight: "bold", padding: "3px" , borderRadius: "5px", backgroundColor: '#DFFFF0', color: '#199F5F', border: '2px solid #199F5F', width: "125px",fontSize:  "12px" }}>Outperformer</button>
  ) : data.benchmark === 'Emerging Outperformer' ? (
  <button disabled style={{ fontWeight: "bold", padding: "3px" , borderRadius: "5px", backgroundColor: '#DFFFF0', color: '#199F5F', border: '2px solid #199F5F',  width: "125px",fontSize:  "12px"}}>Outperformer</button>
) :
data.benchmark === 'Moderate Performer' ? (
    <button disabled style={{ fontWeight: "bold", padding: "3px", borderRadius: "5px", backgroundColor: '#F9F4E7', color: '#D4AF37', border: '2px solid #D4AF37',  width: "125px",fontSize: "12px"}}>Moderate Performer</button>
  )  : data.benchmark === 'Underperformer' ? (
    <button disabled style={{ alignItems: 'center', fontWeight: "bold", padding:"3px ", borderRadius: "5px", backgroundColor: '#FFE3E3', color: '#FF4141', border: '2px solid #FF4141',  width: "125px",fontSize: "12px" }}>Underperformer</button>
) : data.benchmark}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  

                )}
                {mediaQueryVar
                ? null
                : loader === true && (indexData.length > 0 || indexPortfolios.length > 0) && (
                    <button
                    onClick={() => {
                      window.open(`/crypto/${crypto}/trends`, "_blank");
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                    
                    style={{
                      fontSize: "20px",
                      backgroundColor: "#fff",
                      color: "#1E88E5",
                      border: "2px solid #1E88E5",
                      borderRadius: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "12px",
                      cursor: "pointer",
                      fontWeight: "700",
                      padding: "1px 20px",
                      marginBottom: "50px",
                      marginTop: "0%",
                      width: "100%",
                      flexWrap: "nowrap",
                    }}
                  >
                    <span
                      style={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          width: "21px",
                          height: "20px",
                          backgroundImage: `url(${window.constants.asset_path}/images/down-graph.svg)`,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                        }}
                      ></span>
                      <span
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: "25px",
                          height: "25px",
                          backgroundImage: `url(${window.constants.asset_path}/images/lens-new.svg)`,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          marginLeft: "4px",
                          marginBottom: "7px",
                        }}
                      ></span>
                    </span>
                    <span style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    Explore Influencer And Investor Sentiments
              
                      <img
                        src={`${window.constants.asset_path}/images/link.svg`}
                        width="24"
                        height="24"
                        alt="link"
                        style={{marginLeft:"5%"}}
                      />
                    </span>
                  </button>
                )}
             {mediaQueryVar
                ? null
                : loader === true && indexPortfolios.length > 0 && (
                    <div
      style={{
        backgroundColor: "#1877F2", // Blue color
        color: "white",
        fontSize: "20px",
        fontWeight: "400",
        padding: "10px 12px",
        width: "100%",
        marginLeft:"0px",
        height:"50.461px",
        color:"#fff",
        marginBottom:"1%",
        marginTop:"20px"
      }}
    >
Top Performing Indices
    </div>
                )}
    {mediaQueryVar
                ? null
                : loader === true && indexPortfolios.length > 0 && (
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 3, padding: 3 }}>
                    {indexPortfolios.map((data, index) => (
                      <Box
                        key={index}
                        sx={{
                          width: "fit-content", // Ensures content width
                          padding: "12px 16px",
                          borderRadius: "20px",
                          background: "linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%)",
                          textAlign: "center",
                          border: "1.5px solid #1877F2",
                          minWidth: mediaQueryVar2?"100%":"85%",
                          maxWidth: mediaQueryVar2?"100%":"85%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "stretch"
                        }}
                      >
                        
                        <Typography sx={{ fontWeight: "500", mb: 1, textAlign: "center", fontSize: "22px" }}>
                        <img
      className="b57ac673f06a4b0338a596817eb0a5Icon"
      alt="logo"
      src={data.logo}
      style={{
        width: "38px",
        height: "38px",
        border: "2px solid black",
        borderRadius: "20px",
        marginRight: "8px",
      }}
    /><a
    href={`/portfolio/${data.title.replace(/\s+/g, "-")}`}


    style={{color: "black"}}
    
>{data.title}</a>
                        </Typography>
                        <Box sx={{ width: "96%", height: "1.5px", background: "#1877F2", mb: 2, marginLeft: "7px" }} />
                        <Box sx={{ display: "flex", alignItems: "center", gap: 2, px: 1 }}>
                          <Box sx={{ display: "flex", flexDirection: "column", fontSize: "14px", textAlign: "left", flex: 1 }}>
                            <Typography style={{ marginTop: "5px" }}>Last 7 Days</Typography>
                            <Typography style={{ marginTop: "5px" }}>Last 30 Days</Typography>
                            <Typography style={{ marginTop: "5px" }}>Grade</Typography>
                          </Box>
                          <Box sx={{ width: "1.5px", background: "#1877F2", alignSelf: "stretch" }} />
                          <Box sx={{ display: "flex", flexDirection: "column", fontSize: "14px", textAlign: "left", flex: 1 }}>
                            <Typography style={{ marginTop: "5px" }}>{data.last7Days}</Typography>
                            <Typography style={{ marginTop: "5px" }}>{data.last30Days}</Typography>
                            <Typography sx={{ marginTop: "5px" }}>
                            {
    data.benchmark === 'Outperformer' ? (
    <button disabled style={{ fontWeight: "bold", padding: "3px" , borderRadius: "5px", backgroundColor: '#DFFFF0', color: '#199F5F', border: '2px solid #199F5F', width: "125px",fontSize:  "12px" }}>Outperformer</button>
  ) : data.benchmark === 'Emerging Outperformer' ? (
  <button disabled style={{ fontWeight: "bold", padding: "3px" , borderRadius: "5px", backgroundColor: '#DFFFF0', color: '#199F5F', border: '2px solid #199F5F',  width: "125px",fontSize:  "12px"}}>Outperformer</button>
) :
data.benchmark === 'Moderate Performer' ? (
    <button disabled style={{ fontWeight: "bold", padding: "3px", borderRadius: "5px", backgroundColor: '#F9F4E7', color: '#D4AF37', border: '2px solid #D4AF37',  width: "125px",fontSize: "12px"}}>Moderate Performer</button>
  )  : data.benchmark === 'Underperformer' ? (
    <button disabled style={{ alignItems: 'center', fontWeight: "bold", padding:"3px ", borderRadius: "5px", backgroundColor: '#FFE3E3', color: '#FF4141', border: '2px solid #FF4141',  width: "125px",fontSize: "12px" }}>Underperformer</button>
) : data.benchmark}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>

                )} 
            {mediaQueryVar
                ? null
                : loader === true && (indexData.length > 0 || indexPortfolios.length > 0) && (
                    <div style={{ display: "flex", justifyContent: "center", gap: "2%" }}>
                    <button
                      onClick={() => {
                        window.open(`/crypto-screener`, "_blank");
                      }}
                      
                      style={{
                        fontSize: "24px",
                        background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                        color: "#1E88E5",
                        border: "2px solid #1E88E5",
                        borderRadius: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "12px",
                        cursor: "pointer",
                        fontWeight: "700",
                        padding: "10px 20px",
                        marginBottom: "30px",
                        marginTop: "2%",
                        width: "fit-content",
                        minWidth: "90%",
                        flexWrap: "nowrap",
                      }}
                    >
                      <span
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            width: "23px",
                            height: "22px",
                            backgroundImage: `url(${window.constants.asset_path}/images/down-graph.svg)`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                          }}
                        ></span>
                        <span
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "26px",
                            height: "26px",
                            backgroundImage: `url(${window.constants.asset_path}/images/lens-new.svg)`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            marginLeft: "4px",
                            marginBottom: "7px",
                          }}
                        ></span>
                      </span>
                      <span style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        Crypto Screener
                        <img
                          src={`${window.constants.asset_path}/images/link.svg`}
                          width="24"
                          height="24"
                          alt="link"
                        />
                      </span>
                    </button>
                  </div>

            )}
            {mediaQueryVar
                ? null
                : loader === true && (
                      <div>
                          <div>
                          {processedDescription && (
                                    <span style={{marginLeft:"2px", fontWeight:"700", fontSize:"16px", 
                                        color:"#1877F2"}}>{slug} Analysis</span>
                                    )}
                          </div>
                          <div
                              className="description"
                              style={{
                                  fontFamily: "Satoshi, sans-serif!important",
                                  padding: "1%",
                                  borderRadius: "4px",
                                  marginBottom: "2%",
                                  marginTop: "0px",
                                  marginLeft: "0%",
                                  marginRight: "2%",
                              }}
                          >
                              {processedDescription ? (
                                  <div
                                      style={{marginTop: "6px"}}
                                      dangerouslySetInnerHTML={{__html: processedDescription}}
                                  />
                              ) : (
                                  <p></p> // or any other placeholder text
                              )}
                          </div>
                      </div>
                  )}
            {/* {mediaQueryVar
                ? null
                : loader === true && (
                      <div>
                          <p style={{fontSize: "20px", fontWeight: "700", color: "#1877F2", background: "white"}}>
                              Updates
                          </p>
                          <div
                              className="description"
                              style={{
                                  fontFamily: "Satoshi, sans-serif!important",
                                  borderRadius: "4px",
                                  padding: "2px",
                                  marginTop: "15px",
                                  marginBottom: "8%",
                              }}
                          >
                              {analysis_data.ai_news && analysis_data.ai_news != "NA" ? 
                              (
                                  analysis_data.ai_news
                                  .split("\n") 
                                  .filter((line) => line.trim() !== "") 
                                  .map((line, index) => (
                                      <p key={index}>{line.trim().replace(/^-\s, "")}</p> 
                                  ))
                              ) : (
                                  <p>No Updates Available</p>
                              )}
                          </div>
                      </div>
                  )} */}

            {mediaQueryVar ? (
                <div className="layout">
                    <div className>
                        <Box
                            component="nav"
                            sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
                            aria-label="mailbox folders"
                        >
                            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                            <Drawer
                                container={container}
                                variant="temporary"
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}
                                sx={{
                                    display: {xs: "block", sm: "none"},
                                    "& .MuiDrawer-paper": {
                                        boxSizing: "border-box",
                                        width: drawerWidth,
                                        backgroundColor: "white",
                                        color: "#727376",
                                    },
                                }}
                            >
                                {drawer}
                            </Drawer>
                            <Drawer
                                variant="permanent"
                                sx={{
                                    display: {xs: "none", sm: "block"},
                                    "& .MuiDrawer-paper": {
                                        boxSizing: "border-box",
                                        width: drawerWidth,
                                        backgroundColor: "white",
                                        color: "#727376",
                                    },
                                }}
                                open
                            >
                                {drawer}
                            </Drawer>
                        </Box>
                    </div>

                    <div
                        className="content"
                        style={{
                            margin: "0%",
                            padding: "10px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                marginBottom:"9%"
                            }}
                        >
                            <Box
                                sx={{
                                    backgroundColor: "#1877F2", // Background color for the container
                                    paddingTop: {xs: "10px", sm: "4px"}, // Padding inside the container
                                    paddingLeft: {xs: "0px", sm: "0px"},
                                    paddingRight: {xs: "0px", sm: "0px"},
                                    paddingBottom: {xs: "0px", sm: "4px"},
                                    borderRadius: "50px", // Border radius for the container
                                    display: "flex", // Make sure the box is flexible
                                    justifyContent: "flex-start", // Center the content
                                    alignItems: "center", // Center the content
                                    display: "inline-block",
                                    minWidth: isPredictionDataLoaded ? "380px" : "360px",
                                    height: {xs: "44px", sm: "48px"},
                                    border: "none",
                                    marginLeft: "0.3%",
                                    marginTop: "10px",
                                }}
                            >
                                <Tabs
                                    value={selectedButton}
                                    onChange={(event, newValue) => handleButtonClick(newValue)}
                                    aria-label="basic tabs example"
                                    sx={{
                                        alignSelf: mediaQueryVar === true ? "flex-start" : "",
                                        marginLeft: mediaQueryVar === true ? "2px" : "2px",
                                        borderRadius: "50px", // Adjust border radius for more rounded corners
                                        border: "none",
                                        "& .MuiTabs-indicator": {
                                            display: "none",
                                        },
                                        display: "flex", // Make sure the tabs are displayed in a row
                                        overflow: "hidden", // Prevent the tabs from spilling out of the border radius
                                    }}
                                >
                                    <Tab
                                        label={isPredictionDataLoaded ? `${slug} Price Analysis` : "Price Analysis"}
                                        value="CoinPrices"
                                        sx={{
                                            whiteSpace: "nowrap",
                                            bottom: "0px",
                                            marginTop: "0px",
                                            marginBottom: "0px",
                                            marginLeft: "3px",
                                            marginRight: "3px",
                                            paddingBottom: "0px",
                                            paddingTop: "0px",
                                            paddingLeft: "20px",
                                            paddingRight: "20px",
                                            minHeight: "20px",
                                            lineHeight: {xs: "36px", sm: "36px"},
                                            borderRadius: selectedButton === "CoinPrices" ? "50px" : "50px", // Rounded corners for selected tab
                                            textTransform: "none",
                                            fontSize: "17px",
                                            fontStyle: "normal",
                                            fontWeight: selectedButton === "CoinPrices" ? "700" : "500",

                                            backgroundColor:
                                                selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // White background for selected tab
                                            color: selectedButton === "CoinPrices" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
                                            border: "none", // Remove border for tabs
                                            background: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
                                        }}
                                    />
                                                          
                                                          <Tab
  label="Portfolio Audit"
  value="PortfolioAudit"
  onClick={() => {
    navigate("/audit-my-portfolio");
  }}
  sx={{
    whiteSpace: "nowrap",
    bottom: "0px",
    marginTop: "1.1px",
    marginBottom: "0px",
    marginLeft: "7px",
    marginRight: "2px", // Reduced space between tabs
    paddingBottom: "2px",
    paddingTop: "0px",
    paddingLeft: "15px", // Adjusted padding for better spacing
    paddingRight: "15px",
    minHeight: "20px",
    lineHeight: { xs: "28px", sm: "36px" },
    borderRadius: selectedButton === "PortfolioAudit" ? "20px" : "20px",
    textTransform: "none",
    fontSize: { xs: "13px", sm: "17px" },
    fontStyle: "normal",
    fontWeight: selectedButton === "PortfolioAudit" ? "700" : "500",
    backgroundColor: selectedButton === "PortfolioAudit" ? "#FFFFFF" : "transparent",
    color: selectedButton === "PortfolioAudit" ? "#000000 !important" : "#FFFFFF",
    border: "none",
  }}
/>

<Tab
  label="Find Replacement"
  value="AuditPortfolio"
  onClick={() => {
    navigate("/crypto-screener/replace-your-crypto");
  }}
  sx={{
    whiteSpace: "nowrap",
    bottom: "0px",
    marginTop: "0px",
    marginBottom: "0px",
    marginLeft: "0px", // Reduced space between tabs
    marginRight: "0px", // Removed negative margin
    paddingBottom: "0px",
    paddingTop: "0px",
    paddingLeft: "15px",
    paddingRight: "15px",
    minHeight: "20px",
    lineHeight: { xs: "36px", sm: "36px" },
    borderRadius: selectedButton === "AuditPortfolio" ? "50px" : "50px",
    textTransform: "none",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: selectedButton === "AuditPortfolio" ? "700" : "500",
    backgroundColor: selectedButton === "AuditPortfolio" ? "#FFFFFF" : "transparent",
    color: selectedButton === "AuditPortfolio" ? "#000000 !important" : "#FFFFFF",
    border: "none",
  }}
/>

                                </Tabs>
                            </Box>
                             <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginTop: "14px",
                                marginLeft: "0.7%",
                                marginRight: "0.5%",
                            }}
                        >
                            <div style={{display: "flex", alignItems: "center"}}>
                                <button
                                    onClick={() => handleAddToPortfolio(slug)}
                                    style={{
                                        border: "1px solid #1877F2",
                                        borderRadius: "48px",
                                        padding: "5px 10px",
                                        backgroundColor: "white",
                                        color: "#1877F2",
                                        fontSize: "14px",
                                        height: "33px",
                                        marginLeft: "8px",
                                        fontWeight: "500",
                                    }}
                                >
                                    Add to Portfolio
                                </button>
                                <div 
                                  className="notification-section position-absolute end-0 me-4" 
                                  style={{marginRight:"20px"}}
                                  onClick={() => navigate("/alerts")}
                                >
                                  <NotificationIcon className="notification-icon fa-2x" />
                                  <span className="badge rounded-pill badge-notification bg-danger position-absolute top-0 start-100">
                                    {unreadAlerts}
                                  </span>
                                </div>
                                {/* <button
      onClick={() => alert("Alerts functionality coming soon!")}
      style={{
        border: "1px solid #1877F2",
        borderRadius: "6px",
        padding: "6px 14px",
        backgroundColor: "rgba(24, 119, 242, 0.12)",
        color: "#1877F2",
        fontSize: "14px",
        height: "33px",
        border: "1px solid rgba(24, 119, 242, 0.12)",
        fontWeight:"500"
      }}>
      <img src={window.constants.asset_path + "/images/notification.svg"} width="14" height="14" style={{ marginRight: "5px" }} />
      Alerts
    </button> */}
                            </div>
                        </div>
                        </div>

                       

                        <div
                            style={{
                                borderRadius: "8px",
                                backgroundColor: "#fff",
                                padding: "10px 0px 7px 0px",
                                fontFamily: "Satoshi, sans-serif",
                                margin: "13px 5px",
                                border:"1.5px solid #1877F2"

                            }}
                        >
                            <button
                                style={{
                                    fontSize: "20px",
                                    fontWeight: "700",
                                    border: "1px solid var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                    borderRadius: "12px",
                                    background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                    padding: "4px 10px",
                                    marginLeft: "5px",
                                    marginBottom: "10px",
                                    color: "black",
                                }}
                            >
                                Performance Metrics
                            </button>
                            <button
                                    onClick={() => handleStarClick(slug)}
                                    style={{
                                        border: `1px solid ${CryptoWatchlist.includes(slug) ? "red" : "#1877F2"}`,
                                        borderRadius: "48px",
                                        padding: "5px 10px",
                                        backgroundColor: CryptoWatchlist.includes(slug) ? "red" : "white",
                                        color: CryptoWatchlist.includes(slug) ? "white" : "#1877F2",
                                        fontSize: "14px",
                                        height: "33px",
                                        marginLeft: "8px",
                                        fontWeight: "500",
                                    }}
                                >
                                    {CryptoWatchlist.includes(slug) ? "Remove from Watchlist" : "Add to Watchlist"}
                                </button>
                            {/* <button
      onClick={() => handleAddToPortfolio(slug)}
      style={{
        border: "1px solid #1877F2",
        borderRadius: "48px",
        padding: "6px 14px",
        backgroundColor: "white",
        color: "#1877F2",
        fontSize: "14px",
        height: "33px",
        marginLeft: "6px",
        fontWeight:"500"
      }}>
      Add to Watchlist
    </button> */}

                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center",
                            }}>
                                <TableContainer
                                                                    style={{
                                                                        width: "100%",
                                                                        overflowX: "hidden",
                                                                        padding: 0,
                                                                    }}
                                                                >
                                                                    <Table>
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <StyledTableCell
                                                                                    style={{
                                                                                        border: 0,
                                                                                        borderTop: "1px solid #1877F2",
                                                                                        borderBottom: "1px solid #1877F2",
                                                                                        borderRight: "1px solid #1877F2",
                                                                                        padding: 0,
                                                                                        width: "30%",
                                                                                    }}
                                                                                >
                                                                                    <button
                                                                                        disabled
                                                                                        style={{
                                                                                            textAlign: "center",
                                                                                            color: "#1877F2",
                                                                                            fontSize: "16px",
                                                                                            width: "100%",
                                                                                            height: "100%",
                                                                                            background: "var(--Grad, linear-gradient(180deg, rgba(24, 119, 242, 0.40) -1.89%, rgba(24, 119, 242, 0.00) 60.38%))",                                                                                            padding: "19px 12px 5px 12px",
                                                                                            fontWeight: "900",
                                                                                            border: "none",
                                                                                        }}
                                                                                    >
                                                                                        Crypto
                                                                                    </button>
                                                                                </StyledTableCell>
                                
                                                                                <StyledTableCell
                                                                                    style={{
                                                                                        border: 0,
                                                                                        borderTop: "1px solid #1877F2",
                                                                                        borderBottom: "1px solid #1877F2",
                                                                                        borderRight: "1px solid #1877F2",
                                                                                        padding: 0,
                                                                                        width: "12%",
                                                                                    }}
                                                                                >
                                                                                    <button
                                                                                        disabled
                                                                                        style={{
                                                                                            textAlign: "center",
                                                                                            color: "#1877F2",
                                                                                            fontSize: "16px",
                                                                                            width: "100%",
                                                                                            height: "100%",
                                                                                            background: "var(--Grad, linear-gradient(180deg, rgba(24, 119, 242, 0.40) -1.89%, rgba(24, 119, 242, 0.00) 60.38%))",                                                                                            padding: "19px 12px 5px 12px",
                                                                                            fontWeight: "900",
                                                                                            border: "none",
                                                                                        }}
                                                                                    >
                                                                                        7 Days
                                                                                    </button>
                                                                                </StyledTableCell>
                                
                                                                                <StyledTableCell
                                                                                    style={{
                                                                                        border: 0,
                                                                                        borderTop: "1px solid #1877F2",
                                                                                        borderBottom: "1px solid #1877F2",
                                                                                        borderRight: "1px solid #1877F2",
                                                                                        padding: 0,
                                                                                        width: "12%",
                                                                                    }}
                                                                                >
                                                                                    <button
                                                                                        disabled
                                                                                        style={{
                                                                                            textAlign: "center",
                                                                                            color: "#1877F2",
                                                                                            fontSize: "16px",
                                                                                            width: "100%",
                                                                                            height: "100%",
                                                                                            background: "var(--Grad, linear-gradient(180deg, rgba(24, 119, 242, 0.40) -1.89%, rgba(24, 119, 242, 0.00) 60.38%))",                                                                                            padding: "19px 12px 5px 12px",
                                                                                            fontWeight: "900",
                                                                                            border: "none",
                                                                                        }}
                                                                                    >
                                                                                        30 Days
                                                                                    </button>
                                                                                </StyledTableCell>
                                
                                                                                <StyledTableCell
                                                                                    style={{
                                                                                        border: 0,
                                                                                        borderTop: "1px solid #1877F2",
                                                                                        borderBottom: "1px solid #1877F2",
                                                                                        borderRight: "1px solid #1877F2",
                                                                                        padding: 0,
                                                                                        width: "14%",
                                                                                    }}
                                                                                >
                                                                                    <button
                                                                                        disabled
                                                                                        style={{
                                                                                            textAlign: "center",
                                                                                            color: "#1877F2",
                                                                                            fontSize: "16px",
                                                                                            width: "100%",
                                                                                            height: "100%",
                                                                                            background: "var(--Grad, linear-gradient(180deg, rgba(24, 119, 242, 0.40) -1.89%, rgba(24, 119, 242, 0.00) 60.38%))",                                                                                            padding: "19px 12px 5px 12px",
                                                                                            fontWeight: "900",
                                                                                            border: "none",
                                                                                        }}
                                                                                    >
                                                                                        12 Months
                                                                                    </button>
                                                                                </StyledTableCell>
                                
                                                                                
                                
                                                                                <StyledTableCell
                                                                                    style={{
                                                                                        border: 0,
                                                                                        borderTop: "1px solid #1877F2",
                                                                                        borderBottom: "1px solid #1877F2",
                                                                                        borderRight: "1px solid #DADEDF",
                                                                                        padding: 0,
                                                                                        //width: "auto",
                                                                                    }}
                                                                                >
                                                                                    <button
                                                                                        disabled
                                                                                        style={{
                                                                                            textAlign: "center",
                                                                                            color: "#1877F2",
                                                                                            fontSize: "16px",
                                                                                            width: "100%",
                                                                                            height: "100%",
                                                                                            background: "var(--Grad, linear-gradient(180deg, rgba(24, 119, 242, 0.40) -1.89%, rgba(24, 119, 242, 0.00) 60.38%))",
                                                                                            padding: "19px 12px 5px 12px",
                                                                                            fontWeight: "900",
                                                                                            border: "none",
                                                                                        }}
                                                                                    >
                                                                                        Market Cap
                                                                                    </button>
                                                                                </StyledTableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                
                                                                        <TableBody>
                                                                            {loader === false ? (
                                                                                <StyledTableRow>
                                                                                    {[...Array(5)].map((_, index) => (
                                                                                        <StyledTableCell key={index}>
                                                                                            <Stack>
                                                                                                <Skeleton
                                                                                                    sx={{marginTop: "10px"}}
                                                                                                    variant="rounded"
                                                                                                    height={40}
                                                                                                />
                                                                                            </Stack>
                                                                                        </StyledTableCell>
                                                                                    ))}
                                                                                </StyledTableRow>
                                                                            ) : (
                                                                                <StyledTableRow style={{backgroundColor: "white"}}>
                                                                                    <StyledTableCell
                                                                                        component="th"
                                                                                        scope="row"
                                                                                        style={{
                                                                                            borderBottom: "none",
                                                                                            color: "#000",
                                                                                            fontSize: "16px",
                                                                                            fontWeight: "500",
                                                                                            paddingLeft: "16px",
                                                                                            paddingTop: "8px",
                                                                                            paddingBottom: "8px",
                                                                                            borderRight: "1px solid #1877F2",
                                                                                            width: "auto",
                                                                                            height: "5vw",
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "space-between",
                                                                                                alignItems: "center",
                                                                                            }}
                                                                                        >
                                                                                            {/* Left section with logo and name */}
                                                                                            <div style={{display: "flex", alignItems: "center"}}>
                                                                                                <img
                                                                                                    width={35}
                                                                                                    height={35}
                                                                                                    style={{
                                                                                                        borderRadius: "19px",
                                                                                                        border: "2px solid black",
                                                                                                        marginLeft: "-8px",
                                                                                                    }}
                                                                                                    alt="symbolLogo"
                                                                                                    src={symbolLogo}
                                                                                                />
                                                                                                <span style={{marginLeft: "8px"}}>{slug}</span>
                                                                                            </div>
                                
                                                                                            {/* Middle section with price */}
                                                                                            <div style={{textAlign: "center", fontWeight: "500"}}>
                                                                                                ${currentPrice}
                                                                                            </div>
                                
                                                                                            {/* Right section with percentage */}
                                                                                            <div
                                                                                                style={{
                                                                                                    textAlign: "right",
                                                                                                    color: parseFloat(tfhrs) > 0 ? "green" : "red",
                                                                                                }}
                                                                                            >
                                                                                                {parseFloat(tfhrs) > 0 ? `+${tfhrs}` : tfhrs}
                                                                                            </div>
                                                                                        </div>
                                                                                    </StyledTableCell>
                                
                                                                                    <StyledTableCell
                                                                                        style={{
                                                                                            borderBottom: "none",
                                                                                            color: "#000",
                                                                                            fontSize: "16px",
                                                                                            fontWeight: "500",
                                                                                            textAlign: "center",
                                                                                            paddingTop: "8px",
                                                                                            paddingBottom: "8px",
                                                                                            borderRight: "1px solid #1877F2",
                                                                                            width: "18%",
                                                                                            height: "5vw",
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                color: parseFloat(sevenDays) > 0 ? "green" : "red",
                                                                                            }}
                                                                                        >
                                                                                            {parseFloat(sevenDays) > 0 ? `+${sevenDays}` : sevenDays}
                                                                                        </span>
                                                                                    </StyledTableCell>
                                
                                                                                    <StyledTableCell
                                                                                        style={{
                                                                                            borderBottom: "none",
                                                                                            color: "#000",
                                                                                            fontSize: "16px",
                                                                                            fontWeight: "500",
                                                                                            textAlign: "center",
                                                                                            paddingTop: "8px",
                                                                                            paddingBottom: "8px",
                                                                                            borderRight: "1px solid #1877F2",
                                                                                            width: "18%",
                                                                                            height: "5vw",
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                color: parseFloat(thirtyDays) > 0 ? "green" : "red",
                                                                                            }}
                                                                                        >
                                                                                            {parseFloat(thirtyDays) > 0 ? `+${thirtyDays}` : thirtyDays}
                                                                                        </span>
                                                                                    </StyledTableCell>
                                
                                                                                    <StyledTableCell
                                                                                        style={{
                                                                                            borderBottom: "none",
                                                                                            color: "#000",
                                                                                            fontSize: "16px",
                                                                                            fontWeight: "500",
                                                                                            textAlign: "center",
                                                                                            paddingTop: "8px",
                                                                                            paddingBottom: "8px",
                                                                                            borderRight: "1px solid #1877F2",
                                                                                            width: "18%",
                                                                                            height: "5vw",
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                color:
                                                                                                    yearDays == "NA"
                                                                                                        ? "black"
                                                                                                        : parseFloat(yearDays) > 0
                                                                                                        ? "green"
                                                                                                        : "red",
                                                                                            }}
                                                                                        >
                                                                                            {parseFloat(yearDays) > 0 ? `+${yearDays}` : yearDays}
                                                                                        </span>
                                                                                    </StyledTableCell>
                                
                                                                                   
                                
                                                                                    <StyledTableCell
                                                                                        style={{
                                                                                            borderBottom: "none",
                                                                                            color: "#000",
                                                                                            fontSize: "16px",
                                                                                            fontWeight: "500",
                                                                                            textAlign: "center",
                                                                                            paddingTop: "8px",
                                                                                            paddingBottom: "8px",
                                                                                            width: "18%",
                                                                                            height: "5vw",
                                                                                            borderRight: "1px solid #DADEDF",
                                                                                        }}
                                                                                    >
                                                                                        {marketCap}
                                                                                    </StyledTableCell>
                                                                                </StyledTableRow>
                                                                            )}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                                <div
                                    style={{
                                        borderRadius: "5px",
                                        border: "1px solid #DADEDF",
                                        padding: "10px",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginLeft: "5px",
                                        marginRight: "5px",
                                        marginBottom: "5px",
                                        height: "120px",
                                    }}
                                >
                                    <button
                                        onClick={() => navigate(`/crypto/${crypto}/portfolios`)}
                                        style={{
                                            textAlign: "center",
                                            color: "#727376",
                                            fontSize: "16px",
                                            width: "141px",
                                            height: "46px",
                                            fontSize: "14px",
                                            color: "white",
                                            backgroundColor: "#1877F2",
                                            padding: "4px 4px",
                                            borderRadius: "10px",
                                            border: "none",
                                        }}
                                    >
                                        Best Portfolios
                                        <img
                                            src={window.constants.asset_path + "/images/open_in_new_tab.svg"}
                                            style={{marginLeft: "5px"}}
                                            alt="open_in_new_tab"
                                            width={20} height={20} 
                                        ></img>
                                    </button>
                                    <div
                                        style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            textAlign: "center",
                                            padding: "11px 5px",
                                        }}
                                        onClick={() => navigate(`/crypto/${crypto}/portfolios`)}
                                    >
                                        <span
                                            style={{
                                                color: "black",
                                            }}
                                        >
                                            {holdings}
                                        </span>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                        {loader === true && (category || hashtags2.length > 0) && ( <div
  style={{
    display: "inline-flex",  // Allows it to shrink and expand
    alignItems: "center",
    border: "1px solid #1877F2",
    padding: "7px 10px",
    borderRadius: "12px",
    maxWidth: "fit-content", // Adjusts to content width
    boxSizing: "border-box",
    backgroundColor: "#FFF",
    flexWrap: "nowrap",
    gap: "25px", // Ensures space between elements
    marginLeft:"5px",
    gap: "20px",
    marginTop:"5%"
  }}
>
  {/* Category Label */}
  <span
    style={{
      fontWeight: "700",
      fontSize: "14px",
      whiteSpace: "nowrap",
    }}
  >
    Category
  </span>

  {/* Category Name */}
  <div
  style={{
    display: "flex",
    alignItems: "center",
    gap: "8px",
    background: "linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%)",
    padding: "4px 10px",
    borderRadius: "12px",
    fontWeight: "700",
    fontSize: "18px",
    whiteSpace: "nowrap",
    color: "#000",
    textAlign: "center",
  }}
>
  <span>{category}</span>
  <img 
    src={window.constants.asset_path + "/images/new-link-icon.svg"} 
    alt="icon" 
    style={{ width: "16px", height: "16px", marginLeft:"20px", cursor: "pointer" }} 
    onClick={() => {
        window.open(`/crypto-screener/${category.toLowerCase().replace(/\s+/g, "-")}`, "_blank");
      }}
      
  />
</div>


  {/* Hashtags Container */}
  {/* <div
    style={{
      display: "flex",
      flexWrap: "wrap", // Allows proper wrapping
      gap: "4px",
      minWidth: "fit-content", // Expands with content
    }}
  >
    {hashtags2.map((tag, index) => (
      <div
        key={index}
        style={{
          border: "1px solid #1877f2",
          fontSize: "14px",
          fontWeight: "500",
          borderRadius: "6px",
          color: "#1877f2",
          padding: "3px 9px",
          backgroundColor: "transparent",
          cursor: "pointer",
          whiteSpace: "nowrap",
          minWidth: "fit-content", // Ensures hashtags don't wrap unless needed
        }}
        onClick={() => `window.open(/${tag.replace("#", "")}, "_blank")`}
      >
        {tag}
      </div>
    ))}
  </div> */}
</div>


                        )}
            {loader === true && (risk !== null || top100Holders !== null) && (
  <div
    style={{
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      margin: "20px 0 0px 5px",
      gap:"4%"
    }}
  >
    {/* Risk & Top 100 Holders Section */}
    <div
      style={{
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #1877F2",
        padding: "9px 48px 9px 9px",
        borderRadius: "12px",
        flexWrap: "wrap",
        gap: "30px",
        width: "fit-content",
        marginTop:"0.8%"
      }}
    >
      {/* Display Risk */}
      <div
        style={{
          border: "1px solid #1877f2",
          padding: "6px 14px",
          borderRadius: "48px",
          fontSize: "16px",
          fontWeight: "700",
          color: "#000",
          textAlign: "center",
          background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
          cursor: "default",
          whiteSpace: "nowrap",
        }}
      >
        <span style={{ fontSize: "18px" }}>Risk: </span>
        <span style={{ fontSize: "16px", marginLeft: "5px" }}>
          {renderAction(risk)}
        </span>
      </div>

      {/* Display Top 100 Holders */}
      <div
        style={{
          border: "1px solid #1877f2",
          padding: "6px 14px",
          borderRadius: "48px",
          fontSize: "16px",
          fontWeight: "700",
          color: "#000",
          textAlign: "center",
          background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
          cursor: "default",
          whiteSpace: "nowrap",
        }}
      >
        <span style={{ fontSize: "18px" }}>Top 100 Holders: </span>
        <span style={{ fontSize: "16px", marginLeft: "5px" }}>
          {top100Holders}
        </span>
      </div>
    </div>

    {/* Button Section (Right Aligned) */}
    <button
      style={{
        backgroundColor: "#1877F2",
        color: "white",
        border: "none",
        padding: "10px 12px",
        fontSize: "14px",
        fontWeight: "700",
        borderRadius: "6px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        whiteSpace: "nowrap",
        marginRight:"6%",
        width:"35%",
        marginTop:"1%"
      }}
      onClick={() => {
        window.open(`/crypto/${crypto}/trends`, "_blank");
        window.scrollTo({ top: 0, behavior: "smooth" });
      }}
      
    >
      Explore Risk And Influencer Sentiments
      <span
        style={{
          marginLeft: "5px",
          display: "inline-flex",
        }}
      >
        <img
          src={window.constants.asset_path + "/images/new-white-link.svg"}
          style={{ marginLeft: "15px" }}
          width="16"
          height="16"
        />
      </span>
    </button>
  </div>
)}

                        {evaluation_data && (
                            <div
                                style={{
                                    borderRadius: "10px",
                                    padding: "10px 0px 0px 0px",
                                    fontFamily: "Satoshi, sans-serif",
                                    margin: "5% 5px 20px 5px",
                                }}
                            >
                                <button
                                    style={{
                                        fontSize: "20px",
                                        fontWeight: "700",
                                        border: "1px solid var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                        borderRadius: "12px",
                                        background:
                                            "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                        padding: "4px 10px",
                                        marginLeft: "5px",
                                        marginBottom: "8px",
                                        color: "black",
                                    }}
                                >
                                    Outlook Summary
                                </button>

                                <TableContainer
    style={{
        width: "100%",
        overflowX: "hidden",
        padding: 0,
        borderRadius:"8px",
        border:"1px solid #ECECEC"
    }}
>
    <Table>
        <TableHead>
            <TableRow>
                {["Price Target 2025", "Accuracy", "Recommendation", ""].map(
                    (label, index) => (
                        <StyledTableCell
                            key={index}
                            className="cryptotableviewHeader"
                            style={{
                                padding: 0,
                                whiteSpace: "normal",
                                wordWrap: "break-word",
                                border: "none",
                                borderTop: "1px solid #ECECEC",
                                borderBottom: "1px solid #1877F2",
                                borderRight: index !== 3 ? "1px solid #DADEDF" : "none",
                                height: "40px",
                                textAlign: "center",
                                width: index === 3 ? "auto" : "140px", // Fixed width for first 3 columns
                            }}
                        >
                            <button
                                disabled
                                style={{
                                    color: "#1877F2",
                                    fontSize: "16px",
                                    width: "100%",
                                    height: "100%",
                                    borderBottom: "none",
                                    background: "rgba(24, 119, 242, 0.12)",
                                    padding: "19px 12px 5px 12px",
                                    fontWeight: "900",
                                    border: "none",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {label}
                            </button>
                        </StyledTableCell>
                    )
                )}
            </TableRow>
        </TableHead>

        <TableBody>
            {loader === false ? (
                <StyledTableRow>
                    {[...Array(4)].map((_, index) => (
                        <StyledTableCell key={index}>
                            <Stack>
                                <Skeleton
                                    sx={{ marginTop: "8px" }}
                                    variant="rounded"
                                    height={40}
                                />
                            </Stack>
                        </StyledTableCell>
                    ))}
                </StyledTableRow>
            ) : (
                <StyledTableRow style={{ backgroundColor: "white" }}>
                    {[
                        outlookData.target,
                        outlookData.accuracy,
                        outlookData.recommendation,
                    ].map((data, index) => (
                        <StyledTableCell
                            key={index}
                            style={{
                                borderBottom: "none",
                                color: "#000",
                                fontSize: "16px",
                                fontWeight: "500",
                                textAlign: "center",
                                paddingTop: "12px",
                                paddingBottom: "12px",
                                borderRight: index !== 3 ? "1px solid #DADEDF" : "none",
                                width: "18%", // Fixed width
                                height: "5vw",
                            }}
                        >
                            {data === outlookData.recommendation
                                ? renderAction(data)
                                : data || "-"}
                        </StyledTableCell>
                    ))}

                    {/* Blue Button Column (Takes Remaining Space) */}
                    <StyledTableCell
                        style={{
                            borderBottom: "none",
                            textAlign: "center",
                            width: "auto", // Auto width to take remaining space
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <button
                            style={{
                                backgroundColor: "#1877F2",
                                color: "white",
                                border: "none",
                                padding: "10px 16px",
                                fontSize: "14px",
                                fontWeight: "700",
                                borderRadius: "6px",
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "80%", // Full width
                            }}
                            onClick={() => {
                                window.open(`/crypto/${crypto}/predictions`, "_blank");
                              }}
                              
                        >
                            Explore Past Price Targets And Accuracy
                            <span
                                style={{
                                    marginLeft: "5px",
                                    display: "inline-flex",
                                }}
                            >
                                <img src={window.constants.asset_path + "/images/new-white-link.svg"} style={{marginLeft:"15px"}}
                                      width="16"
                                      height="16" />
                            </span>
                        </button>
                    </StyledTableCell>
                </StyledTableRow>
            )}
        </TableBody>
    </Table>
</TableContainer>

                            </div>
                        )}

                        {evaluation_data && (
                            <div
                                style={{
                                    borderRadius: "8px",
                                    backgroundColor: "#fff",
                                    padding: "10px 0px 5px 0px",
                                    fontFamily: "Satoshi, sans-serif",
                                    margin: "45px 5px 20px 5px",
                                    border:"1.5px solid #1877F2"
                                }}
                            >
                                <button
                                    style={{
                                        fontSize: "20px",
                                        fontWeight: "700",
                                        border: "1px solid var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                        borderRadius: "12px",
                                        background:
                                            "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                        padding: "4px 10px",
                                        marginLeft: "5px",
                                        marginBottom: "20px",
                                        color: "black",
                                    }}
                                >
                                    Technical Signals
                                </button>

                                <TableContainer
                                    style={{
                                        width: "100%",
                                        overflowX: "hidden",
                                        padding: 0,
                                    }}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {[
                                                    "RSI",
                                                    "Momentum",
                                                    "MACD",
                                                    "OBV",
                                                    "Bollinger Bands",
                                                    "Head and Shoulders",
                                                    "Triangles",
                                                    "Fibonacci Retracement",
                                                ].map((label, index, array) => (
                                                    <StyledTableCell
                                                        key={index}
                                                        className="cryptotableviewHeader"
                                                        style={{
                                                            padding: 0,
                                                            whiteSpace: "normal", // Allow text to wrap
                                                            wordWrap: "break-word", // Ensure longer words break properly
                                                            border: "none", // Remove all default borders
                                                            borderTop: "1px solid #1877F2", // Top border
                                                            borderBottom: "1px solid #1877F2", // Bottom border
                                                            borderRight:
                                                                index !== array.length - 1
                                                                    ? "1px solid #1877F2"
                                                                    : "none", // Right border for all but last column
                                                            height: "40px", // Increased height for the header
                                                            textAlign: "center", // Centering the text
                                                            width: "auto%", // Ensure each header cell takes equal width (100% / 8 columns)
                                                        }}
                                                    >
                                                        <button
                                                            disabled
                                                            style={{
                                                                color: "#1877F2",
                                                                fontSize: "16px",
                                                                width: "100%",
                                                                height: "100%",
                                                                borderBottom: "none",
                                                                background: "var(--Grad, linear-gradient(180deg, rgba(24, 119, 242, 0.40) -1.89%, rgba(24, 119, 242, 0.00) 60.38%))",
                                                                padding: "19px 12px 5px 12px",
                                                                fontWeight: "900",
                                                                border: "none", // Remove borders from the button
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            {label}
                                                        </button>
                                                    </StyledTableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {loader === false ? (
                                                <StyledTableRow>
                                                    {[...Array(7)].map((_, index) => (
                                                        <StyledTableCell key={index}>
                                                            <Stack>
                                                                <Skeleton
                                                                    sx={{marginTop: "8px"}}
                                                                    variant="rounded"
                                                                    height={40}
                                                                />
                                                            </Stack>
                                                        </StyledTableCell>
                                                    ))}
                                                </StyledTableRow>
                                            ) : (
                                                <StyledTableRow style={{backgroundColor: "white"}}>
                                                    {[
                                                        evaluation_data.rsi_evaluation,
                                                        evaluation_data.mom_oscillator_evaluation,
                                                        evaluation_data.macd_evaluation,
                                                        evaluation_data.obv_evaluation,
                                                        evaluation_data.bbands_evaluation,
                                                        evaluation_data.head_shoulders,
                                                        evaluation_data.triangles,
                                                        evaluation_data.fibonacci_evaluation,
                                                    ].map((data, index, array) => (
                                                        <StyledTableCell
                                                            key={index}
                                                            style={{
                                                                borderBottom: "none",
                                                                color: "#000",
                                                                fontSize: "16px",
                                                                fontWeight: "500",
                                                                textAlign: "center",
                                                                paddingTop: "12px",
                                                                paddingBottom: "12px",
                                                                borderRight:
                                                                    index !== array.length - 1
                                                                        ? "1px solid #1877F2"
                                                                        : "none", // No borderRight for the last column
                                                                width: "auto", // Ensure each column in the body has equal width
                                                                height: "5vw",
                                                            }}
                                                        >
                                                            {renderAction(data) || "-"}
                                                        </StyledTableCell>
                                                    ))}
                                                </StyledTableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {/* <TableContainer
            style={{
              width: "100%",
              overflowX: "hidden",
              border: "1px solid #DADEDF",
              borderRadius: "5px",
              padding: 0,
              marginTop: "20px"
            }}
          >
            <Table className="non_responsiveTable" aria-label="customized table" style={{ borderCollapse: "collapse" }}>
  <TableHead>
    <TableRow>
      {[ 'SMA (10)', 
        'SMA (50)', 'SMA (100)', 'SMA (200)'].map((label, index) => (
        <StyledTableCell
          key={index}
          className="cryptotableviewHeader"
          style={{
            padding: 0,
            whiteSpace: "normal", // Allow text to wrap
            wordWrap: "break-word", // Ensure long words break
            border: 0,
            textAlign: "center", // Center text in the header
            width: "12.5%", // Ensures equal width for all columns (100% / 8 columns)
          }}
        >
          <button
            disabled
            style={{
              color: "#727376",
              fontSize: "16px",
              width: "100%",
              height: "100%",
              borderBottom: "none",
              background: "#D3E6FD",
              padding: "12px 12px",
              borderRight: "1px solid #DADEDF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {label}
          </button>
        </StyledTableCell>
      ))}
    </TableRow>
  </TableHead>
  <TableBody>
    {loader === false ? (
      <StyledTableRow>
        {[...Array(8)].map((_, index) => (
          <StyledTableCell key={index}>
            <Stack>
              <Skeleton sx={{ marginTop: '8px' }} variant="rounded" height={40} />
            </Stack>
          </StyledTableCell>
        ))}
      </StyledTableRow>
    ) : (
      <StyledTableRow style={{ backgroundColor: "white" }}>
        {[
         
          evaluation_data.sma_10_evaluation,
          evaluation_data.sma_50_evaluation,
          evaluation_data.sma_100_evaluation,
          evaluation_data.sma_200_evaluation,
        ].map((data, index) => (
          <StyledTableCell
            key={index}
            style={{
              borderBottom: "none",
              color: "#000",
              fontSize: "16px",
              fontWeight: "500",
              textAlign: "center",
              paddingTop: "12px",
              paddingBottom: "12px",
              borderRight: "1px solid #DADEDF",
              width: "12.5%", // Ensures equal width for all columns in the body
            }}
          >
            {renderAction(data) || '-'}
          </StyledTableCell>
        ))}
      </StyledTableRow>
    )}
  </TableBody>
</Table>

          </TableContainer>   */}
                            </div>
                        )}
                        {loader == true && (
  <div style={{ display: "flex", justifyContent: "center", gap: "2%" }}>
    <button
      onClick={() => {
        window.open(`/crypto/${crypto}/trends`, "_blank");
        window.scrollTo({ top: 0, behavior: "smooth" });
      }}
      style={{
        fontSize: "20px",
        backgroundColor: "#1877F2",
        color: "#fff",
        border: "2px solid #1877F2",
        borderRadius: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "12px",
        cursor: "pointer",
        fontWeight: "700",
        padding: "10px 20px",
        marginTop: "1%",
        minWidth: "45%",
        flexWrap: "nowrap",
        whiteSpace: "nowrap", // Ensure no wrapping
      }}
    >
      <span style={{ display: "flex", alignItems: "center", gap: "8px", whiteSpace: "nowrap" }}>
        <span
          style={{
            width: "21px",
            height: "20px",
            backgroundImage: `url(${window.constants.asset_path}/images/down-graph.svg)`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            display: "inline-block",
          }}
        ></span>

        <span style={{ display: "flex", alignItems: "center", gap: "6px", whiteSpace: "nowrap" }}>
          Explore RSI Charts
          <img
            src={window.constants.asset_path + "/images/new-white-link.svg"}
            width="20"
            height="20"
            style={{ marginLeft: "40px", verticalAlign: "middle" }}
          />
        </span>
      </span>
    </button>
  </div>
)}


                        {loader === true && sortedSlugdata.length>0 && (
                        <div
      style={{
        backgroundColor: "#1877F2", // Blue color
        color: "white",
        fontSize: "22px",
        fontWeight: "400",
        padding: "10px 12px",
        width: "99%",
        marginLeft:"5px",
        height:"50.461px",
        color:"#fff",
        marginBottom:"1.5%",
      }}
    >
      Trending Cryptos
    </div>
    )}
{loader === true && sortedSlugdata.length>0 && (
<Grid container spacing={0} sx={{ 
  
  flexWrap: "nowrap", 
  overflowX: "auto",
  marginBottom:"0%" ,
  width: "77.5vw",
  scrollbarWidth: "none",  // For Firefox
  "&::-webkit-scrollbar": { display: "none" }
}}>
            {sortedSlugdata.map((obj, idx) => (
                <Grid item key={idx} sx={{ flex: "0 0 auto" }}>
  <div
    key={idx}
    style={{
      marginLeft: "5px",
      borderRadius: "10px",
      backgroundColor: "white",
      border: "1px #ECECEC solid",
      padding: "20px",
      marginBottom: "20px",
      marginRight: "10px",
    }}
  >
    {/* First column data */}
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between", // Ensures even spacing between sections
          gap: "30px", // Adds space between left, middle, and right
          fontSize: mediaQueryVar === false ? "13px" : "",
        }}
      >
        {/* Left: Logo & Name (No space change between logo & name) */}
        <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
          <img
            className="b57ac673f06a4b0338a596817eb0a5Icon"
            alt="logo"
            src={obj.logo}
            style={{
              width: "38px",
              height: "38px",
              border: "2px solid black",
              borderRadius: "20px",
              marginRight: "8px",
              marginBottom: "6px",
            }}
          />

          {/* Name or Link */}
          <div>
            {obj["article_link"] ? (
              <a
                href={obj.article_link}
                style={{ color: "black", textDecoration: "underline" }}
              >
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    fontFamily: "Satoshi, sans-serif",
                    color: "black",
                    marginBottom: "6px",
                  }}
                >
                  {obj.slug}
                </div>
              </a>
            ) : (
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  fontFamily: "Satoshi, sans-serif",
                  color: "black",
                  marginBottom: "6px",
                  textDecoration: "underline",
                }}
              >
                {obj.slug}
              </div>
            )}
          </div>
        </div>

        {/* Center: Recommendation Status */}
        <div
          style={{
            flex: 1,
            textAlign: "center", // Ensures text is centered
            fontWeight: "500",
            color:
              obj.recommend === "Hold"
                ? "orange"
                : obj.recommend === "Buy"
                ? "green"
                : obj.recommend === "Sell"
                ? "red"
                : "black",
          }}
        >
          {obj.recommend}
        </div>

        {/* Right: Add to Portfolio Button */}
        <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
          <Button
            className="btn"
            variant="outlined"
            onClick={() => handleAddToPortfolio(obj.slug)}
            style={{
              backgroundColor: "white",
              padding: "4px 10px",
              fontFamily: "Satoshi, sans-serif",
              borderRadius: "48px",
              border: "1px solid #1877F2",
              textTransform: "none",
              fontSize: mediaQueryVar === false ? "10px" : "11px",
              fontWeight: "500",
              color: "#1877F2",
              whiteSpace: "nowrap",
              width: "120px",
              height: "30px",
              marginBottom: "2px",
            }}
          >
            Add to Portfolio
          </Button>
        </div>
      </div>
    </div>
  </div>
</Grid>

            ))}
          </Grid>
          
                        
                        
        )}
        {evaluation_data && sortedSlugdata.length>0 && (
            <div
            style={{
              backgroundColor: "#1877F2", // Blue color
              color: "white",
              fontSize: "22px",
              fontWeight: "400",
              padding: "10px 12px",
              width: "99%",
              marginLeft:"5px",
              height:"25.230px",
              color:"#fff",
              marginBottom:"1.5%",
            }}
          >
            
          </div>

        )}
                        {analysis_data && (
                            <div
                                style={{
                                    borderRadius: "10px",
                                    backgroundColor: "#fff",
                                    padding: "10px 0px 5px 0px",
                                    fontFamily: "Satoshi, sans-serif",
                                    margin: "2.8% 5px 2px 5px",
                                    border:"1.5px solid #1877F2"
                                }}
                            >
                                <button
                                    style={{
                                        fontSize: "20px",
                                        fontWeight: "700",
                                        border: "1px solid var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                        borderRadius: "12px",
                                        background:
                                            "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                        padding: "4px 10px",
                                        marginLeft: "5px",
                                        marginBottom: "20px",
                                        color: "black",
                                    }}
                                >
                                    Influencer Signals
                                    {paid_member === "no" && (
                                        <img
                                            src={window.constants.asset_path + "/images/lock-new.svg"}
                                            width="24"
                                            height="24"
                                            style={{marginLeft: "4px"}}
                                            alt="lock"
                                        />
                                    )}
                                </button>

                                {paid_member === "yes" ? (
                                    <TableContainer
                                        style={{
                                            width: "100%",
                                            overflowX: "hidden",

                                            padding: 0,
                                        }}
                                    >
                                        <Table style={{borderCollapse: "collapse"}}>
                                            <TableHead>
                                                <TableRow>
                                                    {[
                                                        "All Influencers",
                                                        "Top 10% Influencers",
                                                        "Institutions",
                                                        "Whales",
                                                        "Individuals",
                                                        "Market Trend",
                                                        "Grade",
                                                    ].map((label, index, array) => (
                                                        <StyledTableCell
                                                            key={index}
                                                            className="cryptotableviewHeader"
                                                            style={{
                                                                padding: 0,
                                                                whiteSpace: "nowrap", // Prevent word wrapping
                                                                wordWrap: "normal", // Ensure words don't break
                                                                border: "none", // Remove all default borders
                                                                borderTop: "1px solid #1877F2", // Top border
                                                                borderBottom: "1px solid #1877F2", // Bottom border
                                                                borderRight:
                                                                    index !== array.length - 1
                                                                        ? "1px solid #1877F2"
                                                                        : "none", // Right border for all but last column
                                                                height: "40px", // Increased height for the header
                                                                textAlign: "center", // Centering the text
                                                                paddingLeft: "12px", // Added padding for better readability,
                                                                background: "var(--Grad, linear-gradient(180deg, rgba(24, 119, 242, 0.40) -1.89%, rgba(24, 119, 242, 0.00) 60.38%))",
                                                                paddingRight: "12px", // Added padding for better readability
                                                            }}
                                                        >
                                                            <button
                                                                disabled
                                                                style={{
                                                                    color: "#1877F2",
                                                                    fontSize: "16px",
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    borderBottom: "none",
                                                                    background: "none",
                                                                    padding: "19px 12px 5px 12px",
                                                                    fontWeight: "900",
                                                                    border: "none", // Remove borders from the button
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                {label}
                                                            </button>
                                                        </StyledTableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {loader === false ? (
                                                    <StyledTableRow>
                                                        {[...Array(4)].map((_, index) => (
                                                            <StyledTableCell key={index}>
                                                                <Stack>
                                                                    <Skeleton
                                                                        sx={{marginTop: "8px"}}
                                                                        variant="rounded"
                                                                        height={40}
                                                                    />
                                                                </Stack>
                                                            </StyledTableCell>
                                                        ))}
                                                    </StyledTableRow>
                                                ) : (
                                                    <StyledTableRow style={{backgroundColor: "white"}}>
                                                        {[
                                                            analysis_data.hashtags,
                                                            analysis_data.top_10p_influencers,
                                                            analysis_data.indices,
                                                            analysis_data.whales,
                                                            analysis_data.social_media,
                                                            analysis_data.market_trend,
                                                            analysis_data.grade,
                                                        ].map((data, index, array) => (
                                                            <StyledTableCell
                                                                key={index}
                                                                style={{
                                                                    borderBottom: "none",
                                                                    color: "#000",
                                                                    fontSize: "16px",
                                                                    fontWeight: "500",
                                                                    textAlign: "center",
                                                                    paddingTop: "12px",
                                                                    paddingBottom: "12px",
                                                                    borderRight:
                                                                        index !== array.length - 1
                                                                            ? "1px solid #1877F2"
                                                                            : "none",
                                                                    height: "5vw",
                                                                }}
                                                            >
                                                                {renderAction(data) || "-"}
                                                            </StyledTableCell>
                                                        ))}
                                                    </StyledTableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ) : (
                                    <div>
                                        <TableContainer
                                            style={{
                                                width: "100%",
                                                overflowX: "hidden",
                                                border: "1px solid #DADEDF",
                                                borderRadius: "0px",
                                                padding: 0,
                                            }}
                                        >
                                            <Table
                                                className="non_responsiveTable"
                                                aria-label="customized table"
                                                style={{borderCollapse: "collapse"}}
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        {[
                                                            "All Influencers",
                                                            "Top 10% Influencers",
                                                            "Institutions",
                                                            "Whales",
                                                            "Individuals",
                                                            "Grade",
                                                        ].map((label, index, array) => (
                                                            <StyledTableCell
                                                                key={index}
                                                                className="cryptotableviewHeader"
                                                                style={{
                                                                    padding: 0,
                                                                    whiteSpace: "nowrap", // Prevent word wrapping
                                                                    wordWrap: "normal", // Ensure words don't break
                                                                    border: "none", // Remove all default borders
                                                                    borderTop: "1px solid #1877F2", // Top border
                                                                    borderBottom: "1px solid #1877F2", // Bottom border
                                                                    borderRight:
                                                                        index !== array.length - 1
                                                                            ? "1px solid #DADEDF"
                                                                            : "none", // Right border for all but last column
                                                                    height: "40px", // Increased height for the header
                                                                    textAlign: "center", // Centering the text
                                                                    paddingLeft: "12px", // Added padding for better readability,
                                                                    background: "rgba(24, 119, 242, 0.12)",
                                                                    paddingRight: "12px", // Added padding for better readability
                                                                }}
                                                            >
                                                                <button
                                                                    disabled
                                                                    style={{
                                                                        color: "#1877F2",
                                                                        fontSize: "16px",
                                                                        width: "100%",
                                                                        height: "100%",
                                                                        borderBottom: "none",
                                                                        background: "none",
                                                                        padding: "19px 12px 5px 12px",
                                                                        fontWeight: "900",
                                                                        border: "none", // Remove borders from the button
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    {label}
                                                                </button>
                                                            </StyledTableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {loader === false ? (
                                                        <StyledTableRow>
                                                            {[...Array(7)].map((_, index) => (
                                                                <StyledTableCell key={index}>
                                                                    <Stack>
                                                                        <Skeleton
                                                                            sx={{marginTop: "8px"}}
                                                                            variant="rounded"
                                                                            height={40}
                                                                        />
                                                                    </Stack>
                                                                </StyledTableCell>
                                                            ))}
                                                        </StyledTableRow>
                                                    ) : (
                                                        <StyledTableRow style={{backgroundColor: "white"}}>
                                                            <td
                                                                colSpan="100%"
                                                                style={{textAlign: "center", height: "5vw"}}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        marginTop: "10px",
                                                                        marginBottom: "10px",
                                                                    }}
                                                                >
                                                                    <button
                                                                        style={{
                                                                            background:
                                                                                "linear-gradient(90deg, #1877F2 -19.84%, #519DFF 99.98%)",
                                                                            color: "#fff",
                                                                            fontSize: "17px",
                                                                            fontWeight: "700",
                                                                            borderRadius: "25px",
                                                                            padding: "7px 20px 10px 20px",
                                                                            border: "none",
                                                                            cursor: "pointer",
                                                                            width: "auto",
                                                                            height: "40px",
                                                                        }}
                                                                        onClick={handleNavigation}
                                                                    >
                                                                        Premium Signals @ $1 per month
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </StyledTableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                )}
                            </div>
                        )}
                        {loader == true && (
  <div style={{ display: "flex", justifyContent: "center", gap: "2%" }}>
    <button
      onClick={() => {
        window.open(`/crypto-screener`, "_blank");
      }}
      
      style={{
        fontSize: "22px",
        background: "linear-gradient(90deg, #9AFFF9 20%, #FFF06D 100%)",
        color: "#1E88E5",
        border: "2px solid #1E88E5",
        borderRadius: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "12px",
        cursor: "pointer",
        fontWeight: "700",
        padding: "10px 20px",
        marginBottom: "2%",
        marginTop: "3%",
        width: "fit-content",
        minWidth: "45%",
        flexWrap: "nowrap",
      }}
    >
      <span
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
        }}
      >
        <span
          style={{
            width: "21px",
            height: "20px",
            backgroundImage: `url(${window.constants.asset_path}/images/down-graph.svg)`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        ></span>
        <span
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "25px",
            height: "25px",
            backgroundImage: `url(${window.constants.asset_path}/images/lens-new.svg)`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            marginLeft: "4px",
            marginBottom: "7px",
          }}
        ></span>
      </span>
      <span style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        Crypto Screener
        <img
          src={`${window.constants.asset_path}/images/link.svg`}
          width="24"
          height="24"
          alt="link"
        />
      </span>
    </button>
  </div>
)}


                        {loader == true && sortedData.length > 0 && (
                        <div
      style={{
        backgroundColor: "#1877F2", // Blue color
        color: "white",
        fontSize: "22px",
        fontWeight: "400",
        padding: "10px 12px",
        width: "99%",
        marginLeft:"5px",
        height:"50.461px",
        color:"#fff",
        marginBottom:"1.5%",
        marginTop:"5px"
      }}
    >
Price Forecast
    </div>
                        )}
                        {isPredictionDataLoaded === false && predictionLoader ? (
                            <div style={{marginBottom: "2%"}}>
                                <div
                                    style={{
                                        border: "1px solid #B6B6B8",
                                        margin: "0px 2%",
                                        marginTop: "10px",
                                        textAlign: "center",
                                        color: "black",
                                        fontSize: "18px",
                                        backgroundColor: "white",
                                        padding: "2%",
                                        borderRadius: "8px",
                                        marginBottom: "0%",
                                    }}
                                >
                                    No Predictions available.
                                </div>
                                
                            </div>
                        ) : (
                            <div
                                style={{
                                    borderRadius: "10px",
                                    backgroundColor: "#fff",
                                    padding: "15px 15px",
                                    fontFamily: "Satoshi, sans-serif",
                                    margin: "15px 5px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <span style={{fontSize: "20px", fontWeight: "700"}}>Price Forecasts</span>
                                    <div
                                        style={{
                                            width: "25%",
                                            display: "flex",
                                            alignItems: "center",
                                            border: "1px solid #1877F2",
                                            borderRadius: "46px",
                                            padding: "7px 10px",
                                            background: "#F7F7F7",
                                            marginBottom: "12px",
                                        }}
                                    >
                                        <img
                                            src={window.constants.asset_path + "/images/search.svg"}
                                            alt="Search Icon"
                                            style={{
                                                marginLeft: "5px",
                                                marginRight: "10px",
                                                cursor: "pointer",
                                            }}
                                            width={20} height={20} 
                                        />
                                        <input
                                            type="text"
                                            placeholder="Search Forecaster"
                                            style={{
                                                flex: 1, // Makes the input field take up the available space

                                                background: "transparent",
                                                color: "black",
                                                border: "none", // Remove default input border
                                                outline: "none", // Remove focus outline
                                            }}
                                            onChange={handleSearchChange}
                                        />
                                    </div>
                                </div>

                                {/* Prediction Table */}
                                <div>
                                <table
      style={{
        width: "100%",
        borderCollapse: "separate",
        borderSpacing: "0 10px",
      }}
    >
      <thead>
        <tr
          style={{
            background:
              "linear-gradient(180deg, rgba(24, 119, 242, 0.11) -1.89%, rgba(24, 119, 242, 0.00) 60.38%)",
            borderBottom: "5px solid #1877F2",
          }}
        >
                                                <th
                                                    style={{
                                                        padding: "10px",
                                                        textAlign: "left",
                                                        fontWeight: "bold",
                                                        fontSize: "16px",
                                                        color: "#000",
                                                        cursor:"pointer"
                                                      }}
                                                >
                                                    Forecaster {sortOrder.forecaster === "asc" ? "↑" : "↓"}
                                                </th>
                                                <th
                                                    onClick={() => handleSort("target")}
                                                    style={{
                                                        padding: "10px",
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                        fontSize: "16px",
                                                        color: "#000",
                                                        cursor:"pointer"
                                                      }}
                                                >
                                                    Target {sortOrder.target === "asc" ? "↑" : "↓"}
                                                </th>
                                                <th
                                                    onClick={() => handleSort("accuracy")}
                                                    style={{
                                                        padding: "10px",
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                        fontSize: "16px",
                                                        color: "#000",
                                                        cursor:"pointer"
                                                      }}
                                                >
                                                    Past Accuracy {sortOrder.accuracy === "asc" ? "↑" : "↓"}
                                                </th>
                                                <th
                                                    onClick={() => handleSort("percent")}
                                                    style={{
                                                        padding: "10px",
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                        fontSize: "16px",
                                                        color: "#000",
                                                        cursor:"pointer"
                                                      }}
                                                >
                                                    %Target Achieved {sortOrder.percent === "asc" ? "↑" : "↓"}
                                                </th>
                                                <th
                                                    onClick={() => handleSort("targetDate")}
                                                    style={{
                                                        padding: "10px",
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                        fontSize: "16px",
                                                        color: "#000",
                                                        cursor:"pointer"
                                                      }}
                                                >
                                                    Forecast For {sortOrder.targetDate === "asc" ? "↑" : "↓"}
                                                </th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isPredictionDataLoaded === false ?  (
                                                // Render skeletons if data is loading
                                                [...Array(10)].map((_, index) => (
                                                    <tr key={index}>
                                                        {[...Array(6)].map((_, cellIndex) => (
                                                            <td key={cellIndex}>
                                                                <Skeleton variant="rounded" height={40} />
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))
                                            ) : sortedData.length > 0 ? (
                                                // Render data if available
                                                sortedData.slice(0, rowsToShow).map((data, index) => (
                                                    <tr key={index}
                                                    style={{
                                                        background: "#fff",
                                                        borderRadius: "10px",
                                                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                                        marginBottom: "10px",
                                                        border: "2px solid #1877F2",
                                                      }}>
                                                        <td
                                                            style={{
                                                                border: "1px solid #1877F2",
                                                                borderTopLeftRadius: "20px",
                                                                borderBottomLeftRadius: "20px",
                                                                borderRight: "none",
                                                                padding: "10px",
                                                              }}
                                                        >
                                                            <div
                style={{
                  padding: "10px",
                  fontWeight: "bold",
                  fontSize: "16px",
                  color: "#000",
                  background:
                    "linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%)",
                  borderRadius: "10px",
                  textAlign: "left",
                  minWidth: "220px",
                  display:"flex",
                  justifyContent:"space-between"
                }}
              >
                                                            {data.forecaster_name}</div>
                                                        </td>
                                                        <td
                                                            style={{
                                                                padding: "10px",
                                                                textAlign: "center",
                                                                fontWeight: "500",
                                                                fontSize: "14px",
                                                                color: "#000",
                                                                border: "1px solid #1877F2",
                                                                borderLeft: "none",
                                                                borderRight: "none",
                                                                padding: "10px",
                                                              }}
                                                        >
                                                            {data.forecast_price}
                                                        </td>
                                                        <td
                                                            style={{
                                                                padding: "10px",
                                                                textAlign: "center",
                                                                fontWeight: "500",
                                                                fontSize: "14px",
                                                                color: "#000",
                                                                border: "1px solid #1877F2",
                                                                borderLeft: "none",
                                                                borderRight: "none",
                                                                padding: "10px",
                                                              }}
                                                            onClick={() => openModal(data)}
                                                        >
                                                            <span
                                                                className={
                                                                    data.accuracy.toLowerCase() !== "pending"
                                                                        ? "clickable"
                                                                        : "pending"
                                                                }
                                                            >
                                                                {data.accuracy}
                                                            </span>
                                                        </td>
                                                        <td
                                                            style={{
                                                                padding: "10px",
                                                                textAlign: "center",
                                                                fontWeight: "500",
                                                                fontSize: "14px",
                                                                color: "#000",
                                                                border: "1px solid #1877F2",
                                                                borderLeft: "none",
                                                                borderRight: "none",
                                                                padding: "10px",
                                                              }}
                                                        >
                                                            {data.target_percent}
                                                        </td>
                                                        <td
                                                           style={{
                                                            padding: "10px",
                                                            textAlign: "center",
                                                            fontWeight: "500",
                                                            fontSize: "14px",
                                                            color: "#000",
                                                            border: "1px solid #1877F2",
                                                            borderLeft: "none",
                                                            borderRight:"none",
                                                            padding: "10px",
                                                          }}
                                                        >
                                                            {data.target_date}
                                                        </td>
                                                        <td
                                                        style={{
                                                            padding: "10px",
                                                            textAlign: "center",
                                                            fontWeight: "500",
                                                            fontSize: "14px",
                                                            color: "#000",
                                                            border: "1px solid #1877F2",
                                                            borderLeft: "none",
                                                            borderTopRightRadius:"20px",
                                                            borderBottomRightRadius:"20px",
                                                            padding: "10px",
                                                          }}>
                                                            <div className="dropdown">
                                                                <button
                                                                    style={{width: "20px"}}
                                                                    onClick={() => toggleDropdown(index)}
                                                                    className="dropdown-button"
                                                                >
                                                                    <img
                                                                        src={
                                                                            window.constants.asset_path +
                                                                            "/images/more.png"
                                                                        }
                                                                        style={{
                                                                            width: "20px",
                                                                            height: "20px",
                                                                            marginRight: "5px",
                                                                        }}
                                                                        alt="More"
                                                                    />
                                                                </button>
                                                                {dropdownVisible === index && (
                                                                    <div className="dropdown-content">
                                                                        <a
                                                                            href={data.source_link}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    window.constants.asset_path +
                                                                                    "/images/external-link-symbol.png"
                                                                                }
                                                                                style={{
                                                                                    width: "20px",
                                                                                    height: "20px",
                                                                                    marginRight: "5px",
                                                                                }}
                                                                                alt="Copy"
                                                                            />
                                                                            Source link
                                                                        </a>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                // Show "No Predictions available" if no data matches the search
                                                null
                                            )}
                                        </tbody>
                                    </table>
                                    
                                </div>
                            </div>
                        )}
                        {loader == true && (
                            <div style={{display: "flex", justifyContent: "center", gap: "5%"}}>
                            <button
                            onClick={() => {
                                window.open(`/crypto/${crypto}/predictions`, "_blank");
                              }}
                              
                            style={{
                                padding: "6px 12px",
                                fontSize: "19px",
                                background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                color: "#1E88E5", // Blue text
                                border: "2px solid #1E88E5",
                                borderRadius: "6px",
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                                cursor: "pointer",
                                fontWeight: "700",
                                marginTop:"1%",
                                marginBottom:"0.5%"
                            }}
                        >
                            <span
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "28.769px", // Adjust width as needed
                                    height: "31.385px", // Adjust height as needed
                                    backgroundImage: `url(${
                                        window.constants.asset_path + "/images/round-arrow.svg"
                                    })`,
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: "18px", // Adjust size of dollar symbol
                                        color: "#1E88E5", // Match text color
                                        marginTop: "5px",
                                    }}
                                >
                                    $
                                </span>
                            </span>
                            <span
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    textAlign: "center",
                                    margin: "0px 14px",
                                }}
                            >
                                <span style={{marginLeft: "-17px"}}>Price Forecasts</span>
                                <span
                                    style={{
                                        color: "#1877F2",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        marginLeft: "5px",
                                    }}
                                >
                                    By Influencers and Publishers
                                </span>
                            </span>
                            <span style={{marginLeft: "auto"}}>
                                <img
                                    src={window.constants.asset_path + "/images/link.svg"}
                                    width="24"
                                    height="24"
                                    alt="link"
                                />
                            </span>
                        </button>
                        </div>
                        )}
                        {loader == true && (
                            <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              gap: 1,
                              mt: 3,
                              margin: "20px 0.5% 0% 0.5%",
                              backgroundColor: "#fff",
                              padding: "20px 0px",
                              borderRadius: "8px",
                            }}
                          >
                            {/* Header Box */}
                            <Box
                              sx={{
                                backgroundColor: "#1877F2",
                                color: "#fff",
                                padding: "12px 24px",
                                fontSize: "16px",
                                fontWeight: "bold",
                                textAlign: "center",
                                width: "35%",
                                height: "44px",
                                marginBottom: "10px",
                              }}
                            >
                              Are You?
                            </Box>
                      
                            {/* Bearish Section */}
                            <Box sx={{ display: "flex", alignItems: "center", width: "35%", gap: 1, marginBottom: "5px" }}>
                              <img src={"/images/icons_bearish.svg"} alt="Bearish" style={{ width: "24px", height: "24px" }} />
                              <Button
                                sx={{
                                  flex: 1,
                                  background: "var(--Grad, linear-gradient(180deg, rgba(24, 119, 242, 0.40) -1.89%, rgba(24, 119, 242, 0.00) 60.38%))",
                                  color: "#000",
                                  borderRadius: "10px",
                                  padding: "10px 20px",
                                  height: "24px",
                                  "&:hover": {
                                    background: "linear-gradient(to right, #f0f0f0, #bbdefb)",
                                  },
                                  textTransform: "none",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  border: "1px solid #EEE",
                                }}
                                onClick={() => handleSentimentClick("bearish")}
                              >
                                <span>Bearish On {slug}</span>
                                {showPercentage && <Typography sx={{ fontSize: "14px", color: "#1877F2", fontWeight: "700" }}>{votes.bearish}%</Typography>}
                              </Button>
                            </Box>
                      
                            {/* Bullish Section */}
                            <Box sx={{ display: "flex", alignItems: "center", width: "35%", gap: 1 }}>
                              <img src={"/images/icons_bullish.svg"} alt="Bullish" style={{ width: "24px", height: "24px" }} />
                              <Button
                                sx={{
                                  flex: 1,
                                  background: "var(--Grad, linear-gradient(180deg, rgba(24, 119, 242, 0.40) -1.89%, rgba(24, 119, 242, 0.00) 60.38%))",
                                  color: "#000",
                                  borderRadius: "10px",
                                  padding: "10px 20px",
                                  height: "24px",
                                  "&:hover": {
                                    background: "linear-gradient(to right, #f0f0f0, #bbdefb)",
                                  },
                                  textTransform: "none",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  border: "1px solid #EEE",
                                }}
                                onClick={() => handleSentimentClick("bullish")}
                              >
                                <span>Bullish On {slug}</span>
                                {showPercentage && <Typography sx={{ fontSize: "14px", color: "#1877F2", fontWeight: "700" }}>{votes.bullish}%</Typography>}
                              </Button>
                            </Box>
                          </Box>
                        )}
                        
                        {(hasOscillatorData || hasMovingAverageData || processedDescription) && (
                            <div
                                style={{
                                    borderRadius: "0",
                                    backgroundColor: "#fff",
                                    padding: "20px 0px 2px 0px",
                                    fontFamily: "Satoshi, sans-serif",
                                    margin: "0px 5px 15px 5px",
                                }}
                            >
                                {(hasOscillatorData || hasMovingAverageData) && (
                                    <div
                                        style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}
                                    >
                                        <div
      style={{
        backgroundColor: "#1877F2", // Blue color
        color: "white",
        fontSize: "22px",
        fontWeight: "400",
        padding: "10px 12px",
        width: "100%",
        marginLeft:"0px",
        height:"50.461px",
        color:"#fff",
        marginBottom:"1.5%",
      }}
    >
      Technical Summary
    </div>
                                        
                                    </div>
                                )}

                                {(hasOscillatorData || hasMovingAverageData) && (
                                    <div
                                        style={{
                                            borderRadius: "6px",
                                            margin: "0px 22px 15px 10px",
                                        }}
                                    >
                                        {overallSummary && (
                                            <div>
                                                <p style={{marginLeft: "4px", marginTop: "20px"}}>
                                                    In the next week, the overall sentiment for {slug} crypto price is{" "}
                                                    {overallSummary}. The first level of support for {slug} is at{" "}
                                                    {pivotdata.pivot_s1}&nbsp; while the first level of resistance for{" "}
                                                    {slug} price is at {pivotdata.pivot_r1}.&nbsp;
                                                    {sentimentStatement}
                                                </p>
                                            </div>
                                        )}

                                        {(hasOscillatorData || hasMovingAverageData) && (
                                            <div>
                                                
                                                {(hasOscillatorData || hasMovingAverageData) && (
                                                    <p
                                                        style={{
                                                            textAlign: "right",
                                                            fontStyle: "italic",
                                                            margin: "2% 1%",
                                                            fontSize: "14px",
                                                            color: "#000",
                                                        }}
                                                    >
                                                        {timestamp}&nbsp;UTC
                                                    </p>
                                                )}

                                                {oscillatorData.length > 0 && (
                                                    <>
                                                        {/* Check if either RSI or MACD is present */}
                                                        {(oscillatorData[0].RSI || oscillatorData[4].MACD) && (
                                                            <>
                                                                {oscillatorData[0].RSI && (
                                                                    <p
                                                                        style={{
                                                                            fontSize: "16px",
                                                                            margin: "2% 1% 0% 0.5%",
                                                                        }}
                                                                    >
                                                                        The RSI value is at {oscillatorData[0].RSI},
                                                                        which indicates that the {slug} market is in a{" "}
                                                                        {oscillatorData[0].rsi_evaluation} position.
                                                                    </p>
                                                                )}

                                                                {oscillatorData[4].MACD && (
                                                                    <p
                                                                        style={{
                                                                            fontSize: "16px",
                                                                            margin: "2% 1% 0% 0.5%",
                                                                        }}
                                                                    >
                                                                        The MACD value is {oscillatorData[4].MACD}.{" "}
                                                                        {oscillatorData[4].macd_evaluation === "Buy" ? (
                                                                            <>
                                                                                The MACD line is above the signal line,
                                                                                and the green histogram bars have
                                                                                started to increase on the positive
                                                                                axis. This indicates that {slug} is in a
                                                                                buy position.
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                The MACD line is below the signal line,
                                                                                and the red histogram bars have started
                                                                                to increase on the negative axis. This
                                                                                indicates that {slug} is in a sell
                                                                                position.
                                                                            </>
                                                                        )}
                                                                        <br />
                                                                        <br />
                                                                    </p>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}

                                                {movingAveragesData.length > 0 && (
                                                    <>
                                                        {(movingAveragesData[0]["SMA (10)"] ||
                                                            movingAveragesData[4]["EMA (10)"] ||
                                                            movingAveragesData[1]["SMA (50)"] ||
                                                            movingAveragesData[5]["EMA (50)"] ||
                                                            movingAveragesData[2]["SMA (100)"] ||
                                                            movingAveragesData[3]["SMA (200)"] ||
                                                            movingAveragesData[6]["EMA (100)"] ||
                                                            movingAveragesData[7]["EMA (200)"]) && (
                                                            <>
                                                                <p
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        margin: "0px 1% 0px 0.5%",
                                                                        marginBottom: "10px",
                                                                    }}
                                                                >
                                                                    {movingAveragesData[0]["SMA (10)"] && (
                                                                        <>
                                                                            For {slug}, the 10-day SMA is{" "}
                                                                            {movingAveragesData[0]["SMA (10)"]}. As the
                                                                            price of {slug} is{" "}
                                                                            {currentPrice <
                                                                            movingAveragesData[0]["SMA (10)"]
                                                                                ? "below"
                                                                                : "above"}{" "}
                                                                            the 10-day SMA, it is{" "}
                                                                            {currentPrice <
                                                                            movingAveragesData[0]["SMA (10)"]
                                                                                ? "bearish"
                                                                                : "bullish"}
                                                                            . The short-term outlook is{" "}
                                                                            {currentPrice <
                                                                            movingAveragesData[0]["SMA (10)"]
                                                                                ? "negative"
                                                                                : "positive"}
                                                                            .
                                                                        </>
                                                                    )}

                                                                    {movingAveragesData[1]["SMA (50)"] && (
                                                                        <>
                                                                            &nbsp;Additionally, the 50-day SMA is{" "}
                                                                            {movingAveragesData[1]["SMA (50)"]}. {slug}{" "}
                                                                            is{" "}
                                                                            {currentPrice <
                                                                            movingAveragesData[1]["SMA (50)"]
                                                                                ? "below"
                                                                                : "above"}{" "}
                                                                            its 50-day SMA, it is{" "}
                                                                            {currentPrice <
                                                                            movingAveragesData[1]["SMA (50)"]
                                                                                ? "bearish"
                                                                                : "bullish"}
                                                                            .
                                                                        </>
                                                                    )}

                                                                    {(movingAveragesData[2]["SMA (100)"] ||
                                                                        movingAveragesData[3]["SMA (200)"]) && (
                                                                        <>
                                                                            &nbsp;The 100-day and 200-day SMAs
                                                                            representing the long-term outlook of {slug}{" "}
                                                                            are&nbsp;
                                                                            {movingAveragesData[2]["SMA (100)"] ||
                                                                                "-"}{" "}
                                                                            and{" "}
                                                                            {movingAveragesData[3]["SMA (200)"] || "-"}{" "}
                                                                            respectively. Currently, the price of {slug}{" "}
                                                                            is{" "}
                                                                            {currentPrice <
                                                                                movingAveragesData[2]["SMA (100)"] &&
                                                                            currentPrice <
                                                                                movingAveragesData[3]["SMA (200)"]
                                                                                ? "below"
                                                                                : "above"}{" "}
                                                                            both the 100-day and 200-day Moving
                                                                            Averages. It is{" "}
                                                                            {currentPrice <
                                                                                movingAveragesData[2]["SMA (100)"] &&
                                                                            currentPrice <
                                                                                movingAveragesData[3]["SMA (200)"]
                                                                                ? "bearish"
                                                                                : "bullish"}
                                                                            .
                                                                        </>
                                                                    )}
                                                                </p>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )}
                                {loader == true && (
  <div style={{ display: "flex", justifyContent: "center", gap: "2%" }}>
    <button
      onClick={() => {
        window.open(`/crypto-screener`, "_blank");
      }}
      
      style={{
        fontSize: "22px",
        background: "linear-gradient(90deg, #9AFFF9 20%, #FFF06D 100%)",
        color: "#1E88E5",
        border: "2px solid #1E88E5",
        borderRadius: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "12px",
        cursor: "pointer",
        fontWeight: "700",
        padding: "10px 20px",
        marginBottom: "1%",
        marginTop: "1%",
        width: "fit-content",
        minWidth: "45%",
        flexWrap: "nowrap",
      }}
    >
      <span
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
        }}
      >
        <span
          style={{
            width: "21px",
            height: "20px",
            backgroundImage: `url(${window.constants.asset_path}/images/down-graph.svg)`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        ></span>
        <span
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "25px",
            height: "25px",
            backgroundImage: `url(${window.constants.asset_path}/images/lens-new.svg)`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            marginLeft: "4px",
            marginBottom: "7px",
          }}
        ></span>
      </span>
      <span style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        Crypto Screener
        <img
          src={`${window.constants.asset_path}/images/link.svg`}
          width="24"
          height="24"
          alt="link"
        />
      </span>
    </button>
  </div>
)}

        {loader === true && indexData.length > 0 && (
                        <div
      style={{
        backgroundColor: "#1877F2", // Blue color
        color: "white",
        fontSize: "22px",
        fontWeight: "400",
        padding: "8px 12px",
        width: "100%",
        marginLeft:"0px",
        height:"50.461px",
        color:"#fff",
        marginBottom:"0.5%",
      }}
    >
      Cryptos Influencers Are Currently Bullish On
    </div>
    
    )}
    {loader === true && indexData.length > 0 && (
        <Box sx={{ display: "flex", justifyContent:"flex-start", gap: "5%", padding: 2 }}>
        {indexData.map((data, index) => (
          <Box
            key={index}
            sx={{
              flex: "0 1 auto",
              padding: "12px 10px",
              borderRadius: "20px",
              background: "linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%)",
              textAlign: "center",
              border: "1.5px solid #1877F2",
              minWidth: "30%",
              maxWidth: "30%",
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch"
            }}
          >
            <Typography sx={{ fontWeight: "500", mb: 1, textAlign: "center", fontSize: "16px" }}>
            <img
      className="b57ac673f06a4b0338a596817eb0a5Icon"
      alt="logo"
      src={data.logo}
      style={{
        width: "38px",
        height: "38px",
        border: "2px solid black",
        borderRadius: "20px",
        marginRight: "8px",
      }}
    /><a
    href={`/crypto/${data.name.replace(/\s+/g, "-")}`}

    style={{color: "black"}}
    
>{data.title}</a>
            </Typography>
            <Box sx={{ width: "96%", height: "1.5px", background: "#1877F2", mb: 2, marginLeft: "7px" }} />
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, px: 1 }}>
              <Box sx={{ display: "flex", flexDirection: "column", fontSize: "14px", textAlign: "left", flex: 1 }}>
                <Typography style={{ marginTop: "5px" }}>Last 7 Days</Typography>
                <Typography style={{ marginTop: "5px" }}>Last 30 Days</Typography>
                <Typography style={{ marginTop: "5px" }}>Grade</Typography>
              </Box>
              <Box sx={{ width: "1.5px", background: "#1877F2", height: "100%" }} />
              <Box sx={{ display: "flex", flexDirection: "column", fontSize: "14px", textAlign: "left", flex: 1 }}>
                <Typography style={{ marginTop: "5px" }}>{data.last7Days}</Typography>
                <Typography style={{ marginTop: "5px" }}>{data.last30Days}</Typography>
                <Typography
                  sx={{ marginTop: "5px" }}
                >
                  {
    data.benchmark === 'Outperformer' ? (
    <button disabled style={{ fontWeight: "bold", padding: "3px" , borderRadius: "5px", backgroundColor: '#DFFFF0', color: '#199F5F', border: '2px solid #199F5F', width: "125px",fontSize:  "12px" }}>Outperformer</button>
  ) : data.benchmark === 'Emerging Outperformer' ? (
  <button disabled style={{ fontWeight: "bold", padding: "3px" , borderRadius: "5px", backgroundColor: '#DFFFF0', color: '#199F5F', border: '2px solid #199F5F',  width: "125px",fontSize:  "12px"}}>Outperformer</button>
) :
  data.benchmark === 'Moderate Performer' ? (
    <button disabled style={{ fontWeight: "bold", padding: "3px", borderRadius: "5px", backgroundColor: '#F9F4E7', color: '#D4AF37', border: '2px solid #D4AF37',  width: "125px",fontSize: "12px"}}>Moderate Performer</button>
  )  : data.benchmark === 'Underperformer' ? (
    <button disabled style={{ alignItems: 'center', fontWeight: "bold", padding:"3px ", borderRadius: "5px", backgroundColor: '#FFE3E3', color: '#FF4141', border: '2px solid #FF4141',  width: "125px",fontSize: "12px" }}>Underperformer</button>
) : data.benchmark}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      
      

    )}
    {loader === true && (indexData.length > 0 || indexPortfolios.length > 0) && (
  <div style={{ display: "flex", justifyContent: "center", gap: "2%" }}>
    <button
      onClick={() => {
        window.open(`/crypto/${crypto}/trends`, "_blank");
        window.scrollTo({ top: 0, behavior: "smooth" });
      }}
      
      style={{
        fontSize: "20px",
        backgroundColor: "#fff",
        color: "#1E88E5",
        border: "2px solid #1E88E5",
        borderRadius: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "12px",
        cursor: "pointer",
        fontWeight: "700",
        padding: "1px 20px",
        marginBottom: "1%",
        marginTop: "1.5%",
        width: "fit-content",
        minWidth: "45%",
        flexWrap: "nowrap",
      }}
    >
      <span
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
        }}
      >
        <span
          style={{
            width: "21px",
            height: "20px",
            backgroundImage: `url(${window.constants.asset_path}/images/down-graph.svg)`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        ></span>
        <span
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "25px",
            height: "25px",
            backgroundImage: `url(${window.constants.asset_path}/images/lens-new.svg)`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            marginLeft: "4px",
            marginBottom: "7px",
          }}
        ></span>
      </span>
      <span style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      Explore Influencer And Investor Sentiments

        <img
          src={`${window.constants.asset_path}/images/link.svg`}
          width="24"
          height="24"
          alt="link"
          style={{marginLeft:"5%"}}
        />
      </span>
    </button>
  </div>
)}
    {loader==true && indexPortfolios.length > 0 && (
                        <div
      style={{
        backgroundColor: "#1877F2", // Blue color
        color: "white",
        fontSize: "22px",
        fontWeight: "400",
        padding: "10px 12px",
        width: "100%",
        marginLeft:"0px",
        height:"50.461px",
        color:"#fff",
        marginBottom:"0.5%",
        marginTop: "10px"
      }}
    >
      Top Performing Indices
    </div>
    
    )}
    {loader==true && indexPortfolios.length > 0 && (
        <Box sx={{ display: "flex", justifyContent:"flex-start", gap: "5%", padding: 2 }}>
        {indexPortfolios.map((data, index) => (
          <Box
            key={index}
            sx={{
              flex: "0 1 auto",
              padding: "12px 10px",
              borderRadius: "20px",
              background: "linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%)",
              textAlign: "center",
              border: "1.5px solid #1877F2",
              minWidth: "30%",
              maxWidth: "30%",
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch"
            }}
          >
            <Typography sx={{ fontWeight: "500", mb: 1, textAlign: "center", fontSize: "16px" }}>
            <img
      className="b57ac673f06a4b0338a596817eb0a5Icon"
      alt="logo"
      src={data.logo}
      style={{
        width: "38px",
        height: "38px",
        border: "2px solid black",
        borderRadius: "20px",
        marginRight: "6px",
      }}
    /><a
    href={`/portfolio/${data.title.replace(/\s+/g, "-")}`}


    style={{color: "black"}}
    
>{data.title}</a>
            </Typography>
            <Box sx={{ width: "96%", height: "1.5px", background: "#1877F2", mb: 2, marginLeft: "7px" }} />
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, px: 1 }}>
              <Box sx={{ display: "flex", flexDirection: "column", fontSize: "14px", textAlign: "left", flex: 1 }}>
                <Typography style={{ marginTop: "5px" }}>Last 7 Days</Typography>
                <Typography style={{ marginTop: "5px" }}>Last 30 Days</Typography>
                <Typography style={{ marginTop: "5px" }}>Grade</Typography>
              </Box>
              <Box sx={{ width: "1.5px", background: "#1877F2", height: "100%" }} />
              <Box sx={{ display: "flex", flexDirection: "column", fontSize: "14px", textAlign: "left", flex: 1 }}>
                <Typography style={{ marginTop: "5px" }}>{data.last7Days}</Typography>
                <Typography style={{ marginTop: "5px" }}>{data.last30Days}</Typography>
                <Typography
                  sx={{ marginTop: "5px" }}
                >
                   {
    data.benchmark === 'Outperformer' ? (
    <button disabled style={{ fontWeight: "bold", padding: "3px" , borderRadius: "5px", backgroundColor: '#DFFFF0', color: '#199F5F', border: '2px solid #199F5F', width: "125px",fontSize:  "12px" }}>Outperformer</button>
  ) : data.benchmark === 'Emerging Outperformer' ? (
  <button disabled style={{ fontWeight: "bold", padding: "3px" , borderRadius: "5px", backgroundColor: '#DFFFF0', color: '#199F5F', border: '2px solid #199F5F',  width: "125px",fontSize:  "12px"}}>Outperformer</button>
) :
  data.benchmark === 'Moderate Performer' ? (
    <button disabled style={{ fontWeight: "bold", padding: "3px", borderRadius: "5px", backgroundColor: '#F9F4E7', color: '#D4AF37', border: '2px solid #D4AF37',  width: "125px",fontSize: "12px"}}>Moderate Performer</button>
  )  : data.benchmark === 'Underperformer' ? (
    <button disabled style={{ alignItems: 'center', fontWeight: "bold", padding:"3px ", borderRadius: "5px", backgroundColor: '#FFE3E3', color: '#FF4141', border: '2px solid #FF4141',  width: "125px",fontSize: "12px" }}>Underperformer</button>
) : data.benchmark}

                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      

    )}
    
     {loader === true && (indexData.length > 0 || indexPortfolios.length > 0) && (
  <div style={{ display: "flex", justifyContent: "center", gap: "2%" }}>
    <button
      onClick={() => {
        window.open(`/crypto-screener`, "_blank");
      }}
      
      style={{
        fontSize: "22px",
        backgroundColor: "#fff",
        color: "#1E88E5",
        border: "2px solid #1E88E5",
        borderRadius: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "12px",
        cursor: "pointer",
        fontWeight: "700",
        padding: "10px 20px",
        marginBottom: "2%",
        marginTop: "1.5%",
        width: "fit-content",
        minWidth: "45%",
        flexWrap: "nowrap",
      }}
    >
      <span
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
        }}
      >
        <span
          style={{
            width: "21px",
            height: "20px",
            backgroundImage: `url(${window.constants.asset_path}/images/down-graph.svg)`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        ></span>
        <span
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "25px",
            height: "25px",
            backgroundImage: `url(${window.constants.asset_path}/images/lens-new.svg)`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            marginLeft: "4px",
            marginBottom: "7px",
          }}
        ></span>
      </span>
      <span style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        Crypto Screener
        <img
          src={`${window.constants.asset_path}/images/link.svg`}
          width="24"
          height="24"
          alt="link"
        />
      </span>
    </button>
  </div>
)}
                                

                                <div
                                    style={{
                                        border: "1px solid #E2E2E4",
                                        borderRadius: "6px",
                                        margin: "4% 22px 20px 15px",
                                        padding: "10px 0px 5px 3px",
                                    }}
                                >
                                    {processedDescription && (
                                    <span style={{marginLeft:"2px", fontWeight:"700", fontSize:"18px", 
                                        color:"#1877F2"}}>{slug} Analysis</span>
                                    )}
                                    <div
                                        className="description"
                                        style={{
                                            fontFamily: "Satoshi, sans-serif!important",
                                            borderRadius: "4px",
                                            padding: "2px",
                                            marginTop: "15px",
                                        }}
                                    >
                                        {processedDescription ? (
                                            <div dangerouslySetInnerHTML={{__html: processedDescription}}></div>
                                        ) : (
                                            <p></p> // or any other placeholder text
                                        )}
                                    </div>
                                </div>
                                {/* <div
                                    style={{
                                        border: "1px solid #E2E2E4",
                                        borderRadius: "6px",
                                        margin: "5px 22px 20px 15px",
                                        padding: "10px 0px 5px 3px",
                                    }}
                                >
                                    <p
                                        style={{
                                            fontSize: "20px",
                                            fontWeight: "700",
                                            color: "#1877F2",
                                            background: "white",
                                        }}
                                    >
                                        Updates
                                    </p>
                                    <div
                                        className="description"
                                        style={{
                                            fontFamily: "Satoshi, sans-serif!important",
                                            borderRadius: "4px",
                                            padding: "2px",
                                            marginTop: "15px",
                                        }}
                                    >
                                        {analysis_data.ai_news && analysis_data.ai_news != "NA" ? (
                                            analysis_data.ai_news
                                            .split("\n") // Split the string into an array using '\n' as the delimiter
                                            .filter((line) => line.trim() !== "") // Remove empty lines
                                            .map((line, index) => (
                                                <p key={index}>{line.trim().replace(/^-\s, "")}</p> // Remove leading '-' and trim whitespace
                                            ))
                                        ) : (
                                            <p>No Updates Available</p> // or any other placeholder text
                                        )}
                                    </div>
                                </div> */}
                            </div>
                        )}

                        
                    </div>
                </div>
            ) : isPredictionDataLoaded === false && predictionLoader ? (
                <div style={{marginBottom: "50%"}}>
                   
                    <div>
                        <i>
                            <p>
                                <b>Note:</b> Please consult a registered investment advisor to guide your
                                financial decisions.
                            </p>
                        </i>
                    </div>
                </div>
            ) : (
                null
            )}
           {mediaQueryVar && sortedData.length > 0 && (
  <div style={{ marginLeft: "20.5vw" }}>
    <i>
      <p className="text-justify">
        <b>Note:</b> Please consult a registered investment advisor to guide your financial decisions.
      </p>
    </i>
    {/* <Footer /> */}
  </div>
)}

            
            {mediaQueryVar==false && sortedData.length > 0 && (
                <i>
                    <p style={{marginLeft: "0", marginBottom:"80px"}} class="text-justify">
                        <b>Note:</b> Please consult a registered investment advisor to guide your financial decisions.
                    </p>
                </i>
            )}
            {/* {mediaQueryVar==false && sortedData.length > 0 && (
                <Footer/>
            )} */}
            {mediaQueryVar === false && (
                <div
                    style={{
                        position: "fixed",
                        bottom: "0",
                        backgroundColor: "white",
                        width: "105%",
                        zIndex: "100",
                        marginBottom: "0px",
                        marginLeft: "-25px",
                    }}
                >
                    <BottomNavigation showLabels>
                        <BottomNavigationAction
                            label="Top Portfolios"
                            onClick={() => navigate("/portfolio-gallery")}
                            icon={
                                <img
                                    src={
                                        loc === "/portfolio-gallery"
                                            ? "/images/dashboardS.svg"
                                            : "/images/dashboardU.svg"
                                    }
                                    width={"20px"}
                                    height={"20px"}
                                    alt="gallery"
                                />
                            }
                        />
                        <BottomNavigationAction
                            label="Crypto Screener"
                            onClick={() => navigate("/crypto-screener")}
                            icon={
                                <img
                                    src={
                                        loc === "/crypto-screener" ? "/images/portfolioS.svg" : "/images/portfolioU.svg"
                                    }
                                    width={"20px"}
                                    height={"20px"}
                                    alt="screener"
                                />
                            }
                        />
                        <BottomNavigationAction
                            label="Portfolio            Audit"
                            onClick={() => {
                                if (userData) {
                                    navigate("/subscriptions/screener360");
                                } else {
                                    const originalUrl = "/subscriptions/screener360";
                                    sessionStorage.setItem("originalUrl", originalUrl);
                                    handleLoginClick();
                                }
                            }}
                            icon={
                                <img
                                    src={
                                        loc === "/subscriptions/screener360"
                                            ? "/images/dollarS.svg"
                                            : "/images/dollarU.svg"
                                    }
                                    width={24}
                                    height={24}
                                    alt="Icon"
                                    style={{marginBottom: "-3px"}}
                                />
                            }
                        />
                        <BottomNavigationAction
                            label="Watchlist"
                            onClick={() => navigate("/crypto-watchlist")}
                            icon={
                                <img
                                    src={
                                        loc === "/crypto-watchlist" ? "/images/portfolioS.svg" : "/images/portfolioU.svg"
                                    }
                                    width={"20px"}
                                    height={"20px"}
                                    alt="crypto watchlist"
                                />
                            }
                        />
                    </BottomNavigation>
                </div>
            )}
            {isLoginModalOpen && (
                <LoginPopUp
                    isLoginModalOpen={isLoginModalOpen}
                    setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
                    onClose={handleModalClose}
                />
            )}
            <Modal
                style={{
                    overlay: {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 1000,
                        marginLeft: mediaQueryVar === true ? "20%" : "",
                    },
                    content: {
                        align: "center",
                        width: "80%",
                        marginBottom: "20%",
                        height: "auto",
                        borderRadius: "8px",
                        position: "center",
                    },
                }}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Accuracy Details"
            >
                <img
                    className="frameIcon"
                    alt="frameIcon"
                    onClick={closeModal}
                    src="/images/frame17.svg"
                    style={{
                        background: "#F3F3F3",
                        width: "25px",
                        height: "25px",
                        marginTop: "1.5%",
                        marginBottom: "1%",
                    }}
                />
                <h4>Past Accuracy</h4>
                {modalData ? (
                    <table className="modal-table">
                        <thead>
                            <tr>
                                <th>Published On</th>
                                <th>Forecast Date</th>
                                <th style={{textAlign: "center"}}>Forecast Price</th>
                                <th style={{textAlign: "center"}}>Accuracy</th>
                            </tr>
                        </thead>
                        <tbody>
                            {modalData.map((detail, index) => (
                                <tr key={index}>
                                    <td>{detail.publish_date}</td>
                                    <td>{detail.target_date} 11:59 PM</td>
                                    <td style={{textAlign: "center"}}>{detail.forecast_price}</td>
                                    <td style={{textAlign: "center"}}>{detail.accuracy}%</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No data available</p>
                )}
            </Modal>
        </div>
    );
};

export default CryptoPage;
