import React, {useEffect, useState} from "react";
import {
    useAppKit,
    useAppKitAccount,
    useAppKitNetwork,
    useAppKitNetworkCore,
    useAppKitProvider,
    useDisconnect,
} from "@reown/appkit/react";
import {saveCryptoPresalePayment} from "../presale_form/api";
import {ethRecipientAddress} from "../../../common/constant/api_key";
import ProcessingAlert from "../../../common/alert/ProcessingAlert";
import SuccessPaymentAlert from "../../../common/alert/SuccessPaymentAlert";
import {ethers, JsonRpcSigner} from "ethers";
import {mainnet} from "@reown/appkit/networks";
import {useNetwork} from "../../../ReownAppKitProvider.tsx";
import {Alert, Snackbar} from "@mui/material";

const ETHConnect2 = ({paymentMethod, select_price}) => {
    const {address, isConnected, blockchain} = useAppKitAccount();
    const {disconnect} = useDisconnect();
    const {walletProvider} = useAppKitProvider("eip155", {
        autoSwitchNetwork: false,
    });
    const {switchNetwork, chainId} = useAppKitNetwork();
    const isWrongNetwork = chainId !== mainnet.id;

    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const {selectedNetwork, setSelectedNetwork} = useNetwork();

    // alert message
    const [alertMessage, setAlertMessage] = useState("");
    const [snackbaropen, setSnackbarOpen] = useState(false);
    const [severity, setSeverity] = useState(false);

    useEffect(() => {
        setSelectedNetwork("ethereum");
    }, [setSelectedNetwork]);

    useEffect(() => {
        const switchToEthereum = async () => {
            if (isWrongNetwork && switchNetwork) {
                setAlertMessage("Switching to Ethereum network...");
                setSnackbarOpen(true);
                setSeverity("info");
                await switchNetwork(mainnet);
                setTimeout(() => {
                    setSnackbarOpen(false);
                }, [2000]);
            }
        };
        switchToEthereum();
    }, [isWrongNetwork, address, switchNetwork]);


    const initiateTransaction = async (signer, ethToPay) => {
        const tx = {
            to: ethRecipientAddress,
            value: ethers.parseEther(ethToPay.toString()), 
            // value: ethers.parseEther("0.00001"), // Amount in ETH
        };

        const response = await signer.sendTransaction(tx);
        return response;
    };

    const handleSendEth = async () => {
        if (!select_price) {
            setAlertMessage("Please select an amount");
            setSnackbarOpen(true);
            setSeverity("error");
            setTimeout(() => {
                setSnackbarOpen(false);
            }, [2000]);
            return;
        }
        try {
            // setLoading(true);
            const response = await fetch(
                "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
            );
            const data = await response.json();
            const ethPriceInUsd = data.ethereum.usd;

            const priceInUSD = select_price;
            const ethToPay = priceInUSD / ethPriceInUsd;

            const provider = new ethers.BrowserProvider(walletProvider, chainId);
            const signer = new JsonRpcSigner(provider, address);

            // Check user balance before sending
            const balance = await provider.getBalance(address);
            const balanceInEth = ethers.formatEther(balance);

            if (parseFloat(balanceInEth) < parseFloat(ethToPay)) {
                setAlertMessage("Insufficient balance. Please add more ETH to your wallet.");
                setSnackbarOpen(true);
                setSeverity("error");
                setTimeout(() => {
                    setSnackbarOpen(false);
                }, [2000]);
                return;
            }

            setLoading(true);
            const transactionResponse = await initiateTransaction(signer, ethToPay);

            await transactionResponse.wait();

            // const verified= await verifyTransactionEthBackend(transactionResponse.hash, ethRecipientAddress, ethToPay);
            // console.log("verified", verified)

            const type = "eth";
            await saveCryptoPresalePayment(
                ethRecipientAddress,
                await signer.getAddress(),
                transactionResponse.hash,
                priceInUSD,
                type
            );
            setIsSuccess(true);
        } catch (error) {
            console.error("Error sending ETH:", error, error.message, error.code);
            if (error.code === "ACTION_REJECTED") {
                setAlertMessage("Transaction rejected by the user.");
                setSeverity("error");
                setTimeout(() => {
                    setSnackbarOpen(false);
                }, [3000]);
            } else {
                alert(`An error occurred: ${error.message}`);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleDisconnect = async () => {
        setLoading(true);
        try {
            await disconnect();
            setIsSuccess(false);
        } catch (error) {
            console.error("Disconnection failed:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isConnected) {
            handleDisconnect();
        }
    }, [paymentMethod]);

    return loading ? (
        <ProcessingAlert />
    ) : (
        <>
            <div style={{textAlign: "center"}}>
                <appkit-button />
                {isConnected && <button onClick={() => handleSendEth()}>Pay with {paymentMethod.toUpperCase()}</button>}
            </div>
            <SuccessPaymentAlert open={isSuccess} onClose={() => setIsSuccess(false)} />
            <Snackbar
                open={snackbaropen}
                autoHideDuration={5000}
                // onClose={closesnackbar}
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
            >
                <Alert severity={severity}>{alertMessage}</Alert>
            </Snackbar>
        </>
    );
};

export default ETHConnect2;
