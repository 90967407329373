import React, {useEffect, useState} from "react";
import {useAppKitAccount, useAppKitNetwork, useAppKitProvider, useDisconnect} from "@reown/appkit/react";
import {SystemProgram, PublicKey, Transaction, LAMPORTS_PER_SOL, Connection} from "@solana/web3.js";
import {saveCryptoPresalePayment} from "../presale_form/api";
import {recipientAddress} from "../../../common/constant/api_key";
import ProcessingAlert from "../../../common/alert/ProcessingAlert";
import SuccessPaymentAlert from "../../../common/alert/SuccessPaymentAlert";
import {solana} from "@reown/appkit/networks";
import {useNetwork} from "../../../ReownAppKitProvider.tsx";
import {PhantomWalletAdapter, SolflareWalletAdapter} from "@solana/wallet-adapter-wallets";
import { Alert, Snackbar } from "@mui/material";

const SOLConnectButton = ({paymentMethod, select_price}) => {
    const {isConnected, address} = useAppKitAccount();
    const {disconnect} = useDisconnect();
    const {switchNetwork, chainId} = useAppKitNetwork();
    const isWrongNetwork = chainId !== solana.id;
    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const {selectedNetwork, setSelectedNetwork} = useNetwork();
    const [connected, setConnected] = useState(false);

    const [userBalance, setUserBalance] = useState("");
    const [walletAddress, setWalletAddress] = useState("");
    const [provider, setProvider] = useState();

    // alert message
    const [alertMessage, setAlertMessage] = useState("");
    const [snackbaropen, setSnackbarOpen] = useState(false);
    const [severity, setSeverity] = useState(false);

    // Set the selected network to Solana
    useEffect(() => {
        setSelectedNetwork("solana");
    }, [setSelectedNetwork]);

    const connection = new Connection(
        "https://alpha-crimson-diagram.solana-mainnet.quiknode.pro/12683d2983a09482058b04c03b66a20eff0732b6/",
        "confirmed"
    );

    useEffect(() => {
        const switchToSolana = async () => {
            if (isWrongNetwork && switchNetwork) {
                setAlertMessage("Switching to Solana network...");
                setSnackbarOpen(true);
                setSeverity("info");
                await switchNetwork(solana);
                setTimeout(() => {
                    setSnackbarOpen(false);
                }, [2000]);
            }
        };
        switchToSolana();
    }, [isWrongNetwork, switchNetwork]);

    const [detectedWallets, setDetectedWallets] = useState([]);

    useEffect(() => {
        const availableWallets = [];

        if (window.solana?.isPhantom) {
            availableWallets.push("Phantom");
        }

        if (window.solflare?.isSolflare) {
            availableWallets.push("Solflare");
        }

        setDetectedWallets(availableWallets);
    }, []);

    const initiateTransaction = async (connection, address, solToPay) => {
        if (!provider || !provider.publicKey) {
            throw new Error("Wallet is not connected.");
        }

        const senderWallet = provider.publicKey; // Get sender's public key
        const recipientWallet = new PublicKey(recipientAddress); // Recipient's wallet address

        // Fetch latest blockhash
        const {blockhash, lastValidBlockHeight} = await connection.getLatestBlockhash();

        // Create the transaction
        const transaction = new Transaction().add(
            SystemProgram.transfer({
                fromPubkey: senderWallet,
                toPubkey: recipientWallet,
                // lamports: LAMPORTS_PER_SOL * 0.00001, // Amount to send

                lamports: LAMPORTS_PER_SOL * solToPay, 
            })
        );

        transaction.feePayer = senderWallet;
        transaction.recentBlockhash = blockhash;

        // Check if provider supports signing transactions
        if (provider.signTransaction) {
            const signedTransaction = await provider.signTransaction(transaction);
            const signature = await connection.sendRawTransaction(signedTransaction.serialize());
            await connection.confirmTransaction({signature, blockhash, lastValidBlockHeight});

            return signature;
        }
        // Solflare-specific transaction handling
        else if (provider.signAndSendTransaction) {
            const {signature} = await provider.signAndSendTransaction(transaction);
            await connection.confirmTransaction(signature, {commitment: "confirmed"});

            return signature;
        } else {
            throw new Error("Wallet provider does not support signing transactions.");
        }
    };

    const handleSendSol = async () => {
        if (!select_price) {
            setAlertMessage("Please select an amount");
            setSnackbarOpen(true);
            setSeverity("error");
            setTimeout(() => {
                setSnackbarOpen(false);
            }, [2000]);
            return;
        }
        try {
            setLoading(true);

            const balance = await connection.getBalance(new PublicKey(walletAddress));
            const balanceInSol = balance / LAMPORTS_PER_SOL;
            const response = await fetch("https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd");
            const data = await response.json();
            const solPriceInUsd = data.solana.usd;

            const priceInUSD = select_price;
            const solToPay = priceInUSD / solPriceInUsd;

            if (parseFloat(solToPay) > parseFloat(balanceInSol)) {
                setAlertMessage("Insufficient balance. Please add more SOL to your wallet.");
                setSnackbarOpen(true);
                setSeverity("error");
                setTimeout(() => {
                    setSnackbarOpen(false);
                }, [2000]);
                return;
            }

            const transactionResponse = await initiateTransaction(connection, walletAddress, solToPay);

            await saveCryptoPresalePayment(recipientAddress, walletAddress, transactionResponse, priceInUSD, "sol");

            setIsSuccess(true);
        } catch (error) {
            if (error?.message?.includes("User rejected the request") || error.includes("Transaction cancelled")) {
                setAlertMessage("Transaction rejected by the user.");
                setSeverity("error");
                setTimeout(() => {
                    setSnackbarOpen(false);
                }, [3000]);
            } else {
                console.error("Error sending SOL:", error);
                alert("Error sending SOL: " + error.message ? error.message : error);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleConnectWallet = async (walletType) => {

        try {
            if (walletType === "phantom") {
                if ("solana" in window && window.solana?.isPhantom) {
                    const provider = window.solana;
                    const response = await provider.connect();
                    setWalletAddress(response.publicKey.toString());
                    setConnected(true);
                    setProvider(provider);

                    // setPaymentMethod("phantom");
                } else {
                    setAlertMessage("Phantom Wallet not installed!");
                    setSeverity("error");
                    setTimeout(() => {
                        setSnackbarOpen(false);
                    }, [3000]);
                }
            } else if (walletType === "solflare") {
                if ("solflare" in window && window.solflare?.isSolflare) {
                    const provider = window.solflare;
                    const response = await provider.connect();
                    if (window.solflare.publicKey.toString()) {
                        setWalletAddress(window.solflare.publicKey.toString());
                        setConnected(true);
                        setProvider(provider);
                    } else {
                        console.error("No wallet connected to Solflare");
                    }

                    // setPaymentMethod("solflare");
                } else {
                    setAlertMessage("Solflare Wallet not installed!");
                    setSeverity("error");
                    setTimeout(() => {
                        setSnackbarOpen(false);
                    }, [3000]);
                }
            }
        } catch (error) {
            console.error(`Connection to ${walletType} failed:`, error);
        }
    };

    useEffect(() => {
        const fetchBalance = async () => {
            if (provider) {
                try {
                    const balance = await connection.getBalance(new PublicKey(walletAddress));
                    const balanceInSol = balance / LAMPORTS_PER_SOL;
                    setUserBalance(balanceInSol);
                } catch (error) {
                    console.error("Error fetching balance:", error);
                }
            }
        };

        fetchBalance(); // Call the async function inside useEffect
    }, [provider, connection, walletAddress]); // Add dependencies properly

    const handleDisconnect = async () => {
        setLoading(true);
        try {
            await disconnect();
            setIsSuccess(false);
        } catch (error) {
            console.error("Disconnection failed:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isConnected) {
            handleDisconnect();
        }
    }, [paymentMethod]);

    return loading ? (
        <ProcessingAlert />
    ) : (
        <>
            <div style={{textAlign: "center"}}>
                {connected ? (
                    <>
                        <div className="d-flex justify-content-center">
                            <img
                                style={{
                                    margin: "0px 3% 0px 0px",
                                    width: "21px",
                                    height: "24px",
                                }}
                                src="images/icons/solana-sol-logo.svg"
                                alt="crypto-img"
                            />
                            <p
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                {userBalance} SOL
                            </p>
                            <img
                                style={{
                                    margin: "0px 3% 0px 10%",
                                    width: "20px",
                                    height: "20px",
                                }}
                                src="images/presale/solana3d-sphere.svg"
                            />
                            <p
                                style={{
                                    color: "#949e9e",
                                    fontWeight: "bold",
                                }}
                            >
                                {walletAddress.slice(0, 4) + "..." + walletAddress.slice(-6)}
                            </p>
                        </div>

                        <button onClick={handleSendSol}>Pay with {paymentMethod.toUpperCase()}</button>
                    </>
                ) : (
                    <div className="d-block">
                        <button
                            onClick={() => handleConnectWallet("phantom")}
                            style={{
                                width: "200px",
                                padding: "10px",
                                margin: "10px",
                                fontSize: "16px",
                                cursor: "pointer",
                                display: "inline-block",
                            }}
                        >
                            Connect Phantom{" "}
                            {detectedWallets.includes("Phantom") && <span style={{color: "green"}}>Detected</span>}
                        </button>
                        <br />
                        <button
                            onClick={() => handleConnectWallet("solflare")}
                            style={{
                                width: "200px",
                                padding: "10px",
                                margin: "10px",
                                fontSize: "16px",
                                cursor: "pointer",
                                display: "inline-block",
                            }}
                        >
                            Connect Solflare{" "}
                            {detectedWallets.includes("Solflare") && <span style={{color: "green"}}>Detected</span>}
                        </button>
                    </div>
                )}
            </div>
            <SuccessPaymentAlert open={isSuccess} onClose={() => setIsSuccess(false)} />
            <Snackbar
                            open={snackbaropen}
                            autoHideDuration={5000}
                            // onClose={closesnackbar}
                            anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                        >
                            <Alert severity={severity}>{alertMessage}</Alert>
                        </Snackbar>
        </>
    );
};

export default SOLConnectButton;
