import React, {useEffect, useState} from "react";
import {
    useAppKitAccount,
    useAppKitNetwork,
    useAppKitNetworkCore,
    useAppKitProvider,
    useDisconnect,
} from "@reown/appkit/react";
import {ethers, JsonRpcSigner} from "ethers";
import {saveCryptoPresalePayment, verifyTransactionEthBackend} from "../presale_form/api";
import {bnbRecipientAddress} from "../../../common/constant/api_key";
import ProcessingAlert from "../../../common/alert/ProcessingAlert";
import SuccessPaymentAlert from "../../../common/alert/SuccessPaymentAlert";
import {bsc} from "@reown/appkit/networks";
import {Alert, Snackbar} from "@mui/material";

const BNBConnectButton = ({paymentMethod, select_price}) => {
    const {address, isConnected} = useAppKitAccount();
    const {disconnect} = useDisconnect();

    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const {walletProvider} = useAppKitProvider("eip155", {autoSwitchNetwork: false});

    const {switchNetwork, chainId} = useAppKitNetwork();
    const isWrongNetwork = chainId !== bsc.id;

    // alert message
    const [alertMessage, setAlertMessage] = useState("");
    const [snackbaropen, setSnackbarOpen] = useState(false);
    const [severity, setSeverity] = useState(false);



    useEffect(() => {
        const switchToBNB = async () => {
            if (isWrongNetwork && switchNetwork) {
                setAlertMessage("Switching to BNB network...");
                setSnackbarOpen(true);
                setSeverity("info");
                await switchNetwork(bsc);
                setTimeout(() => {
                    setSnackbarOpen(false);
                }, [2000]);
            }
        };
        switchToBNB();
    }, [isWrongNetwork, address, switchNetwork]);

    const initiateTransaction = async (signer, bnbToPay) => {
        const value = ethers.parseEther(bnbToPay.toString());
        const tx = {
            to: bnbRecipientAddress, 
            // value: ethers.parseEther("0.0001"), 
            value: ethers.parseEther(value)
        };
        const response = await signer.sendTransaction(tx);
        return response;
    };

    // Handle Send BNB Logic
    const handleSendBNB = async () => {
        if (!select_price) {
            setAlertMessage("Please select an amount");
            setSnackbarOpen(true);
            setSeverity("error");
            setTimeout(() => {
                setSnackbarOpen(false);
            }, [2000]);
            return;
        }
        try {

            const response = await fetch(
                "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd"
            );
            const data = await response.json();
            const bnbPriceInUsd = data.binancecoin.usd;
            const priceInUSD = select_price;
            const bnbToPay = ethers.parseUnits((priceInUSD / bnbPriceInUsd).toFixed(8), "ether");


            const provider = new ethers.BrowserProvider(walletProvider);
            const signer = new JsonRpcSigner(provider, address);

            // Balance
            const balance = await provider.getBalance(address);
            const balanceInBNB = ethers.formatEther(balance);

            if (parseFloat(balanceInBNB) < parseFloat(bnbToPay)) {
                setAlertMessage("Insufficient balance. Please add more BNB to your wallet.");
                setSnackbarOpen(true);
                setSeverity("error");
                setTimeout(() => {
                    setSnackbarOpen(false);
                }, [2000]);
                return;
            }

            setLoading(true);
            const transactionResponse = await initiateTransaction(signer, bnbToPay);

            await saveCryptoPresalePayment(
                bnbRecipientAddress,
                await signer.getAddress(),
                transactionResponse.hash,
                select_price,
                "bnb"
            );
            setLoading(false);
            setIsSuccess(true);
        } catch (error) {
            console.error("Error sending BNB:", error);
            if (error.code === "ACTION_REJECTED") {
                setAlertMessage("Transaction rejected by the user.");
                setSeverity("error");
                setTimeout(() => {
                    setSnackbarOpen(false);
                }, [3000]);
            } else {
                alert(`An error occurred: ${error.message}`);
            }
        } finally {
            setLoading(false);
        }
    };

    // Handle Disconnect Logic
    const handleDisconnect = async () => {
        setLoading(true);
        try {
            await disconnect();
            setIsSuccess(false);
        } catch (error) {
            console.error("Disconnection failed:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isConnected) {
            handleDisconnect();
        }
    }, [paymentMethod]);

    return loading ? (
        <ProcessingAlert />
    ) : (
        <>
            <div style={{textAlign: "center"}}>
                <appkit-button />
                {isConnected && <button onClick={() => handleSendBNB()}>Pay with {paymentMethod.toUpperCase()}</button>}
            </div>
            <SuccessPaymentAlert open={isSuccess} onClose={() => setIsSuccess(false)} />
            <Snackbar
                open={snackbaropen}
                autoHideDuration={5000}
                // onClose={closesnackbar}
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
            >
                <Alert severity={severity}>{alertMessage}</Alert>
            </Snackbar>
        </>
    );
};

export default BNBConnectButton;
