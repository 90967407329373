import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Config, cookieToInitialState, WagmiProvider } from 'wagmi';
import { createAppKit } from '@reown/appkit/react';
import { ethersAdapter, metadata, networks, projectId, solanaWeb3JsAdapter, wagmiAdapter } from './common/appkitConfig.tsx';

const queryClient = new QueryClient();

// Create a context for the selected network
const NetworkContext = createContext<{
  selectedNetwork: 'ethereum' | 'solana';
  setSelectedNetwork: (network: 'ethereum' | 'solana') => void;
} | null>(null);

// Custom hook to use the network context
export const useNetwork = () => {
  const context = useContext(NetworkContext);
  if (!context) {
    throw new Error('useNetwork must be used within a ReownAppKitProvider');
  }
  return context;
};

// Provider Component
export function ReownAppKitProvider({ children, cookies }: { children: ReactNode; cookies: string | null }) {
  const [selectedNetwork, setSelectedNetwork] = useState<'ethereum' | 'solana'>('ethereum');

  const activeAdapters = useMemo(() => {
    console.log("Selected Network:", selectedNetwork);
    return selectedNetwork === 'solana' ? [solanaWeb3JsAdapter] : [ethersAdapter];
  }, [selectedNetwork]);

  const appKit = useMemo(() => createAppKit({
    adapters: [wagmiAdapter],
    networks,
    metadata,
    projectId,
    features: {
      analytics: true,
      socials: [],
      email: false,
    },
  }), [wagmiAdapter]);

  console.log("AppKit Modal:", appKit);

  const initialState = cookieToInitialState(wagmiAdapter.wagmiConfig as Config, cookies);
  if (initialState) {
    console.log("Wallet found in initial state:", initialState);
  }

  return (
    <NetworkContext.Provider value={{ selectedNetwork, setSelectedNetwork }}>
      <WagmiProvider config={wagmiAdapter.wagmiConfig as Config} initialState={initialState}>
        <QueryClientProvider client={queryClient}>
          {children}
        </QueryClientProvider>
      </WagmiProvider>
    </NetworkContext.Provider>
  );
}