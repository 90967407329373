import React, {
    useState,
    useEffect,
    useRef,
    useMemo,
    useCallback,
    lazy,
    Suspense,
    forwardRef 
  } from "react";
  import ClearIcon from "@mui/icons-material/Clear";
  import { components } from "react-select";
  import {ReactComponent as NotificationIcon} from "../pages/account/profile/profile_icon/notification-icon.svg";

  import Select from "react-select";
  import {
    Popover
  } from "@mui/material";
  import MenuItem from "@mui/material/MenuItem";
  import { useParams } from "react-router-dom";
  import Modal from "react-modal";
  import "./CryptoPage.css"; // Assuming you have a CSS file for styling
  import TableCell, { tableCellClasses } from "@mui/material/TableCell";
  import { FaCaretDown } from "react-icons/fa";
  import TableContainer from "@mui/material/TableContainer";
  import TableHead from "@mui/material/TableHead";
  import TableRow from "@mui/material/TableRow";
  import Table from "@mui/material/Table";
  import { styled } from "@mui/material/styles";
  import TableBody from "@mui/material/TableBody";
  import Skeleton from "@mui/material/Skeleton";
  import Stack from "@mui/material/Stack";
  import Box from "@mui/material/Box";
  import json2mq from "json2mq";
  import useMediaQuery from "@mui/material/useMediaQuery";
  import EmailPopUp from "./EmailPopUp.js";
  import Typography from "@mui/material/Typography";
  import { useNavigate } from "react-router-dom";
  import BottomNavigationAction from "@mui/material/BottomNavigationAction";
  import { BottomNavigation, Button } from "@mui/material";
  import SidebarMenuList from "../includes/sidebarMenuList.jsx";
  
  import IconButton from "@mui/material/IconButton";
  import { Grid } from "@mui/material";
  import Drawer from "@mui/material/Drawer";
  import { useLocation, Link } from "react-router-dom";
  import { Helmet } from "react-helmet";
  import LoginPopUp from "./loginpopup";
  import { Tabs, Tab } from "@mui/material";
  import { getCryporCurrencyListHome } from "../apihelper/homepage";
  
  import Dialog from "@mui/material/Dialog";
  import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
  import { Card, TextField } from "@mui/material";
  
  import { DialogTitle, DialogContent, DialogActions } from "@mui/material"; // Import Material-UI components
  
  import { addToPortfolio, copyToPortfolio } from "../apihelper/portfolio";
  
  import CloseIcon from "@mui/icons-material/Close";
  
  import CreatableSelect from "react-select/creatable";
  import {
    getTotalunit,
    getFetchdropdown,
  } from "../apihelper/cryptocurrencylist";
  import Alert from "@mui/material/Alert";
  import Snackbar from "@mui/material/Snackbar";

  const SkeletonLoaderMobile = () => {
    return (
      <div
        style={{
          background: "linear-gradient(180deg, rgba(24, 119, 242, 0.05) 0%, rgba(255, 255, 255, 1) 100%)",
          borderRadius: "12px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          border: "1.5px solid #1877F2",
          margin: "20px 0",
          fontFamily: "Arial, sans-serif",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {/* Forecaster Name */}
        <div
          style={{
            fontWeight: "bold",
            fontSize: "16px",
            marginBottom: "0px",
            color: "#000",
            borderBottom: "4px solid #1877F2",
            padding:"10px"
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "35px",
                height: "35px",
                borderRadius: "50%",
                background: "#ddd",
                marginRight: "8px",
              }}
            ></div>
            <div
              style={{
                width: "120px",
                height: "16px",
                background: "#ddd",
                borderRadius: "5px",
              }}
            ></div>
          </div>
          
        </div>
  
        {/* Content Skeleton */}
        <div style={{ padding: "15px", display: "flex", flexDirection: "column", gap: "10px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "100px", height: "14px", background: "#ddd", borderRadius: "5px" }}></div>
            <div style={{ width: "60px", height: "14px", background: "#ddd", borderRadius: "5px" }}></div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "120px", height: "14px", background: "#ddd", borderRadius: "5px" }}></div>
            <div style={{ width: "80px", height: "14px", background: "#ddd", borderRadius: "5px" }}></div>
          </div>
          <div style={{ width: "140px", height: "14px", background: "#ddd", borderRadius: "5px" }}></div>
        </div>
      </div>
    );
  };
  
  
  

  const SkeletonLoaderMobileList = () => {
    return (
      <>
        {[...Array(5)].map((_, index) => (
          <SkeletonLoaderMobile key={index} />
        ))}
      </>
    );
  };
  

  const MobileForecastCard = forwardRef(({ data }, ref) => {
   
    return (
      <div
      ref={ref}
        style={{
          background: "linear-gradient(180deg, rgba(24, 119, 242, 0.05) 0%, rgba(255, 255, 255, 1) 100%)",
          borderRadius: "12px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          border: "1.5px solid #1877F2",
          margin: "20px 0",
          fontFamily: "Arial, sans-serif",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {/* Forecaster Name */}
        <div
          style={{
            fontWeight: "bold",
            fontSize: "16px",
            marginBottom: "0px",
            color: "#000",
            borderBottom: "4px solid #1877F2",
          }}
        >
          <div style={{
            background:" var(--try-gradient, linear-gradient(90deg, rgba(24, 119, 242, 0.17) 0.02%, rgba(24, 119, 242, 0.00) 123.27%))",
            borderRadius:"20px",
            padding:"7px 10px", display:"flex",
            justifyContent:"space-between"}}>
         <div style={{display:"flex", alignItems:"center"}}> <img
    src={
      data.photo && data.photo !== "https://crowdwisdom.live/wp-content/plugins/forcaster-accuracy-plugin/images/"
        ? data.photo
        : window.constants.asset_path + "/images/avtar.jpeg"
    }
    alt="Forecaster"
    style={{ width: "30px", height: "30px", borderRadius: "50%", marginRight:"5px" }} // Adjust size and shape as needed
  />
                
                {data.forecaster_name}</div>
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
    <img
      src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${data.cmc_id}.png`}
      alt={data.slug}
      style={{ width: "24px", height: "24px" }}
    />
    <div>{data.slug}</div></div>
          </div>
        </div>
  
        {/* Target & % Achieved */}
        <div style={{ display: "flex", justifyContent: "space-between", fontSize: "14px", fontWeight: "500", color: "#000",marginLeft:"10px",marginRight:"10px",marginTop:"10px" }}>
          <span>Target {data.forecast_price}</span>
          <span>%Target Achieved {data.target_percent}</span>
        </div>
  
        {/* Past Accuracy & Year */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "14px",
            fontWeight: "500",
            color: "#000",
            marginTop: "5px",
            marginLeft:"10px",
            marginRight:"10px",
            marginBottom :"10px"
          }}
        >
          <span>Past Accuracy {data.accuracy}</span>
          <span>Forecast For {data.target_date}</span>
        </div>
      </div>
  );
});
  
  
const CustomDropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaCaretDown style={{ color: "black", fontSize: "16px" }} />
    </components.DropdownIndicator>
  );
};

const CustomCheckbox = ({ checked }) => {
  return (
    <div
      style={{
        width: "16px",
        height: "16px",
        borderRadius: "4px",
        border: "2px solid #007bff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: checked ? "#007bff" : "transparent",
      }}
    >
      {checked && (
        <svg width="12" height="12" viewBox="0 0 24 24" fill="none">
          <path
            d="M20 6L9 17l-5-5"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </div>
  );
};

const CustomOption = (props) => {
  const { data, isSelected, innerRef, innerProps } = props;
  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{
        display: "flex",
        alignItems: "center",
        padding: "8px",
        cursor: "pointer",
        backgroundColor: isSelected ? "#e0f0ff" : "white",
        transition: "background 0.2s",
        "&:hover": {
          backgroundColor: "#f0f0f0",
        },
      }}
    >
      <CustomCheckbox checked={isSelected} />
      <span style={{ marginLeft: "8px" }}>{props.data.label}</span>
    </div>
  );
};


 
  
  const drawerWidth = 292;
  const PredictionsPage = (props) => {
    const { window1 } = props;
    const [dropdownVisible, setDropdownVisible] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortType, setSortType] = useState("fd_asc");
    const [sortDropdownVisible, setSortDropdownVisible] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [loader, setLoader] = useState(false);
    const [slug, setSlug] = useState(false);
    const [symbolLogo, setSymbolLogo] = useState(false);
    const [currentPrice, setCurrentPrice] = useState(false);
    const [rowsToShow, setRowsToShow] = useState(4);
    const [selectedSort, setSelectedSort] = useState("");
    const sortRef = useRef();
    const navigate = useNavigate();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const location = useLocation();
    const loc = location.pathname;
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const [isLoginModalOpen, setLoginModalOpen] = useState(false);
    const [crypto, setCrypto] = useState("bitcoin");
    const [sortOrder, setSortOrder] = useState({
      // Track ascending/descending for each column
      forecaster: "asc",
      target: "asc",
      accuracy: "asc",
      percent: "asc",
      targetDate: "asc",
    });




    const dropdownStyle = {
      padding: "10px 15px",
      borderRadius: "8px",
      border: "1px solid #4A90E2", // Blue border
      background: " var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))", // Yellow to Blue Gradient
      color: "#000", // Black text
      fontSize: "14px",
      outline: "none",
      cursor: "pointer",
      appearance: "none", // Hides default dropdown arrow
    };

  
  
    // Render row based on the action color
    const renderAction = (action) => {
      switch (action) {
        case "Buy":
        case "Bullish":
        case "bullish":
        case "Partially Bullish":
        case "Outperformer":
        case "Strong Buy":
          return <span style={{ color: "green", fontWeight: "" }}> {capitalizeFirstLetter(action)}</span>;
  
        case "Sell":
        case "Bearish":
        case "Partially Bearish":
        case "Underperformer":
        case "Strong Sell":
          return <span style={{ color: "red", fontWeight: "" }}> {capitalizeFirstLetter(action)}</span>;
        case "Neutral":
        case "Moderate Performer":
          return <span style={{ color: "orange", fontWeight: "" }}>Neutral</span>;
        default:
          return null;
      }
    };
  
    function capitalizeFirstLetter(text) {
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    }
  
    const mediaQueryVar = useMediaQuery(
      json2mq({
        minWidth: 900,
      }),
    );
  
    function formatDate(dateString) {
      if (!dateString) return ""; // Handle cases where dateString is undefined or null
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0"); // Add leading zero to day
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero to month (0-based index)
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    
    const handleLoginClick = () => {
      // Mask the URL by adding /login at the end without navigating
      window.history.pushState({}, "", "/login");
      localStorage.setItem("isPopupManuallyOpened", "true");
      // Open the login modal
      setLoginModalOpen(true);
    };
    const handleModalClose = () => {
      // Close the modal and reset the URL to the welcome page
      setLoginModalOpen(false);
      navigate(location.pathname, { replace: true });
    };
  
    const mediaQueryVar1 = useMediaQuery(
      json2mq({
        minWidth: 400,
      }),
    );
  
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        border: 0,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: 0,
      },
    }));
  
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      // hide last border
  
      "& td, & th": {
        border: 0,
      },
    }));
  
    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };
  
    const drawer = (
        <div className='d-flex flex-column h-100' style={{overflowY:'hidden'}}>
    <SidebarMenuList/>
        </div>
    );

    
  
    const [predictionLoader, setPredictionLoader] = useState(false);
    
    const [paid_member, setpaid_member] = useState("no");
  
    const container =
      window1 !== undefined ? () => window1().document.body : undefined;
  
    
    const mediaQueryVar2 = useMediaQuery(
      json2mq({
        maxWidth: 420,
      }),
    );
  
    // Function to extract indentation level and style accordingly
    const getIndentationStyle = (htmlString) => {
      const div = document.createElement("div");
      div.innerHTML = htmlString;
  
      const items = div.querySelectorAll("li");
      items.forEach((item) => {
        const match = item.className.match(/ql-indent-(\d+)/);
        if (match) {
          const level = parseInt(match[1], 10);
          // Set padding based on indent level for content, reducing by 10px
          item.style.paddingLeft = `${20 * level - 20}px`; // Adjusted padding
          // Adjust margin for bullet alignment
          item.style.marginLeft = `${40 * level}px`; // Bullet alignment
        }
      });
  
      return div.innerHTML; // Return modified HTML
    };
  
  
    useEffect(() => {
      console.log("mousedown useeefect");
      const handleClickOutside = (event) => {
        if (sortRef.current && !sortRef.current.contains(event.target)) {
          setSortDropdownVisible(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
  
    const toggleDropdown = (index) => {
      setDropdownVisible(dropdownVisible === index ? null : index);
    };
  
    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
    };
  
    const handleSortChange = (sortType) => {
      setSortType(sortType);
      setSortDropdownVisible(false);
      setSelectedSort(sortType);
    };
  
    const openModal = async (data) => {
      if (data.accuracy.toLowerCase() !== "pending") {
        try {
          const response = await fetch(
            "https://crowdwisdom.live/wp-json/api/v1/showForecasterPastData",
            {
              // Replace with your actual API endpoint
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                searchVal: crypto,
                forecasterName: data.forecaster_name,
              }),
            },
          );
  
          const result = await response.json();
          console.log(result);
  
          if (result.code === "200") {
            setModalData(result.response);
            setModalIsOpen(true);
          } else {
            setError(result.message);
          }
        } catch (err) {
          setError("An error occurred while fetching data.");
        }
      }
    };

  const[Loading1, setLoading1] = useState(true);
 
  
    const closeModal = () => {
      setModalIsOpen(false);
      setModalData(null);
    };
  
    const filteredData = data.filter((forecaster) =>
      forecaster.forecaster_name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  
    const handleLoadMore = () => {
      setRowsToShow(rowsToShow + 4);
    };
  
    const [isTableView, setIsTableView] = useState(true);
    const [selectedButton, setSelectedButton] = useState("CoinPrices");
  
    const handleButtonClick = (buttonName) => {
      if (selectedButton === buttonName) {
        return;
      }
  
      // Toggle the view based on the clicked button
      setSelectedButton(buttonName);
      setIsTableView(buttonName === "CoinPrices" ? false : true); // Show table view only if 'CoinPrices' is selected
    };
  
    let url = `/predict-now`;
    const parseDate = (dateStr) => {
      const [month, day, year] = dateStr.split(" ");
      const monthMap = {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12",
      };
      const formattedDate = `${year}-${monthMap[month]}-${day.padStart(2, "0")}`;
      return new Date(formattedDate);
    };
  
    const parseForecastPrice = (price) => {
      return parseFloat(price.replace(/[^0-9.-]+/g, ""));
    };
  
    const parseAccuracy = (accuracy) => {
      if (typeof accuracy !== "string") return NaN; // Return NaN if input is not a string
  
      // Extract the number from the accuracy string
      const percentage = accuracy.split("%")[0].trim(); // Get the part before '%'
      const parsedValue = parseFloat(percentage.split(" ")[0]); // Convert to float
  
      return isNaN(parsedValue) ? 0 : parsedValue; // Return 0 for NaN for safety
    };
  
    const compareValues = (val1, val2, order = "asc") => {
      // If either value is NaN, treat it as less than any valid number
      if (isNaN(val1) && isNaN(val2)) return 0; // Both are NaN
      if (isNaN(val1)) return 1; // Treat NaN as the largest value
      if (isNaN(val2)) return -1; // Treat NaN as the largest value
  
      // Perform comparison based on order
      if (order === "asc") return val1 - val2;
      return val2 - val1;
    };
  
    const handleSort = (column) => {
      const newSortOrder = sortOrder[column] === "asc" ? "desc" : "asc"; // Toggle sort order
      setSortOrder({ ...sortOrder, [column]: newSortOrder });
  
      // Set the sort type based on the column
      switch (column) {
        case "forecaster":
          setSortType(newSortOrder === "asc" ? "fc_asc" : "fc_desc");
          break;
        case "target":
          setSortType(newSortOrder === "asc" ? "tg_asc" : "tg_desc");
          break;
        case "accuracy":
          setSortType(newSortOrder === "asc" ? "ac_asc" : "ac_desc");
          break;
        case "percent":
          setSortType(newSortOrder === "asc" ? "pr_asc" : "pr_desc");
          break;
        case "targetDate":
          setSortType(newSortOrder === "asc" ? "fd_asc" : "fd_desc");
          break;
        default:
          break;
      }
    };
  
    const sortedData = [...filteredData].sort((a, b) => {
      switch (sortType) {
        case "fc_asc":
          return a.forecaster_name.localeCompare(b.forecaster_name);
        case "fc_desc":
          return b.forecaster_name.localeCompare(a.forecaster_name);
        case "tg_asc":
          return (
            parseForecastPrice(a.forecast_price) -
            parseForecastPrice(b.forecast_price)
          );
        case "tg_desc":
          return (
            parseForecastPrice(b.forecast_price) -
            parseForecastPrice(a.forecast_price)
          );
        case "ac_asc": {
          const accuracyA = parseAccuracy(a.accuracy);
          const accuracyB = parseAccuracy(b.accuracy);
          return compareValues(accuracyA, accuracyB, "asc");
        }
        case "ac_desc": {
          const accuracyA = parseAccuracy(a.accuracy);
          const accuracyB = parseAccuracy(b.accuracy);
          return compareValues(accuracyA, accuracyB, "desc");
        }
        case "pr_asc":
          return parseFloat(a.target_percent) - parseFloat(b.target_percent);
        case "pr_desc":
          return parseFloat(b.target_percent) - parseFloat(a.target_percent);
        case "fd_asc":
          return parseDate(a.target_date) - parseDate(b.target_date);
        case "fd_desc":
          return parseDate(b.target_date) - parseDate(a.target_date);
        default:
          return 0;
      }
    });
  
    const [isPredictionDataLoaded, setIsPredictionDataLoaded] = useState(false);
    useEffect(() => {
      console.log("sorteddata");
      if (sortedData && sortedData.length > 0 && !isPredictionDataLoaded) {
        setIsPredictionDataLoaded(true); // ✅ Only update if necessary
      }
    }, [sortedData, isPredictionDataLoaded]); // ✅ Added `isPredictionDataLoaded` as a dependency
    

    // useEffect(() => {
    //   console.log("sorteddata");
    //   if (sortedData && sortedData.length > 0) {
    //     setIsPredictionDataLoaded(true); // Set to true when data is available
    //   }
    // }, [sortedData]);
  
  
    const [data_replacements, setData_replacements] = useState([]);
    const [loading_replacements, setLoading_replacements] = useState(true);
    const [predictionLoader_replacements, setPredictionLoader_replacements] =
      useState(false);
    const [
      isPredictionDataLoaded_replacements,
      setIsPredictionDataLoaded_replacements,
    ] = useState(false);
  
    const [showModal, setShowModal] = useState(false); // Change the state name to showModal
  
    const filteredData1 = data_replacements;
  
    const sortedData1 = [...filteredData1].sort((a, b) => {
      switch (sortType) {
        case "ac_asc":
          return compareValues(a.accuracy, b.accuracy, "asc");
        case "ac_desc":
          return compareValues(a.accuracy, b.accuracy, "desc");
        default:
          return 0;
      }
    });
  
    const [ignoredRows, setIgnoredRows] = useState([]);
  
    const [open, setOpen] = useState(false);
    const fetchDropdownOptions = async () => {
      const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  
      getFetchdropdown({
        user_id: userData.user_id,
  
        Token: userData.token,
      }).then((res) => {
        if (res.code === "200") {
          const newOptions = res.data.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setOptions(newOptions);
          setuser_role(res.user_role);
          //console.log(res.user_role);
        } else {
          console.log("Data fething error");
        }
      });
    };
  
    const handleIgnoreClick = (index) => {
      const updatedIgnoredRows = [...ignoredRows, index];
      setIgnoredRows(updatedIgnoredRows);
  
      // Check if all currently displayed rows are ignored
      const allIgnored = sortedData1
        .slice(0, rowsToShow)
        .every((_, idx) => updatedIgnoredRows.includes(idx));
  
      // If all rows are ignored, call handleLoadMore
      if (allIgnored) {
        handleLoadMore();
      }
    };
  
    const allRowsIgnored = sortedData1
      .slice(0, rowsToShow)
      .every((_, index) => ignoredRows.includes(index));

      useEffect(() => {
        console.log("sorted data 1");
        if (sortedData1 && sortedData1.length > 0 && !isPredictionDataLoaded_replacements) {
          setIsPredictionDataLoaded_replacements(true);
        }
      }, [sortedData1, isPredictionDataLoaded_replacements]);
      
  
    // useEffect(() => {
    //   console.log("sorted data 1");
    //   if (sortedData1 && sortedData1.length > 0) {
    //     setIsPredictionDataLoaded_replacements(true); // Set to true when data is available
    //   }
    // }, [sortedData1]);
  
    const [unit, setUnit] = useState("");
    const [selectedOption, setSelectedOption] = useState([]);
    const [price, setPrice] = useState("");
    const [totalUnits, setTotalUnits] = useState(0);
    const [selectedDate, handleDateChange] = useState("");
    const [Usablewallet, setUsablewallet] = useState("");
    const [portfolioTypeId, setPortfolioTypeId] = useState("");
    const handleChange = async (selectedOption, slug) => {
      if (selectedOption && selectedOption._isNew_) {
        setIsDialogOpen(true);
        console.log("Creating new option:", selectedOption.value);
        setSelectedOption(null);
        setdataslug(slug);
      } else if (!selectedOption) {
        // Handle the case when the selection is cleared
        setSelectedOption(null);
        setShowAlert(false);
        // Perform any other necessary actions
        return;
      } else {
        const selectedValue = selectedOption.value;
        if (selectedValue.includes("-")) {
          setErrorMessage("Hyphens are not allowed in the option."); // Set error message
          setShowAlert(true); // Show the alert
          return; // Abort further processing
        }
        setShowAlert(false);
        setSelectedOption(selectedOption);
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        getTotalunit({
          user_id: userData.user_id,
          slug: slug, // Assuming value contains the slug
          portfolio_type_id: selectedOption.value, // Adjust as needed
          Token: userData.token,
        }).then((res) => {
          if (res.code === "200") {
            setTotalUnits(res.total_uint);
            setUsablewallet(res.wallet_amount);
          } else {
            console.log("Data fething error");
          }
          // console.log(slugdata)
        });
      }
    };
    useEffect(() => {
      console.log("show Modal");
      if (open || showModal) {
        fetchDropdownOptions();
      }
    }, [open, showModal]);
    const handleCreate = async (inputValue) => {
      setTotalUnits(0);
      setUsablewallet("");
  
      if (options.find((option) => option.value === inputValue)) {
        return; // Exit early if the option already exists
      }
      const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
      //console.log(inputValue);
      const newOption = { value: inputValue, label: inputValue };
      const newOptions = [...options, newOption]; // Create a new array with the existing options plus the new one
      setOptions(newOptions); // Update the options state
      const createdOption = newOptions.find(
        (option) => option.value == inputValue,
      );
      // console.log(createdOption);
      if (createdOption) {
        handleChange(createdOption);
      }
    };
    const [showAlert, setShowAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const formatToNonZero = (number) => {
      number = parseFloat(number);
  
      if (number >= 1) {
        return number.toFixed(2);
      } else if (number >= 0.1) {
        return number.toFixed(1);
      } else if (number >= 0.01) {
        return number.toFixed(4);
      } else if (number >= 0.001) {
        return number.toFixed(5);
      } else if (number >= 0.0001) {
        return number.toFixed(6);
      } else if (number >= 0.00001) {
        return number.toFixed(7);
      } else if (number >= 0.000001) {
        return number.toFixed(8);
      } else if (number >= 0.0000001) {
        return number.toFixed(9);
      } else if (number >= 0.00000001) {
        return number.toFixed(10);
      } else if (number >= 0.000000001) {
        return number.toFixed(11);
      }
      return number.toFixed(1);
    };
    const [buySell, setbuySell] = React.useState("");
    const handleClick = (buySell) => {
      setSnackbaropen(true);
      setOpen(true);
      setSeverity("info");
      setIsFormSubmitted(false);
      // setSnackbarcolor("success");
      // setSnackbarmessage("Data saved successfully");
      setMsgforUpdatePortfolio("loading...");
      //console.log(price, selectedDate, buySell, unit);
      const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
      const portfolioId =
        selectedOption && !isNaN(parseInt(selectedOption.value))
          ? parseInt(selectedOption.value)
          : 0;
      const existingPortfolio = portfolioId > 0 ? "yes" : "no"; // Determine existing_portfolio based on portfolioId
  
      addToPortfolio({
        portfolio_type_id: portfolioId,
        portfolio_name: selectedOption ? selectedOption.label : "", // Ensure selectedOption is defined
  
        existing_portfolio: existingPortfolio,
        user_id: userData.user_id,
        slug: slugpopupdata.slugname,
        unit: unit,
        acquisition_price: price,
        date_time: selectedDate,
        type: buySell,
        Token: userData.token,
      }).then((res) => {
        if (res.code === "200") {
          // setOpen(false);
          setSeverity("info");
          //console.log(res.message);
          setMsgforUpdatePortfolio(res.message);
          setPortfolioTypeId(res.portfolio_type_id);
          setIsFormSubmitted(true);
          // navigate(
          //   `/my-portfolio/${res.portfolio_type_id}/${encodeURIComponent(
          //     selectedOption.label
          //   )}`
          // );
        } else {
          setSeverity("error");
          // setOpen(true)
          setMsgforUpdatePortfolio(res.message);
        }
      });
    };
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [newOptionValue, setNewOptionValue] = useState("");
    const handleDialogSubmit = () => {
      // Handle submitting the dialog (e.g., creating the new option)
      console.log("Creating new option:", newOptionValue);
  
      // Update options state with the new option
      const newOption = { value: newOptionValue, label: newOptionValue };
      setOptions([...options, newOption]);
      setSelectedOption(newOption);
      // Close the dialog
      setIsDialogOpen(false);
  
      // setSelectedOption(selectedOption);
      const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
      getTotalunit({
        user_id: userData.user_id,
        slug: dataslug, // Assuming value contains the slug
        portfolio_type_id: newOption.value, // Adjust as needed
        Token: userData.token,
      }).then((res) => {
        if (res.code === "200") {
          setTotalUnits(res.total_uint);
          setNewOptionValue("");
          setUsablewallet(res.wallet_amount);
        } else {
          console.log("Data fething error");
        }
        // console.log(slugdata)
      });
    };
    const [snackbaropen, setSnackbaropen] = React.useState(false);
    const closesnackbar = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
  
      setSnackbaropen(false);
    };
    const [severity, setSeverity] = useState("info");
    const [msgforUpdatePortfolio, setMsgforUpdatePortfolio] =
      useState("loading...");
    const handleClose = () => setOpen(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [dataslug, setdataslug] = useState("");
    const [options, setOptions] = useState([]);
    const handleChange2 = (e) => {
      const value = e.target.value;
      // Check if the input contains a hyphen
      if (value.includes("-")) {
        setShowAlert(true); // Show the alert if there's a hyphen
      } else {
        setShowAlert(false); // Hide the alert if there's no hyphen
        setNewOptionValue(value); // Update the state with the new value
      }
    };
    const [slugdata, setSlugdata] = useState([]);
    const [user_role, setuser_role] = useState("");
    const [slugpopupdata, setSlugpopupdata] = useState({
      slugname: "",
      slug: "",
      units: 0,
    });
  
    const handleAddToPortfolio = (idx) => {
      console.log("IDX value is : ", idx);
  
      getCryporCurrencyListHome({
        slug: idx,
      }).then((res) => {
        if (res.code === "200") {
          console.log("data got successfully", res.data);
  
          // Update slugdata
          setSlugdata(res.data);
          setuser_role(res.user_role);
  
          // Use res.data directly here
          const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
          if (userData) {
            console.log("User DAta is there");
            setSlugpopupdata({
              slugname: res.data[0].slug,
              slug: res.data[0].name,
              units: res.data[0].total_unit,
              pricetoday: res.data[0].price_today,
              logo: res.data[0].logo,
            });
            console.log("Slug Popup Data : ", res.data);
            setOpen(true);
          } else {
            window.history.pushState({}, "", "/login");
            setLoginModalOpen(true);
          }
        } else {
          console.log("Data fetching error");
        }
      });
    };
  
    const [selectedFilter, setSelectedFilter] = useState(null);
    const handleClickROIHL = () => {
      setSortType('roi_desc');
    };
    const handleClickROILH = () => {
      setSortType('roi_asc');
    };
    const handleClearAll = () => {
      setSortType('roi_asc');
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const openfilter = Boolean(anchorEl);
  
    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleCloseeoption = () => {
      setAnchorEl(null);
    };

  const [selectedInfluencer, setSelectedInfluencer] = useState([]);
  const [selectedCrypto, setSelectedCrypto] = useState([]);
  
  const [selectedAccuracy, setSelectedAccuracy] = useState([
  ]);
  const [selectedYear, setSelectedYear] = useState([]);

  const[forecasters, setForecasters] = useState([]);
  const[Cryptos, setCryptos] = useState([]);

  const accuracyOptions = ["<30%","30-60%",">60%"].map((item) => ({
    value: item,
    label: item,
  }));;

  const yearOptions = ["2025","2026","2027","2028","2029","2030"].map((item) => ({
    value: item,
    label: item,
  }));;

  const dropdowns = [
    { label: mediaQueryVar? "Influencer Name" : "Influencer", value: selectedInfluencer, setValue: setSelectedInfluencer, options: forecasters, width: mediaQueryVar ? "220px" : "150px", height:"40px" },
    { label: "Crypto", value: selectedCrypto, setValue: setSelectedCrypto, options: Cryptos, width: mediaQueryVar? "170px" : "140px",height:"40px" },
    { label: "Accuracy", value: selectedAccuracy, setValue: setSelectedAccuracy, options: accuracyOptions, width:mediaQueryVar? "170px" : "150px",height:"40px" },
    { label: "Year", value: selectedYear, setValue: setSelectedYear, options: yearOptions, width: mediaQueryVar?  "140px" : "140px",height:"40px" },
  ];

  // Remove selected filter
  const removeFilter = (setValue, item) => {
    setValue((prev) => prev.filter((selected) => selected.value !== item.value));
  };


  const fetchDistinctCryptos = async () => {
    try {
      const response = await fetch(
        "https://crowdwisdom.live/wp-json/api/v1/getDistinctCryptos",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      const result = await response.json();
      console.log("Cryptos Data:", result);
  
      if (result.code === "200") {
        setCryptos(
          result.data.map((item) => ({
            value: item.name,  // Assuming API response has "name"
            label: item.name,
            cmc_id: item.cmc_id,  //Storing cmc_id
           
            image: `https://s2.coinmarketcap.com/static/img/coins/64x64/${item.cmc_id}.png`,
          }))
        );
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError("An error occurred while fetching categories.");
    } finally {
      setLoading1(false);
    }
  };
  

    useEffect(() => {
      console.log("crypto fetch");
        fetchDistinctCryptos();
      }, []);


      const fetchDistinctInfluencers = async () => {
        try {
            const response = await fetch(
                "https://crowdwisdom.live/wp-json/api/v1/getDistinctInfluencerNames",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
    
            const result = await response.json();
            console.log("Influencers Data:", result);
            
            if (result.code === "200") {
              setForecasters(result.data.map((item) => ({ 
                  value: item.name, 
                  label: item.name,
                  image: item.photo && item.photo !== "https://crowdwisdom.live/wp-content/plugins/forcaster-accuracy-plugin/images/"
                      ? item.photo 
                      : window.constants.asset_path + "/images/avtar.jpeg" // Corrected default avatar image
              })));
          }
          else {
                setError(result.message);
            }
        } catch (err) {
            setError("An error occurred while fetching categories.");
        } finally {
            setLoading1(false);
        }
    };
    
  
      useEffect(() => {
        console.log("influencer fetch");
        fetchDistinctInfluencers();
        }, []);


    useEffect(() => {

      console.log("Main ersult");

    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const userId = userData?.user_id || null; // Send null if userId is unavailable

    console.log("Hello userid:", userId || "Guest");

    const fetchData = async () => {
        console.log("Selected Crypto:", selectedCrypto.map(c => c.value).join(", "));
        console.log("Selected Influencer:", selectedInfluencer.map(i => i.value).join(", "));
        console.log("Selected Accuracy:", selectedAccuracy.map(a => a.value).join(", "));
        console.log("Selected Year:", selectedYear.map(y => y.value).join(", "));

        try {
            const response = await fetch(
                "https://crowdwisdom.live/wp-json/api/v1/showForecasterPredictions",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      searchVals: selectedCrypto.map(c => c.value),  
                      influencers: selectedInfluencer.map(i => i.value),
                      minAccuracy: selectedAccuracy.map(a => a.value), // Convert to int
                      year: selectedYear.map(y => y.value), // Convert to int
                      sortType: sortType,
                      userId: userId,
                  }),
                  
                }
            );

            const result = await response.json();
            console.log("API Response:", result);

            if (result.code === "200") {
                if (result.response.length === 0) {
                    setPredictionLoader(true);
                }
                setLoader(true);
                setSlug(result.symbol);
                setCurrentPrice(result.current_price);
                setSymbolLogo(result.symbol_logo);
                setData(result.response);
                setpaid_member(result.paid_member);
            } else {
                setError(result.message);
            }
        } catch (err) {
            setError("An error occurred while fetching data.");
        } finally {
            setLoading(false);
        }
    };

    fetchData();
}, [selectedCrypto, selectedInfluencer, selectedAccuracy, selectedYear, sortType]); // Dependency array includes all selected filters

const SkeletonLoader = () => (
  <>
    {Array.from({ length: 5 }).map((_, index) => (
      <tr key={index} style={{ background: "#fff", border: "1px solid #1877F2", borderRadius: "10px", marginBottom: "10px", padding: "6px", height: "60px" }}>
        {Array.from({ length: 5 }).map((_, idx) => (
          <td
            key={idx}
            style={{
              padding: "12px",
              textAlign: "center",
              fontSize: "14px",
              border: "1px solid #1877F2",
              borderRadius: idx === 0 ? "20px 0 0 20px" : idx === 4 ? "0 20px 20px 0" : "0",
              borderLeft: idx === 0 ? "none" : "",
              borderRight: idx === 4 ? "none" : "",
            }}
          >
            <div className="skeleton-box" />
          </td>
        ))}
      </tr>
    ))}
  </>
);
    
const [visibleData, setVisibleData] = useState([]);
const [rowCount, setRowCount] = useState(10); // Initial count
const observerRef = useRef(null);
const lastRowRef = useRef(null);

useEffect(() => {
  console.log("API Response received, updating visibleData"); // ✅ Debugging

  if (sortedData.length > 0) {
    // ✅ Update only if `visibleData` is actually different
    const newVisibleData = sortedData.slice(0, rowCount);
    
    if (JSON.stringify(newVisibleData) !== JSON.stringify(visibleData)) {
      setVisibleData(newVisibleData);
    }
  } else if (visibleData.length > 0) {
    setVisibleData([]); // Clear `visibleData` only if it's not already empty
  }
}, [sortedData, rowCount]); // ✅ Runs only when `sortedData` or `rowCount` changes



// // Load initial data
// useEffect(() => {
//   console.log("sorted data 3");
//   if (sortedData.length > 0) {
//     setVisibleData(sortedData.slice(0, rowCount));
//   }
// }, [sortedData]);

// Function to load more data
const loadMoreRows = useCallback(() => {
  if (rowCount >= sortedData.length) return; // Stop if all rows are loaded

  setTimeout(() => {
    setVisibleData((prevData) => [
      ...prevData,
      ...sortedData.slice(prevData.length, prevData.length + 10), // Slice dynamically
    ]);
    setRowCount((prevCount) => prevCount + 10);
  }, 500);
}, [sortedData]);


useEffect(() => {
  if (!visibleData.length || rowCount >= sortedData.length) return;

  observerRef.current  = new IntersectionObserver(
    (entries) => {
      if (entries[0].isIntersecting) {
        console.log("Load more triggered"); // ✅ Debug log
        loadMoreRows();
      }
    },
    { rootMargin: "100px" } // ✅ Triggers before reaching bottom
  );

  if (lastRowRef.current) observerRef.current.observe(lastRowRef.current);
  return () => {
  if (observerRef.current) observerRef.current.disconnect();
};
}, [visibleData, rowCount, sortedData]);

const [unreadAlerts, setUnreadAlerts] = useState(0);
    useEffect(() => {
    
                const userId = userData?.user_id || null;
    
                const fetchAlertsCount = async () => {
                  try {
                    const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/crypto_alerts_count', {
                      method: "POST", // Use POST instead of GET
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({ user_id: userId }), // Send user_id in the body
                    });
              
                    if (!response.ok) {
                      throw new Error(`Error: ${response.status}`);
                    }
              
                    const data = await response.json();
                    console.log(data);
                    setUnreadAlerts(data.unread_notifications);    
    
                  } catch (error) {
                    console.error("Failed to fetch alerts:", error);
                  } finally {
                  }
                };
              
                fetchAlertsCount();
              }
              , []);



// // Intersection Observer Setup
// useEffect(() => {
//   console.log("visible data");
//   if (!visibleData.length || rowCount >= sortedData.length) return;

//   observerRef.current = new IntersectionObserver(
//     (entries) => {
//       if (entries[0].isIntersecting) {
//         loadMoreRows();
//       }
//     },
//     { rootMargin: "100px" } // Trigger before reaching bottom
//   );

//   if (lastRowRef.current) {
//     observerRef.current.observe(lastRowRef.current);
//   }

//   return () => {
//     if (observerRef.current) observerRef.current.disconnect();
//   };
// }, [visibleData, rowCount]);

  
    return (
      <div className="crypto-page" style={{padding:mediaQueryVar? 0:"10px",margin: 0, backgroundColor: mediaQueryVar? "#F2F2F2" : "#fff" }}>
        <Helmet>
          <title>
          Crypto Influencer Price Predictions
          </title>
          <meta
            name="description"
            content={`Accurate ${crypto.charAt(0).toUpperCase() + crypto.slice(1)} Influencer Price Predictions & In-depth ${crypto.charAt(0).toUpperCase() + crypto.slice(1)} Price Predictions.`}
          />
        </Helmet>
  
        <Modal
          isOpen={open}
          onRequestClose={handleClose}
          overlayClassName={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black overlay
              backdropFilter: "blur(5px)", // Optional: Add a blur effect to the overlay
            },
          }}
          className={{
            content: {
              position: "relative",
              backgroundColor: "white", // Set background for inner popup
              padding: "20px", // Add padding for content
              borderRadius: "8px", // Rounded corners
              maxWidth: mediaQueryVar ? "100%" : "100%", // Set a max width for popup
              margin: "0", // Center the popup horizontally
              boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)", // Optional: Popup shadow
            },
          }}
        >
          <div className="CardOpener" style={{ overflow: "hidden" }}>
            {isFormSubmitted ? (
              <div
                style={{
                  position: "fixed", // Fix the popup to the top of the viewport
                  top: 0, // Align it to the top
                  left: 0,
                  width: "100%",
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional: darken the background for emphasis
                  zIndex: 1000, // High z-index to overlay other elements
                }}
              >
                <Card className="card__container">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CheckCircleOutlineIcon sx={{ fontSize: 50, mr: 1 }} />
                    </div>
                    <Typography
                      severity="success"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Portfolio added successfully!
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginTop: "10px",
                        width: "100%",
                      }}
                    >
                      <Button
                        onClick={() => {
                          setIsFormSubmitted(false);
                          setOpen(false);
                          setUnit("");
                          setSelectedOption("");
                          setPrice("");
                          setTotalUnits(0);
                          handleDateChange("");
                          setUsablewallet("");
                        }}
                        color="primary"
                        style={{
                          backgroundColor: "rgba(67, 97, 238, 0.15)",
                          borderRadius: "6px",
                          border: "none",
                          textTransform: "none",
                          borderStyle: "solid",
                          borderColor: "rgba(67, 97, 238, 0.15)",
                          borderWidth: "1px",
                          color: "#4361ee",
                          whiteSpace: "nowrap",
                          float: "inline-end",
                          width: mediaQueryVar === true ? "150px" : "100px",
                          height: mediaQueryVar === false ? "30px" : "",
                          fontSize: mediaQueryVar === false ? "10px" : "11px",
                        }}
                      >
                        Add More Cryptos
                      </Button>
                      <Button
                        onClick={() =>
                          navigate(
                            `/my-portfolio/${portfolioTypeId}/${encodeURIComponent(
                              selectedOption.label.replace(/\s+/g, "-"),
                            )}`,
                          )
                        }
                        variant="outlined"
                        style={{
                          backgroundColor: "rgba(67, 97, 238, 0.15)",
                          borderRadius: "6px",
                          border: "none",
                          textTransform: "none",
                          borderStyle: "solid",
                          borderColor: "rgba(67, 97, 238, 0.15)",
                          borderWidth: "1px",
                          color: "#4361ee",
                          whiteSpace: "nowrap",
                          float: "inline-end",
                          width: mediaQueryVar === true ? "100px" : "100px",
                          height: mediaQueryVar === false ? "30px" : "",
                          fontSize: mediaQueryVar === false ? "10px" : "11px",
                        }}
                        color="primary"
                      >
                        View Portfolio
                      </Button>
                    </div>
                  </div>
                </Card>
              </div>
            ) : (
              <Dialog
                PaperProps={{
                  sx: {
                    position: mediaQueryVar ? "static" : "fixed",
                    bottom: mediaQueryVar ? "" : 0,
                    left: mediaQueryVar ? "" : 0,
                    right: mediaQueryVar ? "" : 0,
                    marginLeft: mediaQueryVar ? "" : "0px",
                    marginRight: mediaQueryVar ? "" : "0px",
                    marginTop: "1%",
                    maxWidth: "100%",
                    minHeight: mediaQueryVar ? "60%" : "", // Adjust max height if needed
                    borderRadius: mediaQueryVar ? "24px" : "24px 24px 0 0",
                    marginBottom: mediaQueryVar ? "" : "0px",
                    padding: mediaQueryVar ? "10px" : "1px",
                    alignItems: "center",
                    overflow: "scroll",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                    "-ms-overflow-style": "none",
                    "scrollbar-width": "none", // Ensure no scrollbar
                  },
                }}
                open={open}
                onClose={handleClose}
              >
                <div
                  style={{
                    padding: "20px",
                    width: mediaQueryVar ? "400px" : "100%",
                    margin: "0",
                    marginTop: "-5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                      borderBottom: "1px #E3E3E3 solid",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: "20.767px",
                        paddingLeft: "100px",
                      }}
                      gutterBottom
                    >
                      Add To Portfolio
                    </Typography>
  
                    <div className="primaryButton">
                      <img
                        className="frameIcon"
                        alt=""
                        onClick={handleClose}
                        src={window.constants.asset_path + "/images/frame17.svg"}
                        style={{
                          background: "#F3F3F3",
                          width: "25px",
                          height: "25px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "5px",
                      marginLeft: mediaQueryVar === false ? "10px" : "",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "13.845px",
                        fontWeight: "500",
                        marginBottom: "1px",
                        color: "black",
                        marginTop: "-8px",
                      }}
                    >
                      Select Portfolio
                    </Typography>
                    <div style={{ marginTop: "5px" }}>
                      <CreatableSelect
                        isClearable
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderRadius: "12px",
                            border: "1px solid #1877F2",
                            boxShadow: "none",
                            minHeight: "40px",
                            "&:hover": {
                              borderRadius: "12px",
                              border: "2px solid #1877F2",
                            },
                          }),
                          menu: (provided) => ({
                            ...provided,
                            borderRadius: "8px",
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: "",
                          }),
                          dropdownIndicator: (provided) => ({
                            ...provided,
                            color: "#1877F2",
                            border: "1px solid #1877F2",
                            padding: "5px",
                            margin: "8px",
                            borderRadius: "3.786px",
                            "&:hover": {
                              color: "#1877F2",
                            },
                          }),
                          indicatorSeparator: (provided) => ({
                            display: "none",
                          }),
                        }}
                        placeholder=""
                        onChange={(options) =>
                          handleChange(options, slugpopupdata.slugname)
                        }
                        onCreateOption={handleCreate}
                        options={[
                          {
                            value: "",
                            label: "Create new portfolio",
                            _isNew_: true,
                          }, // Dynamic "create new option" item
                          ...options, // Existing options
                        ]}
                        value={selectedOption}
                      />
                    </div>
                    {showAlert && (
                      <Alert severity="error" sx={{ marginBottom: "10px" }}>
                        {errorMessage}
                      </Alert>
                    )}
                  </div>
                  <Typography
                    style={{
                      fontSize: "13.845px",
                      fontWeight: "500",
                      color: "#000",
                      marginTop: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    Usable Wallet Value: {Usablewallet}
                  </Typography>
                  <div
                    style={{
                      padding: mediaQueryVar ? "8px" : "5px",
                      background: "#F3F3F3",
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "5px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flex: 0,
                        minWidth: 0,
                      }}
                    >
                      <img
                        style={{
                          marginRight: "10px",
                          border: "2px solid #000",
                          borderRadius: "19px",
                          height: "27.69px",
                          width: "27.69px",
                        }}
                        alt=""
                        src={slugpopupdata.logo}
                      />
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          sx={{
                            fontWeight: "500",
                            marginBottom: "0px",
                            fontSize: "14px",
                            color: "#7F7F80",
                          }}
                        >
                          {slugpopupdata.slugname}
                        </Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginLeft: "20%",
                        flex: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "500",
                          marginBottom: "4px",
                          fontSize: "13px",
                          color: "#7F7F80",
                        }}
                      >
                        {" "}
                        Price
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12.114px",
                          fontWeight: "500",
                          color: "black",
                          margin: "0",
                          marginLeft: "5%",
                        }}
                      >
                        {slugpopupdata.pricetoday}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginLeft: "20px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "500",
                          marginBottom: "4px",
                          fontSize: "13px",
                          color: "#7F7F80",
                        }}
                      >
                        {" "}
                        Units
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12.114px",
                          fontWeight: "500",
                          color: "black",
                          margin: "0",
                        }}
                      >
                        {totalUnits}
                      </Typography>
                    </div>
                  </div>
                  <div className="card__container__row3">
                    <div style={{ marginLeft: "-5px" }}>
                      <p style={{ fontSize: "14px", fontWeight: "500" }}>
                        Enter number of Units
                      </p>
                      <input
                        style={{
                          backgroundColor: "white",
                          color: "black",
                          padding: "20px",
                          border: "1px solid #1877F2",
                          borderRadius: "10.384px",
                          width: mediaQueryVar ? "350px" : "100%",
                          height: "45px",
                          margin: 0,
                        }}
                        min="1"
                        max="10"
                        type="number"
                        placeholder=""
                        value={unit}
                        onChange={(e) => setUnit(e.target.value)}
                      ></input>
                    </div>
                    <div>
                      $
                      {formatToNonZero(
    unit *
      parseFloat(
        (slugpopupdata.pricetoday || "")
          .replace(/^\$/, "") // Remove the dollar sign
          .replace(/,/g, ""), // Remove commas
      ),
  )}
  
                    </div>
                    {user_role === "superuser" && (
                      <>
                        <div className="card__container__row3__r2">
                          <div style={{ marginLeft: "-5px" }}>
                            <p style={{ fontSize: "14px", fontWeight: "500" }}>
                              Buy/Sell Price($)
                            </p>
                            <input
                              style={{
                                backgroundColor: "white",
                                color: "black",
                                padding: "20px",
                                border: "1px solid #1877F2",
                                borderRadius: "10.384px",
                                width: "345px",
                                height: "45px",
                              }}
                              className="card__container__row3__r2__input"
                              min="1"
                              max="10"
                              type="number"
                              placeholder="Enter Price"
                              value={price}
                              onChange={(e) => setPrice(e.target.value)}
                            ></input>
                          </div>
                        </div>
                        <div style={{ marginBottom: "10px", marginLeft: "-5px" }}>
                          <p style={{ fontSize: "14px", fontWeight: "500" }}>
                            Date
                          </p>
                          <TextField
                            type="date"
                            value={selectedDate}
                            onChange={(e) => handleDateChange(e.target.value)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "10.384px",
                                border: "1px solid #1877F2",
                                boxShadow: "none",
                                width: "190%",
                                height: "45px",
                              },
                            }}
                          />
                        </div>
                      </>
                    )}
                    <div
                      className="card__container__row3__r4"
                      style={{ marginTop: "10px" }}
                    >
                      <Button
                        className={
                          buySell === "buy"
                            ? "buttonToggleButActive"
                            : "buttonToggleBuy"
                        }
                        value="Buy"
                        style={{
                          lineHeight: "20px",
                          width: mediaQueryVar ? "159px" : "130px",
                          borderRadius: "27px",
                          border: "1px solid #1877F2",
                          padding: mediaQueryVar ? "15px 85px" : "13px 75px",
                          marginLeft: "-6px",
                          backgroundColor: buySell === "buy" ? "" : "#1877F2",
                          color: buySell === "buy" ? "" : "white",
                          textTransform: "none",
                          fontSize: "18px",
                          fontWeight: "700",
                        }}
                        onClick={() => handleClick("buy")}
                      >
                        Buy
                      </Button>
                      <Button
                        className={
                          buySell === "sell"
                            ? "buttonToggleSellActive"
                            : "buttonToggleSell"
                        }
                        value="sell"
                        style={{
                          lineHeight: "20px",
                          width: mediaQueryVar ? "159px" : "130px",
                          borderRadius: "27px",
                          border: "1px solid #1877F2",
                          marginLeft: "10px",
                          padding: mediaQueryVar ? "15px 85px" : "13px 75px",
                          backgroundColor: buySell === "sell" ? "" : "#1877F2",
                          color: buySell === "sell" ? "" : "white",
                          textTransform: "none",
                          fontSize: "18px",
                          fontWeight: "700",
                        }}
                        onClick={() => handleClick("sell")}
                      >
                        Sell
                      </Button>
                    </div>
                  </div>
                </div>
              </Dialog>
            )}
          </div>
        </Modal>
  
        <Dialog
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            Create New Portfolio
            <IconButton
              aria-label="close"
              onClick={() => {
                setIsDialogOpen(false);
                setNewOptionValue("");
              }}
              sx={{ position: "absolute", right: 13, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div>
              <TextField
                autoFocus
                label="Enter Name"
                value={newOptionValue}
                onChange={handleChange2}
                fullWidth
                margin="dense"
              />
              {showAlert && (
                <Alert severity="error" sx={{ marginTop: "8px" }}>
                  Hyphens are not allowed in the name.
                </Alert>
              )}
            </div>
          </DialogContent>
          <DialogActions sx={{ marginRight: "14px" }}>
            <Button
              onClick={() => {
                setIsDialogOpen(false);
                setNewOptionValue("");
              }}
              sx={{ fontWeight: "bold" }}
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={handleDialogSubmit}
              variant="contained"
              color="primary"
              sx={{ fontWeight: "bold" }}
              disabled={!newOptionValue.trim()}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
  
        <Snackbar
          open={snackbaropen}
          autoHideDuration={5000}
          onClose={closesnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity={severity}>{msgforUpdatePortfolio}</Alert>
        </Snackbar>
  
        <Grid item xs={12} align="left">
        {mediaQueryVar ? null : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between", // Changed from space-between to flex-end
              marginTop: "0px",
              marginLeft: "5px",
              fontSize:"20px"
            }}
          >
           
            
    <Typography style={{fontSize:"20px", fontWeight:"700"}}> Price Predictions </Typography>
    <div style={{display:"flex", alignItems:"center"}}>
                                   <div className="notification-section position-relative" onClick={() => navigate("/alerts")}>
                                        <NotificationIcon className="notification-icon fa-2x" />
                                        <span className="badge rounded-pill badge-notification bg-danger position-absolute top-0 start-100 ">
                                              {unreadAlerts}
                                        </span>
                                    </div>
                                  
    <IconButton
      color="inherit"
      aria-label="open drawer"
      edge="end"
      onClick={handleDrawerToggle}
      sx={{ display: { sm: "none" } }}
    >
      <img
        src="/images/menubar.svg"
        alt="Menu Icon"
        style={{
          width: "24px",
          height: "24px",
          marginRight: "10px",
          marginTop: "10px",
        }}
      />
    </IconButton>
    </div> 
          
          </div> 
        )}
        </Grid>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "400",
              backgroundColor: "white",
              color: "#727376",
            },
          }}
        >
          {drawer}
        </Drawer>
        {mediaQueryVar ? null : (
          <div style={{display:"flex", justifyContent:"space-between"}}>
          <Box
            sx={{
              backgroundColor: "#1877F2", // Background color for the container
              padding: "3.105px", // Padding inside the container
              borderRadius: "38.042px", // Border radius for the container
              display: "flex", // Ensure that the box is flexible
              alignItems: "center", // Center the content vertically
              display: "inline-block",
              minWidth: isPredictionDataLoaded
                ? mediaQueryVar2
                  ? "300px"
                  : "300px"
                : mediaQueryVar2
                  ? "300px"
                  : "300px",
  
              height: "37px",
              border: "none",
              marginLeft: "0px",
              marginTop: "2%",
              marginBottom: "10px",
            }}
          >
            <Tabs
              value={selectedButton}
              onChange={(event, newValue) => handleButtonClick(newValue)}
              aria-label="basic tabs example"
              sx={{
                flexGrow: 1, // Make the tabs take up remaining space
                "& .MuiTabs-indicator": {
                  display: "none",
                },
                display: "flex", // Ensure that the tabs are displayed in a row
                overflow: "hidden", // Prevent the tabs from spilling out of the border radius
              }}
            >
              <Tab
                label={
                  isPredictionDataLoaded
                    ? `Price Predictions`
                    : "Price Predictions"
                }
                value="CoinPrices"
                sx={{
                  whiteSpace: "nowrap",
                  borderRadius: "20.962px",
                  minHeight: "31px",
                  height: "31px",
                  marginLeft: "1.5px",
                  marginTop: "0.2px",
                  border: "1px solid black",
                  fontWeight: selectedButton === "CoinPrices" ? "700" : "500",
                  marginRight: "0px",
                  fontSize: mediaQueryVar2 ? "13px" : "15px",
                  paddingRight: "-8px",
                  textTransform: "none",
                  backgroundColor:
                    selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // White background for selected tab
                  color:
                    selectedButton === "CoinPrices"
                      ? "#000000 !important"
                      : "#FFFFFF", // Black text for selected tab
                  border: "none", // Remove border for tabs
                }}
              />
              <Tab
                label="Predict and Win"
                value="PortfolioGallery"
                component={Link}
                to={url}
                sx={{
                  whiteSpace: "nowrap",
                  borderRadius: "20.962px",
                  minHeight: "31.5px",
                  height: "31.5px",
                  fontWeight:
                    selectedButton === "PortfolioGallery" ? "700" : "500",
                  marginLeft: "0.5px",
                  paddingLeft: "-15px",
                  marginTop: "0.5px",
                  border: "1px solid black",
                  marginBottom: "-5px",
                  fontSize: mediaQueryVar2 ? "13px" : "15px",
                  marginRight: "-10px",
                  backgroundColor:
                    selectedButton === "PortfolioGallery"
                      ? "#FFFFFF"
                      : "transparent", // White background for selected tab
                  color:
                    selectedButton === "PortfolioGallery"
                      ? "#000000 !important"
                      : "#FFFFFF", // Black text for selected tab
                  textTransform: "none",
                  border: "none",
                }}
              />
            </Tabs>
           
          </Box>
          <div>
                    <Box
                        sx={{
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "flex-end",
                          marginBottom: "0px",
                          color:"#1877F2",
                          marginLeft:"-10px"
                        }}
                      >
                        <Button
                          onClick={handlePopoverOpen}
                        >
                          
                          <div style={{
                          padding:"6px 2px"
                          }}>
                          <img width="23px"
                          src={window.constants.asset_path + '/images/filter2.svg'}/></div>
                        </Button>
            
                        <Popover
                          open={openfilter}
                          anchorEl={anchorEl}
                          onClose={handleCloseeoption}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          style={{
                           
                          }}
                        >
                         <MenuItem
                            selected={selectedFilter === "fc_asc"}
                            onClick={() => handleSort("forecaster")}
                          >
                           Forecaster {sortOrder.forecaster === "asc" ? "↑" : "↓"}
                          </MenuItem>
                          
                          <MenuItem
                            selected={selectedFilter === "tg_asc"}
                            onClick={() => handleSort("target")}
                          >
                            Target {sortOrder.target === "asc" ? "↑" : "↓"}
                          </MenuItem>
                          <MenuItem
                            selected={selectedFilter === "ac_asc"}
                            onClick={() => handleSort("accuracy")}
                          >
                            Accuracy {sortOrder.accuracy === "asc" ? "↑" : "↓"}
                          </MenuItem>
                          
            
                          <MenuItem
                            selected={selectedFilter === "pr_asc"}
                            onClick={() => handleSort("percent")}
                          >
                            Achieved  {sortOrder.percent === "asc" ? "↑" : "↓"}
                          </MenuItem>
                          <MenuItem
                            selected={selectedFilter === "fd_asc"}
                            onClick={() => handleSort("targetDate")}
                          >
                            Target Date {sortOrder.targetDate === "asc" ? "↑" : "↓"}
                          </MenuItem>
                
                
                          
                        
            
                          <Box sx={{ display: "flex", padding: "8px" }}>
                            <Button
                              size="small"
                              sx={{ textTransform: "capitalize", marginLeft: "10px" }}
                              onClick={handleClearAll}
                              startIcon={<ClearIcon />}
                            >
                              Clear All
                            </Button>
                          </Box>
                        </Popover>
            
                        
                      </Box>
            
              </div>
              </div>
          
        )}
  
        {mediaQueryVar ? (
          <div className="layout">
            <div className>
              <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
              >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                  container={container}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                  sx={{
                    display: { xs: "block", sm: "none" },
                    "& .MuiDrawer-paper": {
                      boxSizing: "border-box",
                      width: drawerWidth,
                      backgroundColor: "white",
                      color: "#727376",
                    },
                  }}
                >
                  {drawer}
                </Drawer>
                <Drawer
                  variant="permanent"
                  sx={{
                    display: { xs: "none", sm: "block" },
                    "& .MuiDrawer-paper": {
                      boxSizing: "border-box",
                      width: drawerWidth,
                      backgroundColor: "white",
                      color: "#727376",
                    },
                  }}
                  open
                >
                  {drawer}
                </Drawer>
              </Box>
            </div>
  
            <div
              className="content"
              style={{
                margin: "0%",
                padding: "10px",
              }}
            >
              <div style={{
                display:"flex",
                justifyContent:"flex-start",
                alignItems:"center"
              }}>
              <Box
                sx={{
                  backgroundColor: "#1877F2", // Background color for the container
                  paddingTop: { xs: "10px", sm: "4px" }, // Padding inside the container
                  paddingLeft: { xs: "0px", sm: "0px" },
                  paddingRight: { xs: "0px", sm: "0px" },
                  paddingBottom: { xs: "0px", sm: "4px" },
                  borderRadius: "50px", // Border radius for the container
                  display: "flex", // Make sure the box is flexible
                  justifyContent: "flex-start", // Center the content
                  alignItems: "center", // Center the content
                  display: "inline-block",
                  minWidth: isPredictionDataLoaded ? "350px" : "350px",
                  height: { xs: "44px", sm: "48px" },
                  border: "none",
                  marginLeft: "0.3%",
                  marginTop: "10px",
                  marginBottom:"3%"
                }}
              >
                <Tabs
                  value={selectedButton}
                  onChange={(event, newValue) => handleButtonClick(newValue)}
                  aria-label="basic tabs example"
                  sx={{
                    alignSelf: mediaQueryVar === true ? "flex-start" : "",
                    marginLeft: mediaQueryVar === true ? "2px" : "2px",
                    borderRadius: "50px", // Adjust border radius for more rounded corners
                    border: "none",
                    "& .MuiTabs-indicator": {
                      display: "none",
                    },
                    display: "flex", // Make sure the tabs are displayed in a row
                    overflow: "hidden", // Prevent the tabs from spilling out of the border radius
                  }}
                >
                  <Tab
                    label={
                      isPredictionDataLoaded
                        ? `Price Predictions`
                        : "Price Predictions"
                    }
                    value="CoinPrices"
                    sx={{
                      whiteSpace: "nowrap",
                      bottom: "0px",
                      marginTop: "0px",
                      marginBottom: "0px",
                      marginLeft: "3px",
                      marginRight: "3px",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      minHeight: "20px",
                      lineHeight: { xs: "36px", sm: "36px" },
                      borderRadius:
                        selectedButton === "CoinPrices" ? "50px" : "50px", // Rounded corners for selected tab
                      textTransform: "none",
                      fontSize: "17px",
                      fontStyle: "normal",
                      fontWeight: selectedButton === "CoinPrices" ? "700" : "500",
  
                      backgroundColor:
                        selectedButton === "CoinPrices"
                          ? "#FFFFFF"
                          : "transparent", // White background for selected tab
                      color:
                        selectedButton === "CoinPrices"
                          ? "#000000 !important"
                          : "#FFFFFF", // Black text for selected tab
                      border: "none", // Remove border for tabs
                      background:
                        selectedButton === "CoinPrices"
                          ? "#FFFFFF"
                          : "transparent", // Blue background for unselected tab
                    }}
                  />
                  <Tab
                    label="Predict and Win"
                    value="PortfolioGallery"
                    component={Link}
                    to={url}
                    sx={{
                      whiteSpace: "nowrap",
                      bottom: "0px",
                      marginTop: "1.1px",
                      marginBottom: "0px",
                      marginLeft: "3px",
                      marginRight: "3px",
                      paddingBottom: "2px",
                      paddingTop: "0px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      minHeight: "20px",
                      lineHeight: { xs: "28px", sm: "36px" },
                      borderRadius:
                        selectedButton === "PortfolioGallery" ? "20px" : "20px", // Rounded corners for selected tab
                      textTransform: "none",
                      fontSize: { xs: "13px", sm: "17px" },
                      fontStyle: "normal",
                      fontWeight:
                        selectedButton === "PortfolioGallery" ? "700" : "500",
  
                      backgroundColor:
                        selectedButton === "PortfolioGallery"
                          ? "#FFFFFF"
                          : "transparent", // White background for selected tab
                      color:
                        selectedButton === "PortfolioGallery"
                          ? "#000000 !important"
                          : "#FFFFFF", // Black text for selected tab
                      border: "none", // Remove border for tabs
                      background:
                        selectedButton === "PortfolioGallery"
                          ? "#FFFFFF"
                          : "transparent", // Blue background for unselected tab
                    }}
                  />
                </Tabs>
                
              </Box>
             
              <div 
  className="notification-section position-absolute" 
  style={{ right: "20px" }}  
  onClick={() => navigate("/alerts")}
>
  <NotificationIcon className="notification-icon fa-2x" />
  <span className="badge rounded-pill badge-notification bg-danger position-absolute top-0 start-100">
    {unreadAlerts}
  </span>
</div>

                                    
              </div> 
              
  
              
              
              
              <div>
      {/* Multi-Select Dropdowns */}
      <div style={{ display: "flex", justifyContent: "center", gap: "30px", padding: "10px", color:"black" }}>
        {dropdowns.map((dropdown, index) => (
          <div key={index} style={{ width: dropdown.width , height: dropdown.height}}>
   <Select
            options={dropdown.options}
            isMulti
            placeholder={dropdown.label}
            value={dropdown.value} // The selected values
            onChange={(selectedOptions) => {
              dropdown.setValue(selectedOptions);
            }}
            
            closeMenuOnSelect={false} // Keep dropdown open
            hideSelectedOptions={false} // Keep selected options in the list
            styles={{
              dropdownIndicator: (base) => ({
                ...base,
                color: "black",
              }),
              indicatorSeparator: (base) => ({
                ...base,
                display: "none",
              }),
              control: (base) => ({
                ...base,
                background: "linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%)",
                border: "2px solid #79A7FF",
                borderRadius: "10px",
                padding: "2px",
                fontSize: "14px",
                fontWeight: "bold",
                color: "#000",
                cursor: "pointer",
              }),
              menu: (base) => ({
                ...base,
                background: "#fff",
                borderRadius: "10px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                color: "#000",
              }),
              multiValue: (base) => ({
                ...base,
                display: "none", // Hide selected values inside the input field
              }),
              valueContainer: (base, state) => {
                const showLabel = !state.selectProps.inputValue; // Hide label while typing
            
                return {
                  ...base,
                  padding: "4px",
                  position: "relative",
                  ":before": showLabel
                    ? {
                        content: `"${dropdown.label}"`,
                        color: "#000",
                        fontWeight: "bold",
                        fontSize: "14px",
                        position: "absolute",
                        left: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }
                    : {},
                };
              },
              placeholder: (base) => ({
                ...base,
                display: "none", // Hide default placeholder since we use :before
              }),
            }}
            components={{ Option: CustomOption }}
          
         
/>
</div>
        ))}
      </div>

      {/* Selected Filters */}
      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", marginTop: "15px" }}>
        {dropdowns.map((dropdown, index) =>
          dropdown.value.map((item) => (
            <div
              key={`${index}-${item.value}`}
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#fff",
                color: "#000",
                borderRadius: "10px",
                padding: "5px 10px",
                margin: "5px",
                fontWeight: "bold",
                border:"1px solid #1877F2"
              }}
            >


        {(dropdown.label === "Crypto" || dropdown.label === "Influencer Name") && item.image && (
         <img
         src={item.image?.trim() ? item.image : window.constants.asset_path + "/images/avtar.jpeg"}
         alt={item.label}
         style={{ width: "30px", height: "30px", marginRight: "8px", borderRadius: "50%" }}
       />
       
        )}

              {item.label}
             
            </div>
          ))
        )}
      </div>
    </div>


  <div
    style={{
      padding: "15px 15px",
      fontFamily: "Satoshi, sans-serif",
      margin: "15px 5px",
    }}
  >
     <style>
      {`
        @keyframes shimmer {
          0% { background-position: -200px 0; }
          100% { background-position: 200px 0; }
        }
        .skeleton-box {
          width: 80%;
          height: 20px;
          background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
          background-size: 200px 100%;
          border-radius: 4px;
          animation: shimmer 1.2s infinite linear;
        }
      `}
    </style>
    <table
      style={{
        width: "100%",
        borderCollapse: "separate",
        borderSpacing: "0 10px",
      }}
    >
      <thead>
        <tr
          style={{
            background:
              "linear-gradient(180deg, rgba(24, 119, 242, 0.11) -1.89%, rgba(24, 119, 242, 0.00) 60.38%)",
            borderBottom: "5px solid #1877F2",
          }}
        >
          <th
            style={{
              padding: "10px",
              textAlign: "left",
              fontWeight: "bold",
              fontSize: "16px",
              color: "#000",
              cursor:"pointer"
            }}
            onClick={() => handleSort("forecaster")}
          >
            Forecaster {sortOrder.forecaster === "asc" ? "↑" : "↓"}
          </th>
          <th
            style={{
              padding: "10px",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "16px",
              color: "#000",
              cursor:"pointer"
            }}
            onClick={() => handleSort("target")}
          >
            Target {sortOrder.target === "asc" ? "↑" : "↓"}
          </th>
          <th
            style={{
              padding: "10px",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "16px",
              color: "#000",
              cursor:"pointer"
            }}
            onClick={() => handleSort("accuracy")}
          >
            Past Accuracy {sortOrder.accuracy === "asc" ? "↑" : "↓"}
          </th>
          <th
            style={{
              padding: "10px",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "16px",
              color: "#000",
              cursor:"pointer"
            }}
            onClick={() => handleSort("percent")}
          >
            % Target Achieved {sortOrder.percent === "asc" ? "↑" : "↓"}
          </th>
          <th
            style={{
              padding: "10px",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "16px",
              color: "#000",
              cursor:"pointer"
            }}
            onClick={() => handleSort("targetDate")}
          >
            Forecast For {sortOrder.targetDate === "asc" ? "↑" : "↓"}
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>

      {loading ? <SkeletonLoader /> : 
            sortedData.length==0 ? (
              <tr>
              <td colSpan="5" style={{
                textAlign: "center",
                padding: "20px",
                fontSize: "16px",
                fontWeight: "bold",
                border: "2px solid #1877F2",
                borderRadius: "10px",
                background: "#f8f9fa",
                color: "#1877F2"
              }}>
               No Predictions Available
              </td>
            </tr>
            ):(

              visibleData.map((data, index) => (
          <tr
            key={index}
            ref={index === visibleData.length - 1 ? lastRowRef : null}
            style={{
              background: "#fff",
              borderRadius: "10px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              marginBottom: "10px",
              border: "2px solid #1877F2",
            }}
          >
            <td
              style={{
                border: "1px solid #1877F2",
                borderTopLeftRadius: "20px",
                borderBottomLeftRadius: "20px",
                borderRight: "none",
                padding: "10px",
              }}
            >
              <div
                style={{
                  padding: "10px",
                  fontWeight: "bold",
                  fontSize: "16px",
                  color: "#000",
                  background:
                    "linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%)",
                  borderRadius: "10px",
                  textAlign: "left",
                  minWidth: "220px",
                  display:"flex",
                  justifyContent:"space-between"
                }}
              >
               <div style={{display:"flex", alignItems:"center"}}> <img
    src={
      data.photo && data.photo !== "https://crowdwisdom.live/wp-content/plugins/forcaster-accuracy-plugin/images/"
        ? data.photo
        : window.constants.asset_path + "/images/avtar.jpeg"
    }
    alt="Forecaster"
    style={{ width: "30px", height: "30px", borderRadius: "50%", marginRight:"5px" }} // Adjust size and shape as needed
  />
                
                {data.forecaster_name}</div>
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
    <img
      src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${data.cmc_id}.png`}
      alt={data.slug}
      style={{ width: "24px", height: "24px" }}
    />
    <div>{data.slug}</div></div>
              </div>
            </td>
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                fontWeight: "500",
                fontSize: "14px",
                color: "#000",
                border: "1px solid #1877F2",
                borderLeft: "none",
                borderRight: "none",
                padding: "10px",
              }}
            >
              {data.forecast_price}
            </td>
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                fontWeight: "500",
                fontSize: "14px",
                color: "#000",
                border: "1px solid #1877F2",
                borderLeft: "none",
                borderRight: "none",
                padding: "10px",
              }}
            >
              {data.accuracy}
            </td>
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                fontWeight: "500",
                fontSize: "14px",
                color: "#000",
                border: "1px solid #1877F2",
                borderLeft: "none",
                borderRight: "none",
                padding: "10px",
              }}
            >
              {data.target_percent}
            </td>
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                fontWeight: "500",
                fontSize: "14px",
                color: "#000",
                border: "1px solid #1877F2",
                borderLeft: "none",
                borderTopRightRadius:"20px",
                borderBottomRightRadius:"20px",
                padding: "10px",
              }}
            >
              {data.target_date}
            </td>
          </tr>

          
        )))}
      </tbody>
      
      </table>
      { loading && (<>
      <div style={{
        width: "40px",
        height: "40px",
        border: "4px solid rgba(24, 119, 242, 0.3)",
        borderTop: "4px solid #1877F2",
        borderRadius: "50%",
        animation: "spin 1s linear infinite",
        margin: "auto"
      }}></div>
      
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style></>
    )}
            </div>
            </div>
            </div>
        ) : (

              <>
              <div style={{ padding: "10px" }}>
              {/* Multi-Select Dropdowns */}
              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "15px", justifyContent: "center" }}>
                {dropdowns.map((dropdown, index) => (
                     <div key={index} style={{ width: dropdown.width , height: dropdown.height}}>
                     <Select
                              options={dropdown.options}
                              isMulti
                              placeholder={dropdown.label}
                              value={dropdown.value} // The selected values
                              onChange={(selectedOptions) => {
                                dropdown.setValue(selectedOptions);
                              }}
                              
                              closeMenuOnSelect={false} // Keep dropdown open
                              hideSelectedOptions={false} // Keep selected options in the list
                              styles={{
                                dropdownIndicator: (base) => ({
                                  ...base,
                                  color: "black",
                                }),
                                indicatorSeparator: (base) => ({
                                  ...base,
                                  display: "none",
                                }),
                                control: (base) => ({
                                  ...base,
                                  background: "linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%)",
                                  border: "2px solid #79A7FF",
                                  borderRadius: "10px",
                                  padding: "2px",
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  color: "#000",
                                  cursor: "pointer",
                                }),
                                menu: (base) => ({
                                  ...base,
                                  background: "#fff",
                                  borderRadius: "10px",
                                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                  color: "#000",
                                }),
                                multiValue: (base) => ({
                                  ...base,
                                  display: "none", // Hide selected values inside the input field
                                }),
                                valueContainer: (base, state) => {
                                  const showLabel = !state.selectProps.inputValue; // Hide label while typing
                              
                                  return {
                                    ...base,
                                    padding: "4px",
                                    position: "relative",
                                    ":before": showLabel
                                      ? {
                                          content: `"${dropdown.label}"`,
                                          color: "#000",
                                          fontWeight: "bold",
                                          fontSize: "14px",
                                          position: "absolute",
                                          left: "10px",
                                          top: "50%",
                                          transform: "translateY(-50%)",
                                        }
                                      : {},
                                  };
                                },
                                placeholder: (base) => ({
                                  ...base,
                                  display: "none", // Hide default placeholder since we use :before
                                }),
                              }}
                              components={{ Option: CustomOption }}
                            
                           
                  />
                  </div>
                ))}
              </div>
        
                {/* Selected Filters */}
                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", marginTop: "15px" }}>
                {dropdowns.map((dropdown, index) =>
                  dropdown.value.map((item) => (
                    <div
                      key={`${index}-${item.value}`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#fff",
                        color: "#000",
                        borderRadius: "10px",
                        padding: "5px 10px",
                        margin: "5px",
                        fontWeight: "bold",
                        border:"1px solid #1877F2"
                      }}
                    >
        
        
                {(dropdown.label === "Crypto" || dropdown.label === "Influencer Name") && item.image && (
                <img
                src={item.image?.trim() ? item.image : window.constants.asset_path + "/images/avtar.jpeg"}
                alt={item.label}
                style={{ width: "30px", height: "30px", marginRight: "8px", borderRadius: "50%" }}
              />
              
                )}
        
                      {item.label}
                      
                    </div>
                  ))
                )}
              </div>
              </div>

              {loading ? ( <SkeletonLoaderMobileList />) : sortedData.length === 0 ? (
                    
                <div
                  style={{
                    border: "1px solid #1877F2",
                    margin: "0px 2%",
                    textAlign: "center",
                    color: "#1877F2",
                    fontSize: "18px",
                    backgroundColor: "white",
                    padding: "2%",
                    borderRadius: "8px",
                    marginBottom: "50%",
                  }}
                >
                  No Predictions available
                </div>
              ) : (
                


              <div style={{ padding: "10px", marginBottom:"60px" }}>
              {visibleData.map((data, index) => (
              <MobileForecastCard key={index} 
              data={data}
              ref={index === visibleData.length - 1 ? lastRowRef : null} />
              ))}

{ loading && (<>
  <div style={{
        width: "40px",
        height: "40px",
        border: "4px solid rgba(24, 119, 242, 0.3)",
        borderTop: "4px solid #1877F2",
        borderRadius: "50%",
        animation: "spin 1s linear infinite",
        margin: "auto",
        marginBottom: "30px"
      }}></div>
      
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style></>
      )}
              </div>

              
                )}
              </>
                )}
        
         
      
  
        
        {mediaQueryVar === false && (
          <div
            style={{
              position: "fixed",
              bottom: "0",
              backgroundColor: "white",
              width: "105%",
              zIndex: "100",
              marginBottom: "0px",
              marginLeft: "-25px",
            }}
          >
            <BottomNavigation showLabels>
              <BottomNavigationAction
                label="Top Portfolios"
                onClick={() => navigate("/portfolio-gallery")}
                icon={
                  <img
                    src={
                      loc === "/portfolio-gallery"
                        ? "/images/dashboardS.svg"
                        : "/images/dashboardU.svg"
                    }
                    width={"20px"}
                    height={"20px"}
                  />
                }
              />
              <BottomNavigationAction
                label="Crypto Screener"
                onClick={() => navigate("/crypto-screener")}
                icon={
                  <img
                    src={
                      loc === "/crypto-screener"
                        ? "/images/portfolioS.svg"
                        : "/images/portfolioU.svg"
                    }
                    width={"20px"}
                    height={"20px"}
                  />
                }
              />
             <BottomNavigationAction
              label="Indices"
              onClick={() => {
                navigate("/indices");
              }}
              icon={
                <img
                  src={
                    "/images/dollarU.svg" 
                  }
                  width={24}
                  height={24}
                  alt="Icon"
                  style={{ marginBottom: "-3px" }}
                />
              }
            />
            </BottomNavigation>
          </div>
        )}
        {isLoginModalOpen && (
          <LoginPopUp
            isLoginModalOpen={isLoginModalOpen}
            setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
            onClose={handleModalClose}
          />
        )}
        <Modal
          style={{
            overlay: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1000,
              marginLeft: mediaQueryVar === true ? "20%" : "",
            },
            content: {
              align: "center",
              width: "80%",
              marginBottom: "20%",
              height: "auto",
              borderRadius: "8px",
              position: "center",
            },
          }}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Accuracy Details"
        >
          <img
            className="frameIcon"
            alt=""
            onClick={closeModal}
            src="/images/frame17.svg"
            style={{
              background: "#F3F3F3",
              width: "25px",
              height: "25px",
              marginTop: "1.5%",
              marginBottom: "1%",
            }}
          />
          <h4>Past Accuracy</h4>
          {modalData ? (
            <table className="modal-table">
              <thead>
                <tr>
                  <th>Published On</th>
                  <th>Forecast Date</th>
                  <th style={{ textAlign: "center" }}>Forecast Price</th>
                  <th style={{ textAlign: "center" }}>Accuracy</th>
                </tr>
              </thead>
              <tbody>
                {modalData.map((detail, index) => (
                  <tr key={index}>
                    <td>{detail.publish_date}</td>
                    <td>{detail.target_date} 11:59 PM</td>
                    <td style={{ textAlign: "center" }}>
                      {detail.forecast_price}
                    </td>
                    <td style={{ textAlign: "center" }}>{detail.accuracy}%</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No data available</p>
          )}
        </Modal>
      </div>
    );
  };
  
  export default PredictionsPage;
  