import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import { CheckCircle, TrendingUp, TrendingDown, AlertCircle, Briefcase, Pin, Eye, EyeOff } from "lucide-react";
import SidebarMenuList from "../includes/sidebarMenuList.jsx";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {addToPortfolio, copyToPortfolio} from "../apihelper/portfolio";
import {DialogTitle, DialogContent, DialogActions} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import CreatableSelect from "react-select/creatable";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import {Grid} from "@mui/material";
import Box from "@mui/material/Box";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import {BottomNavigation, Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {getCryporCurrencyListHome} from "../apihelper/homepage";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import {Card, TextField} from "@mui/material";
import Modal from "react-modal";
import {getTotalunit, getFetchdropdown} from "../apihelper/cryptocurrencylist";
import LoginPopUp from "./loginpopup";
import {useLocation} from "react-router-dom";
import {ReactComponent as HomeIcon} from "../pages/account/profile/profile_icon/home-icon.svg";
import {ReactComponent as SearchIcon} from "../pages/account/profile/profile_icon/search-icon.svg";
import {ReactComponent as PredictionPageIcon} from "../pages/account/profile/profile_icon/prediction-page-icon.svg";
import {ReactComponent as AuditPortfolioIcon} from "../pages/account/profile/profile_icon/audit-portfolio-icon.svg";
import {ReactComponent as GalleryIcon} from "../pages/account/profile/profile_icon/gallery-icon.svg";
import {ReactComponent as Investicon} from "../pages/account/profile/profile_icon/invest-icon.svg";
import {ReactComponent as SearchWhiteIcon} from "../pages/account/profile/profile_icon/search-white-icon.svg";
import {ReactComponent as NotificationIcon} from "../pages/account/profile/profile_icon/notification-icon.svg"
import {ReactComponent as ProfileIcon} from "../pages/account/profile/profile_icon/profile-icon.svg";
import {Helmet} from "react-helmet";
const drawerWidth = 292;
const API_URL = "https://crowdwisdom.live/wp-json/api/v1/crypto_alerts"; // Replace with your actual API URL

const TabHook = ({ activeTab, tabList }) => {
  return (
      <div
          style={{
              display: "flex",
              borderRadius: "50px",
              overflow: "hidden",
              backgroundColor: "rgb(3 130 255)",
              padding: "5px",
          }}
      >
          {tabList.map((tab, index) => (
              <a
                  key={index}
                  href={tab.url} 
                  target="_self"  // Ensures proper link behavior
                  rel="noopener noreferrer"  // Security best practice
                  style={{
                      textDecoration: "none",
                      whiteSpace: "nowrap",
                      padding: "0px 20px",
                      marginLeft: "3px",
                      minHeight: "20px",
                      lineHeight: "36px",
                      borderRadius: "50px",
                      textTransform: "none",
                      fontWeight: activeTab === index ? "700" : "500",
                      fontSize: "16px",
                      backgroundColor: activeTab === index ? "#FFFFFF" : "transparent",
                      color: activeTab === index ? "#000000" : "#FFFFFF",
                      border: "none",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                  }}
              >
                  {tab.title}
              </a>
          ))}
      </div>
  );
};


const CryptoAlerts = (props) => {
  const { window1 } = props;
  // Sample alerts
  const sampleAlerts = [
    { id: 1, portfolio: "Crypto Shadow January Portfolio", coin: "BTC", message: "Whales have Turned Bullish", alertType: "bullish", read: false, pinned: false },
    { id: 2, portfolio: "Coincodex portfolio", coin: "ETH", message: "Whales have Turned Bearish", alertType: "bearish", read: false, pinned: false },
    { id: 3, portfolio: "Crypto GEM Portfolio", coin: "DOGE", message: "Social Media have Turned Bullish", alertType: "bullish", read: false, pinned: false },
    { id: 4, portfolio: "GBV Captial Portfolio", coin: "SOL", message: "Social Media have Turned Bearish", alertType: "bearish", read: false, pinned: false },
    { id: 5, portfolio: "Crypto Benny Shadow Portfolio", coin: "ADA", message: "is now an Outperformer", alertType: "outperformer", read: false, pinned: false },
    { id: 6, portfolio: "Solid AI shadow Portfolio", coin: "XRP", message: "is now an Underperformer", alertType: "underperformer", read: false, pinned: false },
  ];

  const [alerts, setAlerts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [unreadAlerts, setUnreadAlerts] = useState(0);
  

  // Function to toggle read/unread state
  const toggleRead = (id) => {
    setAlerts((prev) =>
      prev.map((alert) => (alert.id === id ? { ...alert, read: !alert.read } : alert))
    );
  };

  // Function to toggle pin state
  const togglePin = (id) => {
    setAlerts((prev) =>
      prev
        .map((alert) => (alert.id === id ? { ...alert, pinned: !alert.pinned } : alert))
        .sort((a, b) => b.pinned - a.pinned) // Pinned alerts come first
    );
  };

  // Alert styles
  const alertStyles = {
    bullish: { background: "#e8f5e9", borderLeft: "6px solid #2e7d32", color: "#2e7d32" },
    bearish: { background: "#ffebee", borderLeft: "6px solid #c62828", color: "#c62828" },
    outperformer: { background: "#e3f2fd", borderLeft: "6px solid #1565c0", color: "#1565c0" },
    underperformer: { background: "#fff3e0", borderLeft: "6px solid #ef6c00", color: "#ef6c00" },
    normal: { background: "#e8f5e9", borderLeft: "6px solid #2e7d32", color: "#2e7d32"},
    neutral : { background: "#fff3e0", borderLeft: "6px solid #ef6c00", color: "#ef6c00" },
  };

  
  const drawer = (
          <div className='d-flex flex-column h-100' style={{overflowY:'hidden'}}>
      <SidebarMenuList/>
          </div>
      );

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  
  const loc = location.pathname;
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
      const handleModalClose = () => {
          // Close the modal and reset the URL to the welcome page
          setLoginModalOpen(false);
          navigate(location.pathname, {replace: true});
      };

      


      const container =
      window1 !== undefined ? () => window1().document.body : undefined;
       const mediaQueryVar = useMediaQuery(
            json2mq({
              minWidth: 900,
            }),
          );

          const handleLoginClick = () => {
                  if (!userData) {
                    const originalUrl = "/alerts";
                    sessionStorage.setItem("originalUrl", originalUrl);
           
                    setIsLoading(false);
                      window.history.pushState({}, "", "/login");
                      localStorage.setItem("isPopupManuallyOpened", "true");
                      setLoginModalOpen(true);
                  } 
              };

              const observerTarget = useRef(null);

        
          useEffect(() => {

          
            const userId = userData?.user_id || null;
            const cameFromAddToPortfolio = sessionStorage.getItem("loginFromAddToPortfolio");


            const fetchAlerts = async () => {
              try {
                const response = await fetch(API_URL, {
                  method: "POST", // Use POST instead of GET
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({ user_id: userId }), // Send user_id in the body
                });
          
                if (!response.ok) {
                  throw new Error(`Error: ${response.status}`);
                }
          
                const data = await response.json();
                console.log(data);
                setAlerts(data.response); // Assuming alerts are in `response`
                setUnreadAlerts(data.unread_notifications);

                const storedData = JSON.parse(sessionStorage.getItem("slugDataForPortfolio"));
                    if (storedData) {
                        setSlugpopupdata(storedData);
                        setOpen(true);
                    }
                    // Clear after restoring
                    sessionStorage.removeItem("loginFromAddToPortfolio");
                    sessionStorage.removeItem("slugDataForPortfolio");

                if (userId) {
                  const markReadResponse = await fetch("https://crowdwisdom.live/wp-json/api/v1/mark-read", {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ user_id: userId }),
                  });
          
                  if (!markReadResponse.ok) {
                    throw new Error(`Error marking notifications as read: ${markReadResponse.status}`);
                  }
          
                  setUnreadAlerts(0);
                }
          
              } catch (error) {
                console.error("Failed to fetch alerts:", error);
              } finally {
                setIsLoading(false);
              }
            };
            
          
            fetchAlerts();
          
          }, []);

         

       const headerTabOptions = [
                {
                    id: 1,
                    text: "Go To Home Page",
                    route: "/",
                    icon: <HomeIcon />,
                    color: "#FFB45C",
                },
                {
                    id: 2,
                    text: "Crypto Screener",
                    route: "/crypto-screener",
                    icon: <SearchIcon />,
                    color: "#D2FD8B",
                },
                {
                    id: 3,
                    text: "Price Predictions",
                    route: "/crypto-predictions",
                    icon: <PredictionPageIcon />,
                    color: "#FFE144",
                },
                {
                    id: 4,
                    text: "Audit My Portfolio",
                    route: "/audit-my-portfolio",
                    icon: <AuditPortfolioIcon />,
                    color: "#48CEFF",
                },
            ];

       const profileTabList = [
              {
                  title: (
                    <div style={{width: "140px",display:"flex",alignItems:"center", justifyContent:"center"}}>
                    <NotificationIcon style={{ width: "25px", height: "25px" , paddingRight:"5px"}} /> My Alerts
                </div>
                
                  ),
                  url: "/alerts",
              },
              {
                  title: (
                      <div style={{width: "140px",display:"flex",alignItems:"center", justifyContent:"center"}}>
                          <AuditPortfolioIcon style={{ width: "35px", height: "35px" , paddingRight:"5px"}}  /> My Watchlist
                      </div>
                  ),
                  url: "/my-portfolio",
              },
              {
                  title: (
                      <div style={{width: "140px",display:"flex",alignItems:"center", justifyContent:"center"}}>
                          <SearchWhiteIcon style={{ width: "25px", height: "25px" , paddingRight:"5px"}} /> Crypto Screener
                      </div>
                  ),
                  url: "/crypto-screener",
              },
          ];
       const profileTabMobileList = [
            {
              title: (
                <div style={{width: "60px",display:"flex",alignItems:"center", justifyContent:"center"}}>
                <NotificationIcon style={{ width: "20px", height: "20px" , paddingRight:"2px"}} /> Alerts
            </div>
            
              ),
              url: "/alerts",
          },
          {
              title: (
                  <div style={{width: "60px",display:"flex",alignItems:"center", justifyContent:"center"}}>
                      <AuditPortfolioIcon style={{ width: "30px", height: "30px" , paddingRight:"2px"}}  /> Watchlist
                  </div>
              ),
              url: "/my-portfolio",
          },
          {
              title: (
                  <div style={{width: "60px",display:"flex",alignItems:"center", justifyContent:"center"}}>
                      <SearchWhiteIcon style={{ width: "20px", height: "20px" , paddingRight:"2px"}} /> Screener
                  </div>
              ),
              url: "/crypto-screener",
          },
          ];
          
          const [visibleCount, setVisibleCount] = useState(10);
          
          const [isFetchingMore, setIsFetchingMore] = useState(false);
  
        
          

          const loadMore = () => {
            setVisibleCount((prevCount) => prevCount + 10);
          }; 

          const [searchQuery, setSearchQuery] = useState("");
  
         

  const [sortOrder, setSortOrder] = useState("desc"); // Default to descending order

          // Sorting function (toggle between ascending and descending)
  const handleSort = () => {
    setSortOrder((prevOrder) => (prevOrder === "desc" ? "asc" : "desc"));
  };

  const [filteredAlerts, setFilteredAlerts] = useState([]);

  useEffect(() => {
    if (!alerts) return;
  
    const updatedAlerts = alerts
      .filter((alert) =>
        [alert.coin, alert.message, alert.portfolio, alert.created_at.formatted].some(
          (field) =>
            field && field.toLowerCase().includes(searchQuery.toLowerCase())
        )
      )
      .sort((a, b) => {
        return sortOrder === "asc"
          ? a.created_at.timestamp - b.created_at.timestamp // Ascending
          : b.created_at.timestamp - a.created_at.timestamp; // Descending
      });
  
    setFilteredAlerts(updatedAlerts);
  }, [alerts, searchQuery, sortOrder]);
  

  useEffect(() => {

    if(filteredAlerts.length ===0) return;
    const observer = new IntersectionObserver(
      (entries) => {
        const first = entries[0];
  
        // Only load more if not fetching and more items are left
        if (
          first.isIntersecting &&
          !isFetchingMore &&
          visibleCount < alerts.length
        ) {
          setIsFetchingMore(true); // Show spinner
          console.log("Fetching more...");
  
          setTimeout(() => {
            setVisibleCount((prev) => prev + 10);
            setIsFetchingMore(false); // Hide spinner
          }, 500);
        }
      },
      { threshold: 1 }
    );
  
    const currentTarget = observerTarget.current;
    if (currentTarget) {
      observer.observe(currentTarget);
    }
  
    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, [alerts, isFetchingMore, visibleCount,filteredAlerts]);
  // add isFetchingMore dependency
  
  
        
//add to portfolio
const [showModal, setShowModal] = useState(false);
const handleClose = () => setOpen(false);
const [isFormSubmitted, setIsFormSubmitted] = useState(false);
const [modalIsOpen, setModalIsOpen] = useState(false);
const [modalData, setModalData] = useState(null);
const [slugdata, setSlugdata] = useState([]);
const [open, setOpen] = useState(false);
const [options, setOptions] = useState([]);
const [unit, setUnit] = useState("");
    const [selectedOption, setSelectedOption] = useState([]);
    const [price, setPrice] = useState("");
    const [totalUnits, setTotalUnits] = useState(0);
    const [selectedDate, handleDateChange] = useState("");
    const [Usablewallet, setUsablewallet] = useState("");
    const [portfolioTypeId, setPortfolioTypeId] = useState("");
    const handleChange = async (selectedOption, slug) => {
        if (selectedOption && selectedOption._isNew_) {
            setIsDialogOpen(true);
            setSelectedOption(null);
            setdataslug(slug);
        } else if (!selectedOption) {
            // Handle the case when the selection is cleared
            setSelectedOption(null);
            setShowAlert(false);
            // Perform any other necessary actions
            return;
        } else {
            const selectedValue = selectedOption.value;
            if (selectedValue.includes("-")) {
                setErrorMessage("Hyphens are not allowed in the option."); // Set error message
                setShowAlert(true); // Show the alert
                return; // Abort further processing
            }
            setShowAlert(false);
            setSelectedOption(selectedOption);
            const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
            getTotalunit({
                user_id: userData.user_id,
                slug: slug, // Assuming value contains the slug
                portfolio_type_id: selectedOption.value, // Adjust as needed
                Token: userData.token,
            }).then((res) => {
                if (res.code === "200") {
                    setTotalUnits(res.total_uint);
                    setUsablewallet(res.wallet_amount);
                } else {
                    console.error("Data fething error");
                }
            });
        }
    };
    const handleCreate = async (inputValue) => {
            setTotalUnits(0);
            setUsablewallet("");
    
            if (options.find((option) => option.value === inputValue)) {
                return; // Exit early if the option already exists
            }
            const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
            const newOption = {value: inputValue, label: inputValue};
            const newOptions = [...options, newOption]; // Create a new array with the existing options plus the new one
            setOptions(newOptions); // Update the options state
            const createdOption = newOptions.find((option) => option.value == inputValue);
            if (createdOption) {
                handleChange(createdOption);
            }
        };
        const [showAlert, setShowAlert] = useState(false);
        const [errorMessage, setErrorMessage] = useState("");
        const formatToNonZero = (number) => {
            number = parseFloat(number);
    
            if (number >= 1) {
                return number.toFixed(2);
            } else if (number >= 0.1) {
                return number.toFixed(1);
            } else if (number >= 0.01) {
                return number.toFixed(4);
            } else if (number >= 0.001) {
                return number.toFixed(5);
            } else if (number >= 0.0001) {
                return number.toFixed(6);
            } else if (number >= 0.00001) {
                return number.toFixed(7);
            } else if (number >= 0.000001) {
                return number.toFixed(8);
            } else if (number >= 0.0000001) {
                return number.toFixed(9);
            } else if (number >= 0.00000001) {
                return number.toFixed(10);
            } else if (number >= 0.000000001) {
                return number.toFixed(11);
            }
            return number.toFixed(1);
        };
        const handleChange2 = (e) => {
          const value = e.target.value;
          // Check if the input contains a hyphen
          if (value.includes("-")) {
              setShowAlert(true); // Show the alert if there's a hyphen
          } else {
              setShowAlert(false); // Hide the alert if there's no hyphen
              setNewOptionValue(value); // Update the state with the new value
          }
      };
        const [buySell, setbuySell] = React.useState("");
        const handleClick = (buySell) => {
            setSnackbaropen(true);
            setOpen(true);
            setSeverity("info");
            setIsFormSubmitted(false);
            // setSnackbarcolor("success");
            // setSnackbarmessage("Data saved successfully");
            setMsgforUpdatePortfolio("loading...");
            const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
            const portfolioId =
                selectedOption && !isNaN(parseInt(selectedOption.value)) ? parseInt(selectedOption.value) : 0;
            const existingPortfolio = portfolioId > 0 ? "yes" : "no"; // Determine existing_portfolio based on portfolioId
    
            addToPortfolio({
                portfolio_type_id: portfolioId,
                portfolio_name: selectedOption ? selectedOption.label : "", // Ensure selectedOption is defined
    
                existing_portfolio: existingPortfolio,
                user_id: userData.user_id,
                slug: slugpopupdata.slugname,
                unit: unit,
                acquisition_price: price,
                date_time: selectedDate,
                type: buySell,
                Token: userData.token,
            }).then((res) => {
                if (res.code === "200") {
                    // setOpen(false);
                    setSeverity("info");
                    setMsgforUpdatePortfolio(res.message);
                    setPortfolioTypeId(res.portfolio_type_id);
                    setIsFormSubmitted(true);
                    // navigate(
                    //   `/my-portfolio/${res.portfolio_type_id}/${encodeURIComponent(
                    //     selectedOption.label
                    //   )}`
                    // );
                } else {
                    setSeverity("error");
                    // setOpen(true)
                    setMsgforUpdatePortfolio(res.message);
                }
            });
        };
        const [isDialogOpen, setIsDialogOpen] = useState(false);
        const [newOptionValue, setNewOptionValue] = useState("");
        const handleDialogSubmit = () => {
            // Handle submitting the dialog (e.g., creating the new option)
    
            // Update options state with the new option
            const newOption = {value: newOptionValue, label: newOptionValue};
            setOptions([...options, newOption]);
            setSelectedOption(newOption);
            // Close the dialog
            setIsDialogOpen(false);
    
            // setSelectedOption(selectedOption);
            const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
            getTotalunit({
                user_id: userData.user_id,
                slug: dataslug, // Assuming value contains the slug
                portfolio_type_id: newOption.value, // Adjust as needed
                Token: userData.token,
            }).then((res) => {
                if (res.code === "200") {
                    setTotalUnits(res.total_uint);
                    setNewOptionValue("");
                    setUsablewallet(res.wallet_amount);
                } else {
                    console.error("Data fething error");
                }
            });
        };
        const [snackbaropen, setSnackbaropen] = React.useState(false);
        const closesnackbar = (event, reason) => {
            if (reason === "clickaway") {
                return;
            }
    
            setSnackbaropen(false);
        };
        const [severity, setSeverity] = useState("info");
        const [msgforUpdatePortfolio, setMsgforUpdatePortfolio] = useState("loading...");
        const [dataslug, setdataslug] = useState("");
    const fetchDropdownOptions = async () => {
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));

        getFetchdropdown({
            user_id: userData.user_id,

            Token: userData.token,
        }).then((res) => {
            if (res.code === "200") {
                const newOptions = res.data.map((item) => ({
                    value: item.id,
                    label: item.name,
                }));
                setOptions(newOptions);
                setuser_role(res.user_role);
            } else {
                console.error("Data fething error");
            }
        });
    };
const [user_role, setuser_role] = useState("");
    const [slugpopupdata, setSlugpopupdata] = useState({
        slugname: "",
        slug: "",
        units: 0,
    });

    useEffect(() => {
            if (open || showModal) {
                fetchDropdownOptions();
            }
        }, [open, showModal]);

const handleAddToPortfolio = (idx) => {

        getCryporCurrencyListHome({
            slug: idx,
        }).then((res) => {
            if (res.code === "200") {

                // Update slugdata
                setSlugdata(res.data);
                setuser_role(res.user_role);

                // Use res.data directly here
                const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
                if (userData) {
                    setSlugpopupdata({
                        slugname: res.data[0].slug,
                        slug: res.data[0].name,
                        units: res.data[0].total_unit,
                        pricetoday: res.data[0].price_today,
                        logo: res.data[0].logo,
                    });
                    setOpen(true);
                } else {
                    // Save the source in sessionStorage
                    const originalUrl = window.location.pathname + window.location.search;
                    sessionStorage.setItem("originalUrl", originalUrl);

                    // Before redirecting to login
                    sessionStorage.setItem("loginFromAddToPortfolio", "true");
                    sessionStorage.setItem("slugDataForPortfolio", JSON.stringify({
                        slugname: res.data[0].slug,
                        slug: res.data[0].name,
                        units: res.data[0].total_unit,
                        pricetoday: res.data[0].price_today,
                        logo: res.data[0].logo,
                    }));

                   
                    window.history.pushState({}, "", "/login");
                    localStorage.setItem("isPopupManuallyOpened", "true");
                    setLoginModalOpen(true);
                }
            } else {
                console.error("Data fetching error");
            }
        });
    };
          
          
        
  return (

    
    <div style={{ minHeight: "100vh", background: mediaQueryVar? "#f4f4f4": "#fff", padding: mediaQueryVar? "20px" : "13px" ,marginLeft:mediaQueryVar ? "292px":"0px"}}>
                    <Helmet>
                        <title>Crowdwisdom360 Crypto Alerts</title>
                    </Helmet>

                                <Modal
                                    isOpen={open}
                                    onRequestClose={handleClose}
                                    overlayClassName={{
                                        overlay: {
                                            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black overlay
                                            backdropFilter: "blur(5px)", // Optional: Add a blur effect to the overlay
                                        },
                                    }}
                                    className={{
                                        content: {
                                            position: "relative",
                                            backgroundColor: "white", // Set background for inner popup
                                            padding: "20px", // Add padding for content
                                            borderRadius: "8px", // Rounded corners
                                            maxWidth: mediaQueryVar ? "100%" : "100%", // Set a max width for popup
                                            margin: "0", // Center the popup horizontally
                                            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)", // Optional: Popup shadow
                                        },
                                    }}
                                >
                                    <div className="CardOpener" style={{overflow: "hidden"}}>
                                        {isFormSubmitted ? (
                                            <div
                                                style={{
                                                    position: "fixed", // Fix the popup to the top of the viewport
                                                    top: 0, // Align it to the top
                                                    left: 0,
                                                    width: "100%",
                                                    height: "100vh",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional: darken the background for emphasis
                                                    zIndex: 1000, // High z-index to overlay other elements
                                                }}
                                            >
                                                <Card className="card__container">
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            flexDirection: "column",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <CheckCircleOutlineIcon sx={{fontSize: 50, mr: 1}} />
                                                        </div>
                                                        <Typography
                                                            severity="success"
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            Portfolio added successfully!
                                                        </Typography>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-evenly",
                                                                marginTop: "10px",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Button
                                                                onClick={() => {
                                                                    setIsFormSubmitted(false);
                                                                    setOpen(false);
                                                                    setUnit("");
                                                                    setSelectedOption("");
                                                                    setPrice("");
                                                                    setTotalUnits(0);
                                                                    handleDateChange("");
                                                                    setUsablewallet("");
                                                                }}
                                                                color="primary"
                                                                style={{
                                                                    backgroundColor: "rgba(67, 97, 238, 0.15)",
                                                                    borderRadius: "6px",
                                                                    border: "none",
                                                                    textTransform: "none",
                                                                    borderStyle: "solid",
                                                                    borderColor: "rgba(67, 97, 238, 0.15)",
                                                                    borderWidth: "1px",
                                                                    color: "#4361ee",
                                                                    whiteSpace: "nowrap",
                                                                    float: "inline-end",
                                                                    width: mediaQueryVar === true ? "150px" : "100px",
                                                                    height: mediaQueryVar === false ? "30px" : "",
                                                                    fontSize: mediaQueryVar === false ? "10px" : "11px",
                                                                }}
                                                            >
                                                                Add More Cryptos
                                                            </Button>
                                                            <Button
                                                                onClick={() =>
                                                                    navigate(
                                                                        `/my-portfolio/${portfolioTypeId}/${encodeURIComponent(
                                                                            selectedOption.label.replace(/\s+/g, "-")
                                                                        )}`
                                                                    )
                                                                }
                                                                variant="outlined"
                                                                style={{
                                                                    backgroundColor: "rgba(67, 97, 238, 0.15)",
                                                                    borderRadius: "6px",
                                                                    border: "none",
                                                                    textTransform: "none",
                                                                    borderStyle: "solid",
                                                                    borderColor: "rgba(67, 97, 238, 0.15)",
                                                                    borderWidth: "1px",
                                                                    color: "#4361ee",
                                                                    whiteSpace: "nowrap",
                                                                    float: "inline-end",
                                                                    width: mediaQueryVar === true ? "100px" : "100px",
                                                                    height: mediaQueryVar === false ? "30px" : "",
                                                                    fontSize: mediaQueryVar === false ? "10px" : "11px",
                                                                }}
                                                                color="primary"
                                                            >
                                                                View Portfolio
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        ) : (
                                            <Dialog
                                                PaperProps={{
                                                    sx: {
                                                        position: mediaQueryVar ? "static" : "fixed",
                                                        bottom: mediaQueryVar ? "" : 0,
                                                        left: mediaQueryVar ? "" : 0,
                                                        right: mediaQueryVar ? "" : 0,
                                                        marginLeft: mediaQueryVar ? "" : "0px",
                                                        marginRight: mediaQueryVar ? "" : "0px",
                                                        marginTop: "1%",
                                                        maxWidth: "100%",
                                                        minHeight: mediaQueryVar ? "60%" : "", // Adjust max height if needed
                                                        borderRadius: mediaQueryVar ? "24px" : "24px 24px 0 0",
                                                        marginBottom: mediaQueryVar ? "" : "0px",
                                                        padding: mediaQueryVar ? "10px" : "1px",
                                                        alignItems: "center",
                                                        overflow: "scroll",
                                                        "&::-webkit-scrollbar": {
                                                            display: "none",
                                                        },
                                                        "-ms-overflow-style": "none",
                                                        "scrollbar-width": "none", // Ensure no scrollbar
                                                    },
                                                }}
                                                open={open}
                                                onClose={handleClose}
                                            >
                                                <div
                                                    style={{
                                                        padding: "20px",
                                                        width: mediaQueryVar ? "400px" : "100%",
                                                        margin: "0",
                                                        marginTop: "-5px",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                            marginBottom: "20px",
                                                            borderBottom: "1px #E3E3E3 solid",
                                                        }}
                                                    >
                                                        <Typography
                                                            style={{
                                                                fontWeight: "500",
                                                                fontSize: "20.767px",
                                                                paddingLeft: "100px",
                                                            }}
                                                            gutterBottom
                                                        >
                                                            Add To Portfolio
                                                        </Typography>
                    
                                                        <div className="primaryButton">
                                                            <img
                                                                className="frameIcon"
                                                                alt="frameIcon"
                                                                onClick={handleClose}
                                                                src={window.constants.asset_path + "/images/frame17.svg"}
                                                                style={{
                                                                    background: "#F3F3F3",
                                                                    width: "25px",
                                                                    height: "25px",
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            marginBottom: "5px",
                                                            marginLeft: mediaQueryVar === false ? "10px" : "",
                                                        }}
                                                    >
                                                        <Typography
                                                            style={{
                                                                fontSize: "13.845px",
                                                                fontWeight: "500",
                                                                marginBottom: "1px",
                                                                color: "black",
                                                                marginTop: "-8px",
                                                            }}
                                                        >
                                                            Select Portfolio
                                                        </Typography>
                                                        <div style={{marginTop: "5px"}}>
                                                            <CreatableSelect
                                                                isClearable
                                                                styles={{
                                                                    control: (provided) => ({
                                                                        ...provided,
                                                                        borderRadius: "12px",
                                                                        border: "1px solid #1877F2",
                                                                        boxShadow: "none",
                                                                        minHeight: "40px",
                                                                        "&:hover": {
                                                                            borderRadius: "12px",
                                                                            border: "2px solid #1877F2",
                                                                        },
                                                                    }),
                                                                    menu: (provided) => ({
                                                                        ...provided,
                                                                        borderRadius: "8px",
                                                                    }),
                                                                    placeholder: (provided) => ({
                                                                        ...provided,
                                                                        color: "",
                                                                    }),
                                                                    dropdownIndicator: (provided) => ({
                                                                        ...provided,
                                                                        color: "#1877F2",
                                                                        border: "1px solid #1877F2",
                                                                        padding: "5px",
                                                                        margin: "8px",
                                                                        borderRadius: "3.786px",
                                                                        "&:hover": {
                                                                            color: "#1877F2",
                                                                        },
                                                                    }),
                                                                    indicatorSeparator: (provided) => ({
                                                                        display: "none",
                                                                    }),
                                                                }}
                                                                placeholder=""
                                                                onChange={(options) => handleChange(options, slugpopupdata.slugname)}
                                                                onCreateOption={handleCreate}
                                                                options={[
                                                                    {
                                                                        value: "",
                                                                        label: "Create new portfolio",
                                                                        _isNew_: true,
                                                                    }, // Dynamic "create new option" item
                                                                    ...options, // Existing options
                                                                ]}
                                                                value={selectedOption}
                                                            />
                                                        </div>
                                                        {showAlert && (
                                                            <Alert severity="error" sx={{marginBottom: "10px"}}>
                                                                {errorMessage}
                                                            </Alert>
                                                        )}
                                                    </div>
                                                    <Typography
                                                        style={{
                                                            fontSize: "13.845px",
                                                            fontWeight: "500",
                                                            color: "#000",
                                                            marginTop: "10px",
                                                            marginBottom: "5px",
                                                        }}
                                                    >
                                                        Usable Wallet Value: {Usablewallet}
                                                    </Typography>
                                                    <div
                                                        style={{
                                                            padding: mediaQueryVar ? "8px" : "5px",
                                                            background: "#F3F3F3",
                                                            borderRadius: "8px",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "space-between",
                                                            marginBottom: "5px",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                flex: 0,
                                                                minWidth: 0,
                                                            }}
                                                        >
                                                            <img
                                                                style={{
                                                                    marginRight: "10px",
                                                                    border: "2px solid #000",
                                                                    borderRadius: "19px",
                                                                    height: "27.69px",
                                                                    width: "27.69px",
                                                                }}
                                                                alt="logo"
                                                                src={slugpopupdata.logo}
                                                            />
                                                            <div style={{display: "flex", flexDirection: "column"}}>
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight: "500",
                                                                        marginBottom: "0px",
                                                                        fontSize: "14px",
                                                                        color: "#7F7F80",
                                                                    }}
                                                                >
                                                                    {slugpopupdata.slugname}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                alignItems: "center",
                                                                marginLeft: "20%",
                                                                flex: 1,
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontWeight: "500",
                                                                    marginBottom: "4px",
                                                                    fontSize: "13px",
                                                                    color: "#7F7F80",
                                                                }}
                                                            >
                                                                {" "}
                                                                Price
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: "12.114px",
                                                                    fontWeight: "500",
                                                                    color: "black",
                                                                    margin: "0",
                                                                    marginLeft: "5%",
                                                                }}
                                                            >
                                                                {slugpopupdata.pricetoday}
                                                            </Typography>
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                alignItems: "center",
                                                                marginLeft: "20px",
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontWeight: "500",
                                                                    marginBottom: "4px",
                                                                    fontSize: "13px",
                                                                    color: "#7F7F80",
                                                                }}
                                                            >
                                                                {" "}
                                                                Units
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: "12.114px",
                                                                    fontWeight: "500",
                                                                    color: "black",
                                                                    margin: "0",
                                                                }}
                                                            >
                                                                {totalUnits}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                    <div className="card__container__row3">
                                                        <div style={{marginLeft: "-5px"}}>
                                                            <p style={{fontSize: "14px", fontWeight: "500"}}>Enter number of Units</p>
                                                            <input
                                                                style={{
                                                                    backgroundColor: "white",
                                                                    color: "black",
                                                                    padding: "20px",
                                                                    border: "1px solid #1877F2",
                                                                    borderRadius: "10.384px",
                                                                    width: mediaQueryVar ? "350px" : "100%",
                                                                    height: "45px",
                                                                    margin: 0,
                                                                }}
                                                                min="1"
                                                                max="10"
                                                                type="number"
                                                                placeholder=""
                                                                value={unit}
                                                                onChange={(e) => setUnit(e.target.value)}
                                                            ></input>
                                                        </div>
                                                        <div>
                                                            $
                                                            {formatToNonZero(
                                                                unit *
                                                                    parseFloat(
                                                                        (slugpopupdata.pricetoday || "")
                                                                        .replace(/^\$/, "") // Remove the dollar sign
                                                                        .replace(/,/g, "") // Remove commas
                                                                    )
                                                            )}
                                                        </div>
                                                        {user_role === "superuser" && (
                                                            <>
                                                                <div className="card__container__row3__r2">
                                                                    <div style={{marginLeft: "-5px"}}>
                                                                        <p style={{fontSize: "14px", fontWeight: "500"}}>
                                                                            Buy/Sell Price($)
                                                                        </p>
                                                                        <input
                                                                            style={{
                                                                                backgroundColor: "white",
                                                                                color: "black",
                                                                                padding: "20px",
                                                                                border: "1px solid #1877F2",
                                                                                borderRadius: "10.384px",
                                                                                width: "345px",
                                                                                height: "45px",
                                                                            }}
                                                                            className="card__container__row3__r2__input"
                                                                            min="1"
                                                                            max="10"
                                                                            type="number"
                                                                            placeholder="Enter Price"
                                                                            value={price}
                                                                            onChange={(e) => setPrice(e.target.value)}
                                                                        ></input>
                                                                    </div>
                                                                </div>
                                                                <div style={{marginBottom: "10px", marginLeft: "-5px"}}>
                                                                    <p style={{fontSize: "14px", fontWeight: "500"}}>Date</p>
                                                                    <TextField
                                                                        type="date"
                                                                        value={selectedDate}
                                                                        onChange={(e) => handleDateChange(e.target.value)}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                        sx={{
                                                                            "& .MuiOutlinedInput-root": {
                                                                                borderRadius: "10.384px",
                                                                                border: "1px solid #1877F2",
                                                                                boxShadow: "none",
                                                                                width: "190%",
                                                                                height: "45px",
                                                                            },
                                                                        }}
                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                        <div className="card__container__row3__r4" style={{marginTop: "10px"}}>
                                                            <Button
                                                                className={buySell === "buy" ? "buttonToggleButActive" : "buttonToggleBuy"}
                                                                value="Buy"
                                                                style={{
                                                                    lineHeight: "20px",
                                                                    width: mediaQueryVar ? "159px" : "130px",
                                                                    borderRadius: "27px",
                                                                    border: "1px solid #1877F2",
                                                                    padding: mediaQueryVar ? "15px 85px" : "13px 75px",
                                                                    marginLeft: "-6px",
                                                                    backgroundColor: buySell === "buy" ? "" : "#1877F2",
                                                                    color: buySell === "buy" ? "" : "white",
                                                                    textTransform: "none",
                                                                    fontSize: "18px",
                                                                    fontWeight: "700",
                                                                }}
                                                                onClick={() => handleClick("buy")}
                                                            >
                                                                Buy
                                                            </Button>
                                                            <Button
                                                                className={
                                                                    buySell === "sell" ? "buttonToggleSellActive" : "buttonToggleSell"
                                                                }
                                                                value="sell"
                                                                style={{
                                                                    lineHeight: "20px",
                                                                    width: mediaQueryVar ? "159px" : "130px",
                                                                    borderRadius: "27px",
                                                                    border: "1px solid #1877F2",
                                                                    marginLeft: "10px",
                                                                    padding: mediaQueryVar ? "15px 85px" : "13px 75px",
                                                                    backgroundColor: buySell === "sell" ? "" : "#1877F2",
                                                                    color: buySell === "sell" ? "" : "white",
                                                                    textTransform: "none",
                                                                    fontSize: "18px",
                                                                    fontWeight: "700",
                                                                }}
                                                                onClick={() => handleClick("sell")}
                                                            >
                                                                Sell
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Dialog>
                                        )}
                                    </div>
                                </Modal>
                    
                                <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} fullWidth maxWidth="sm">
                                    <DialogTitle>
                                        Create New Portfolio
                                        <IconButton
                                            aria-label="close"
                                            onClick={() => {
                                                setIsDialogOpen(false);
                                                setNewOptionValue("");
                                            }}
                                            sx={{position: "absolute", right: 13, top: 8}}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </DialogTitle>
                                    <DialogContent>
                                        <div>
                                            <TextField
                                                autoFocus
                                                label="Enter Name"
                                                value={newOptionValue}
                                                onChange={handleChange2}
                                                fullWidth
                                                margin="dense"
                                            />
                                            {showAlert && (
                                                <Alert severity="error" sx={{marginTop: "8px"}}>
                                                    Hyphens are not allowed in the name.
                                                </Alert>
                                            )}
                                        </div>
                                    </DialogContent>
                                    <DialogActions sx={{marginRight: "14px"}}>
                                        <Button
                                            onClick={() => {
                                                setIsDialogOpen(false);
                                                setNewOptionValue("");
                                            }}
                                            sx={{fontWeight: "bold"}}
                                            variant="outlined"
                                            color="primary"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            onClick={handleDialogSubmit}
                                            variant="contained"
                                            color="primary"
                                            sx={{fontWeight: "bold"}}
                                            disabled={!newOptionValue.trim()}
                                        >
                                            Submit
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                    
                                <Snackbar
                                    open={snackbaropen}
                                    autoHideDuration={5000}
                                    onClose={closesnackbar}
                                    anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                                >
                                    <Alert severity={severity}>{msgforUpdatePortfolio}</Alert>
                                </Snackbar>

                  {mediaQueryVar ? (
                            <div
                            className="d-flex justify-content-between"
                            style={{
                                border: "1px solid #1877F2",
                                borderRadius: "3rem",
                                padding: "3px 4px",
                                margin: "auto",
                                background: "#FFF",
                                marginBottom:"20px",
                                marginRight:"5%"
                            }}
                        >
                            {headerTabOptions.map((option) => (
                                <a
                                    key={option.id}
                                    href={option.route}
                                    className="d-flex align-items-center"
                                    style={{
                                        backgroundColor: option.color,
                                        textDecoration: "none",
                                        boxShadow: "0px 5px 0px 0px #dddbdb",
                                        borderRadius: "50px",
                                        padding: "8px 12px",
                                    }}
                                >
                                    {option.icon}
                                    <span
                                        style={{
                                            paddingLeft: "2%",
                                            whiteSpace: "nowrap",
                                            color: "#000000",
                                            fontWeight: "700",
                                        }}
                                    >
                                        {option.text}
                                    </span>
                                </a>
                            ))}
                        </div>
                        
                        ) : null}    
{/* {mediaQueryVar ? null : (
    <>
   <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
    <Typography style={{fontSize:"24px", fontWeight:"700"}}> Crypto Alerts </Typography>
    <IconButton
      color="inherit"
      aria-label="open drawer"
      edge="end"
      onClick={handleDrawerToggle}
      sx={{ display: { sm: "none" } }}
    >
      <img
        src="/images/menubar.svg"
        alt="Menu Icon"
        style={{
          width: "24px",
          height: "24px",
          marginRight: "10px",
          marginTop: "10px",
        }}
      />
    </IconButton>
      
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "400",
              backgroundColor: "white",
              color: "#727376",
            },
          }}
        >
          {drawer}
        </Drawer></div>
        </>
   )} */}

                        <div className="d-flex justify-content-between my-3">
                        {mediaQueryVar ? (
                            <>
                                <TabHook activeTab={0} tabList={profileTabList} />
                                <div className="d-flex profile-left-section">
                                    <div className=" my-profile-container">
                                        <a
                                            href="/account"
                                            className="rounded-pill px-3 py-2 d-flex align-items-center justify-content-between my-profile-btn w-auto"
                                        >
                                            <ProfileIcon />
                                            <span className="tab-text">My Profile</span>
                                        </a>
                                    </div>
                                    <div className="notification-section position-relative">
                                        <NotificationIcon className="notification-icon fa-2x" />
                                        <span className="badge rounded-pill badge-notification bg-danger position-absolute top-0 start-100 ">
                                            {unreadAlerts}
                                        </span>
                                    </div>
                                </div>
                            </>
                        ) : (
                          <>
                          <div className=" my-profile-container">
                                  <a
                                      href="/account"
                                      className="rounded-pill px-3 py-2 d-flex align-items-center justify-content-between my-profile-btn w-auto"
                                  >
                                      <ProfileIcon />
                                      <span className="tab-text">My Profile</span>
                                  </a>
                              </div>
                             

                               <Grid item xs={12} align="left">
                                              
                                              {mediaQueryVar ? null : (
                                                  <div
                                                      style={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                          justifyContent: "space-between", // Changed from space-between to flex-end
                                                          marginTop: "0px",
                                                          marginLeft: "0px",
                                                          fontSize: "20px",
                                                      }}
                                                  >
                                             <div style={{display:"flex", alignItems:"center"}}>
                                             <div className="notification-section position-relative">
                                  <NotificationIcon className="notification-icon fa-2x" />
                                  <span className="badge rounded-pill badge-notification bg-danger position-absolute top-0 start-100 ">
                                      {unreadAlerts}
                                  </span>
                              </div>   
                                                      <IconButton
                                                          color="inherit"
                                                          aria-label="open drawer"
                                                          edge="end"
                                                          onClick={handleDrawerToggle}
                                                          sx={{display: {sm: "none"}}}
                                                      >
                                                          <img
                                                              src="/images/menubar.svg"
                                                              alt="Menu Icon"
                                                              style={{
                                                                  width: "24px",
                                                                  height: "24px",
                                                                  marginRight: "10px",
                                                                  marginTop: "0px",
                                                              }}
                                                          />
                                                      </IconButton>
                                                      </div>
                                                  </div>
                                              )}
                                          </Grid>

                          
                      </>
                        )}
                    </div>
                      
                      
        {/* Search bar */}                 
   {mediaQueryVar ?(
    <div
    style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        marginTop: "16px",
        marginBottom: "1%",
    }}
>
<img src="/images/searchblueicon.svg"
    
    style={{
        position: "absolute",
        left: "25px",
        width: "20px",
        height: "20px",
       
    }}
/>

    <input
        type="text"
        placeholder="Search Portfolios, Predictions, Cryptos"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{
            paddingLeft: "60px",
            width: "565px",
            height: "47px",
            borderRadius: "3rem",
            border: "1px solid #1877f2",
            backgroundColor: "#fff",
            fontSize: "large",
            maxWidth: "800px",
            color: "#1877f2",
            caretColor: "#1877f2",
            outline: "none",
        }}
    />
    </div>
   ):(
    <div
    style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        marginTop: "16px",
        marginBottom: "1%",
    }}
>
<img src="/images/searchblueicon.svg"
    
    style={{
        position: "absolute",
        left: "25px",
        width: "20px",
        height: "20px",
       
    }}
/>

    <input
        type="text"
        placeholder="Search Portfolios, Predictions Cryptos"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{
            paddingLeft: "60px",
            width: "565px",
            height: "42px",
            borderRadius: "3rem",
            border: "1px solid #1877f2",
            backgroundColor: "transparent",
            fontSize: "14px",
            maxWidth: "800px",
            color: "#1877f2",
            caretColor: "#1877f2",
            outline: "none",
        }}
    />
    </div>
   )}                                            

   {mediaQueryVar ? null : (
    <div style={{margin: "20px auto"}}>
    <TabHook activeTab={0} tabList={profileTabMobileList} />
    </div>
   )}
                   
   

   

{mediaQueryVar ? (
  <Box
    component="nav"
    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
    aria-label="mailbox folders"
  >
    {/* The implementation can be swapped with JS to avoid SEO duplication of links. */}
    <Drawer
      container={container}
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { xs: "block", sm: "none" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: drawerWidth,
          backgroundColor: "white",
          color: "#727376",
        },
      }}
    >
      {drawer}
    </Drawer>

    <Drawer
      variant="permanent"
      sx={{
        display: { xs: "none", sm: "block" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: drawerWidth,
          backgroundColor: "white",
          color: "#727376",
        },
      }}
      open
    >
      {drawer}
    </Drawer>
  </Box>
) :  <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: {xs: "block", sm: "none"},
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: "400",
                        backgroundColor: "white",
                        color: "#727376",
                    },
                }}
            >
                {drawer}
            </Drawer>}

{mediaQueryVar ? (

    //Desktop Design

      <div style={{  width: "100%", background: "#fff", padding: "20px", borderRadius: "12px", boxShadow: "0 4px 15px rgba(0,0,0,0.1)", margin: "auto" }}>
<h2 style={{
  color: "#000000",
  textAlign: "center",
  fontSize: "22px",
  fontWeight: "bold",
  marginBottom: "20px",
  borderRadius: "10px",
  borderBottom: "5px solid #1877F2",
  background: "linear-gradient(180deg, rgba(24, 119, 242, 0.11) -1.89%, rgba(24, 119, 242, 0.00) 60.38%)",
 
  padding: "10px",
  borderRadius: "8px",
  boxShadow: "0 3px 6px rgba(0, 0, 0, 0.1)"
}}>
  <div style={{display:"flex",alignItems:"center"}}>
  Alerts 
  <img
  src="/images/sort_icon.svg"
  alt="Sort Icon"
  style={{
    width: "24px",
    height: "24px",
    marginRight: "10px",
    marginTop: "10px",
    cursor: "pointer",
    transform: sortOrder === "asc" ? "rotate(180deg)" : "none",
    transition: "transform 0.3s ease-in-out",
  }}
  onClick={handleSort}
/>
</div>
</h2>

{!userData ? (
  <div style={{ textAlign: "center", marginTop: "20px" }}>
  <button
    onClick={handleLoginClick}
    style={{
      padding: "10px 20px",
      fontSize: "16px",
      fontWeight: "bold",
      color: "#fff",
      background: "#1877F2",
      border: "none",
      borderRadius: "24px",
      cursor: "pointer",
      boxShadow: "0 3px 6px rgba(0, 0, 0, 0.1)",
      marginBottom:"37px"
    }}
  >
    Register/Login for custom alerts
  </button>
</div>
):null}

      
      {/* Loading State */}
      {isLoading && (
        <div>
          {[...Array(3)].map((_, index) => (
            <div key={index} style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "15px" }}>
              <div style={{ width: "40px", height: "40px", borderRadius: "50%", backgroundColor: "#e0e0e0" }}></div>
              <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                <div style={{ width: "200px", height: "20px", backgroundColor: "#e0e0e0" }}></div>
                <div style={{ width: "150px", height: "15px", backgroundColor: "#e0e0e0" }}></div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* No Alerts Message */}
      {!isLoading && filteredAlerts.length === 0 && (
        <div style={{ padding: "20px", textAlign: "center" }}>
          <span style={{ fontSize: "50px", display: "block", marginBottom: "10px" }}>🔔</span>
          <p style={{ color: "#777", fontSize: "16px" }}>No alerts available</p>
        </div>
      )}

      {/* Alerts List */}
      {!isLoading &&
        filteredAlerts.slice(0, visibleCount).map((alert) => (
        <motion.div
          key={alert.id}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "15px",
            borderRadius: "20px",
            boxShadow: "0px 3px 8px rgba(0,0,0,0.1)",
            background: "linear-gradient(180deg, rgba(24, 119, 242, 0.40) -1.89%, rgba(24, 119, 242, 0.00) 60.38%)",
            color:"black",
            border:"1px solid #1877F2",
            marginBottom: "10px",
            paddingBottom: "28px",
            paddingTop:"10px"
          }}
        >
           
          {/* Left Side */}
          <div style={{ display: "flex", flexDirection:"column", gap: "8px",maxWidth:"600px" }}>
          <div style={{ fontSize: "14px", color: "#555" }}>
     
           {alert.created_at.formatted} 
    
          </div>
            {/* <div style={{width:"30px"}}>
            {alert.alertType === "bullish" ? (
              <CheckCircle size={22} color="#28a745" />
            ) : alert.alertType === "bearish" ? (
              <TrendingDown size={22} color="#dc3545" />
            ) : alert.alertType === "outperformer" ? (
              <TrendingUp size={22} color="#007bff" />
            ) : (
              <AlertCircle size={22} color="#ffc107" />
            )}</div> */}
            <div>

            <div style={{display:"flex", alignItems:"center",gap:"3px"}}>
              <img src={alert.logo} width={30} height={30} style={{borderRadius:"20px"}}></img>
              <strong style={{ fontSize: "16px" }}>{alert.coin}</strong>
              <p style={{ fontSize: "14px", color: "#000", margin: "5px 0" }}>
  {(() => {
    let message = alert.message;

    // Replace exact phrases with styled versions
    message = message.replace(/Moderate Performer/gi, (match) => {
      return `<span style="color: orange; font-weight: bold; text-transform: uppercase;">${match}</span>`;
    });

    message = message.replace(/Neutral/gi, (match) => {
      return `<span style="color: orange; font-weight: bold; text-transform: uppercase;">${match}</span>`;
    });

    message = message.replace(/Emerging Outperformer/gi, (match) => {
      return `<span style="color: green; font-weight: bold; text-transform: uppercase;">${match}</span>`;
    });

    message = message.replace(/Outperformer/gi, (match) => {
      return `<span style="color: green; font-weight: bold; text-transform: uppercase;">${match}</span>`;
    });

    message = message.replace(/Underperformer/gi, (match) => {
      return `<span style="color: red; font-weight: bold; text-transform: uppercase;">${match}</span>`;
    });

    message = message.replace(/Bullish/gi, (match) => {
      return `<span style="color: green; font-weight: bold; text-transform: uppercase;">${match}</span>`;
    });

    message = message.replace(/Bearish/gi, (match) => {
      return `<span style="color: red; font-weight: bold; text-transform: uppercase;">${match}</span>`;
    });

    message = message.replace(/Added/gi, (match) => {
      return `<span style="color: black; font-weight: bold; text-transform: uppercase;">${match}</span>`;
    });

    message = message.replace(/Removed/gi, (match) => {
      return `<span style="color: black; font-weight: bold; text-transform: uppercase;">${match}</span>`;
    });

    return <span dangerouslySetInnerHTML={{ __html: message }} />;
  })()}
</p>
</div>





    {alert.portfolio && (
     <> <Briefcase size={14} style={{ verticalAlign: "middle" , marginLeft:"35px"}} /> <span style={{color:"black",fontSize:"14px"}}>{alert.portfolio}</span></>
     )}

             

            </div>
          </div>
          
          {/* Right Side Buttons */}
          <div style={{ width:userData? "150px": "280px", display:"flex" }}>
            {/* <motion.div
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.2 }}
              onClick={() => togglePin(alert.id)}
              style={{ cursor: "pointer", color: alert.pinned ? "#ff9800" : "#555" }}
            >
              <Pin size={20} />
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.2 }}
              onClick={() => toggleRead(alert.id)}
              style={{ cursor: "pointer", color: "#555" }}
            >
              {alert.read ? <EyeOff size={20} /> : <Eye size={20} />}
            </motion.div> */}
            <button
  style={{ 
    borderRadius: "50px",
    border: "1px solid #1877F2",
    background: "linear-gradient(91deg, rgba(154, 255, 249, 0.60) 18.86%, rgba(255, 240, 109, 0.60) 100.02%)",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)", 
    padding: "8px 12px", 
    color: "#1877F2",
    fontSize: userData? "16px":"14px",
    fontWeight: "bold",
    cursor: "pointer"
  }}
  onClick={() => {
    if (userData) {
      const messageLower = alert.message.toLowerCase();
      const performanceKeywords = ["underperformer", "moderate performer", "outperformer", "bullish", "bearish", "neutral"];

      if (performanceKeywords.some(keyword => messageLower.includes(keyword))) {
        navigate(`/crypto/${alert.tag_url}`);
      } else if (messageLower.includes("prediction")) {
        navigate(`/crypto/${alert.tag_url}/predictions`);
      } else {
        navigate(`/influencer/${alert.tag_url}/portfolios`);
      }
    } else {
      handleLoginClick();
    }
  }}
>
{userData ? "Check Out Now" : "Add to Watchlist"}

</button>

{userData? null : (
  <button
  style={{ 
    borderRadius: "50px",
    border: "1px solid #1877F2",
    background: "linear-gradient(91deg, rgba(154, 255, 249, 0.60) 18.86%, rgba(255, 240, 109, 0.60) 100.02%)",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)", 
    padding: "8px 12px", 
    color: "#1877F2",
    fontSize: "14px",
    fontWeight: "bold",
    cursor: "pointer",
    marginLeft:"10px"
  }}
  onClick={() => {handleAddToPortfolio(alert.symbol)}}>
  Add to Portfolio
</button>
)}



          </div>
        </motion.div>
      ))}

      {/* Lazy Load Trigger */}
     <div ref={observerTarget} style={{ height: "1px" }}></div>
     {isFetchingMore && visibleCount < filteredAlerts.length &&(
  <div style={{ textAlign: "center", margin: "20px 0" }}>
    <div style={{
      width: "40px",
      height: "40px",
      border: "4px solid #e0e0e0",
      borderTop: "4px solid #1877F2",
      borderRadius: "50%",
      animation: "spin 1s linear infinite",
      margin: "auto"
    }} />
  </div>
)}

     </div>


):(
 
      //Mobile Design

      <div style={{  width: "100%", margin: "auto",marginBottom:"70px" }}>
      <h2 style={{
        color: "#000000",
        textAlign: "center",
        fontSize: "22px",
        fontWeight: "bold",
        marginBottom: "20px",
        borderRadius: "10px",
        borderBottom: "2px solid #1877F2",
        background: "linear-gradient(91deg, rgba(154, 255, 249, 0.30) 18.86%, rgba(255, 240, 109, 0.30) 100.02%)",
       
        padding: "10px",
        borderRadius: "8px",
        boxShadow: "0 3px 6px rgba(0, 0, 0, 0.1)"
      }}>
        <div style={{display:"flex",alignItems:"center"}}>
        Alerts 
        <img
              src="/images/sort_icon.svg"
              alt="Menu Icon"
              style={{
                width: "24px",
                height: "24px",
                marginRight: "10px",
                marginTop: "10px",
                cursor:"pointer",
                transform: sortOrder === "asc" ? "rotate(180deg)" : "none",
    transition: "transform 0.3s ease-in-out",
              }}
              onClick={handleSort}
            /></div>
      </h2>

      {!userData ? (
  <div style={{ textAlign: "center", marginTop: "20px" }}>
  <button
    onClick={handleLoginClick}
    style={{
      padding: "10px 20px",
      fontSize: "16px",
      fontWeight: "bold",
      color: "#fff",
      background: "#1877F2",
      border: "none",
      borderRadius: "24px",
      cursor: "pointer",
      boxShadow: "0 3px 6px rgba(0, 0, 0, 0.1)",
      marginBottom:"40px"
    }}
  >
    Register/Login for custom alerts
  </button>
</div>
):null}
      
            
            {/* Loading State */}
            {isLoading && (
              <div>
                {[...Array(3)].map((_, index) => (
                  <div key={index} style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "15px" }}>
                    <div style={{ width: "40px", height: "40px", borderRadius: "50%", backgroundColor: "#e0e0e0" }}></div>
                    <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                      <div style={{ width: "200px", height: "20px", backgroundColor: "#e0e0e0" }}></div>
                      <div style={{ width: "150px", height: "15px", backgroundColor: "#e0e0e0" }}></div>
                    </div>
                  </div>
                ))}
              </div>
            )}
      
            {/* No Alerts Message */}
            {!isLoading && filteredAlerts.length === 0 && (
              <div style={{ padding: "20px", textAlign: "center" }}>
                <span style={{ fontSize: "50px", display: "block", marginBottom: "10px" }}>🔔</span>
                <p style={{ color: "#777", fontSize: "16px" }}>No alerts available</p>
              </div>
            )}
      
            {/* Alerts List */}
            {!isLoading &&
              filteredAlerts.slice(0, visibleCount).map((alert) => (
              <motion.div
                key={alert.id}
                style={{
                  display: "flex",
                  flexDirection: mediaQueryVar? "row" : "column",
                  justifyContent: "space-between",
                  padding: "15px",
                  borderRadius: "20px",
                  boxShadow: "0px 3px 8px rgba(0,0,0,0.1)",
                  background: "linear-gradient(180deg, rgba(24, 119, 242, 0.40) -1.89%, rgba(24, 119, 242, 0.00) 60.38%)",
                  color:"black",
                  border:"1px solid #1877F2",
                  marginBottom: "10px",
                  paddingBottom: "28px",
                  paddingTop:"10px"
                }}
              >
                 
                {/* Left Side */}
                <div
  style={{
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    maxWidth: "600px",
  }}
>
  <div style={{ display: "inline-block" }}>
    <span
      style={{
        fontSize: "14px",
        color: "#000",
        borderBottom: "1px solid #1877F2",
      }}
    >
      {alert.created_at.formatted}
    </span>
  </div>

  <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
    {/* Logo & Coin Name */}
    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
      <img
       src={alert.logo}
        width={30}
        height={30}
        style={{ borderRadius: "20px" }}
      />
      <strong style={{ fontSize: "16px" }}>{alert.coin}</strong>
    </div>

    {/* Alert Message */}
    <div style={{ flex: 1 }}>
      <p
        style={{
          fontSize: "14px",
          color: "#000",
          margin: "5px 0",
          textAlign: "left",
        }}
      >
        {(() => {
          let message = alert.message;

          message = message.replace(/Moderate Performer|Neutral/gi, (match) => {
            return `<span style="color: orange; font-weight: bold; text-transform: uppercase;">${match}</span>`;
          });

          message = message.replace(
            /Emerging Outperformer|Outperformer|Bullish/gi,
            (match) => {
              return `<span style="color: green; font-weight: bold; text-transform: uppercase;">${match}</span>`;
            }
          );

          message = message.replace(/Underperformer|Bearish/gi, (match) => {
            return `<span style="color: red; font-weight: bold; text-transform: uppercase;">${match}</span>`;
          });

          message = message.replace(/Added|Removed/gi, (match) => {
            return `<span style="color: black; font-weight: bold; text-transform: uppercase;">${match}</span>`;
          });

          return <span dangerouslySetInnerHTML={{ __html: message }} />;
        })()}
      </p>
    </div>
  </div>

  {alert.portfolio && (
    <div style={{ display: "flex", alignItems: "center", gap: "5px",margin:"auto" }}>
      <Briefcase size={14} style={{ verticalAlign: "middle" }} />
      <div>{alert.portfolio}</div>
    </div>
  )}
</div>

                
                {/* Right Side Buttons */}
                <div style={{ display:"flex",alignItems:"center", justifyContent:"center", margin:"15px auto 0px auto" }}>
                  {/* <motion.div
                    whileHover={{ scale: 1.1 }}
                    transition={{ duration: 0.2 }}
                    onClick={() => togglePin(alert.id)}
                    style={{ cursor: "pointer", color: alert.pinned ? "#ff9800" : "#555" }}
                  >
                    <Pin size={20} />
                  </motion.div>
                  <motion.div
                    whileHover={{ scale: 1.1 }}
                    transition={{ duration: 0.2 }}
                    onClick={() => toggleRead(alert.id)}
                    style={{ cursor: "pointer", color: "#555" }}
                  >
                    {alert.read ? <EyeOff size={20} /> : <Eye size={20} />}
                  </motion.div> */}
                 <button
  style={{ 
    borderRadius: "50px",
    border: "1px solid #1877F2",
    background: "linear-gradient(91deg, rgba(154, 255, 249, 0.60) 18.86%, rgba(255, 240, 109, 0.60) 100.02%)",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)", 
    padding: "8px 12px", 
    color: "#1877F2",
    fontSize: userData? "16px" : "14px",
    fontWeight: "bold",
    cursor: "pointer"
  }}
  onClick={() => {
    if (userData) {
      const messageLower = alert.message.toLowerCase();
      const performanceKeywords = ["underperformer", "moderate performer", "outperformer", "bullish", "bearish", "neutral"];

      if (performanceKeywords.some(keyword => messageLower.includes(keyword))) {
        navigate(`/crypto/${alert.tag_url}`);
      } else if (messageLower.includes("prediction")) {
        navigate(`/crypto/${alert.tag_url}/predictions`);
      } else {
        navigate(`/influencer/${alert.tag_url}/portfolios`);
      }
    } else {
      handleLoginClick();
    }
  }}
>
  {userData ? "Check Out Now" : "Add to Watchlist"}
</button>

{userData? null : (
  <button
  style={{ 
    borderRadius: "50px",
    border: "1px solid #1877F2",
    background: "linear-gradient(91deg, rgba(154, 255, 249, 0.60) 18.86%, rgba(255, 240, 109, 0.60) 100.02%)",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)", 
    padding: "8px 12px", 
    color: "#1877F2",
    fontSize: "14px",
    fontWeight: "bold",
    cursor: "pointer",
    marginLeft:"10px"
  }}
  onClick={() => {handleAddToPortfolio(alert.symbol)}}>
  Add to Portfolio
</button>
)}

      
      
      
                </div>
              </motion.div>
            ))}
      
            {/* Load More Button */}
            {/* {!isLoading && visibleCount < filteredAlerts.length && (
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <button
                  onClick={loadMore}
                  style={{
                    padding: "10px 20px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#fff",
                    background: "#1877F2",
                    border: "none",
                    borderRadius: "8px",
                    cursor: "pointer",
                    boxShadow: "0 3px 6px rgba(0, 0, 0, 0.1)"
                  }}
                >
                  Load More
                </button>
              </div>
            )} */}

            {/* Lazy Load Trigger */}
            <div ref={observerTarget} style={{ height: "1px" }}></div>
            {isFetchingMore && visibleCount < alerts.length && (
  <div style={{ textAlign: "center", margin: "20px 0" }}>
    <div style={{
      width: "40px",
      height: "40px",
      border: "4px solid #e0e0e0",
      borderTop: "4px solid #1877F2",
      borderRadius: "50%",
      animation: "spin 1s linear infinite",
      margin: "auto"
    }} />
  </div>
)}

            </div>

            

)}

{mediaQueryVar === false && (
                <div
                    style={{
                        position: "fixed",
                        bottom: "0",
                        backgroundColor: "white",
                        width: "105%",
                        zIndex: "100",
                        marginBottom: "0px",
                        marginLeft: "-15px",
                    }}
                >
                    <BottomNavigation showLabels>
                        <BottomNavigationAction
                            label="Best Portfolios"
                            onClick={() => navigate("/portfolio-gallery")}
                            icon={
                                <img
                                    src={
                                        loc === "/portfolio-gallery"
                                            ? "/images/dashboardS.svg"
                                            : "/images/dashboardU.svg"
                                    }
                                    width={"20px"}
                                    height={"20px"}
                                    alt="gallery"
                                />
                            }
                        />
                        
                        <BottomNavigationAction
                            label="Portfolio Audit"
                            onClick={() => {
                                if (userData) {
                                    navigate("/audit-my-portfolio");
                                } else {
                                    const originalUrl = "/subscriptions/screener360";
                                    sessionStorage.setItem("originalUrl", originalUrl);
                                    handleLoginClick();
                                }
                            }}
                            icon={
                                <img
                                    src={
                                        loc === "/subscriptions/screener360"
                                            ? "/images/dollarS.svg"
                                            : "/images/dollarU.svg"
                                    }
                                    width={24}
                                    height={24}
                                    alt="Icon"
                                    style={{marginBottom: "-3px"}}
                                />
                            }
                        />
                        <BottomNavigationAction
                            label="Screener"
                            onClick={() => navigate("/crypto-screener")}
                            icon={
                                <img
                                    src={
                                        loc === "/crypto-screener" ? "/images/portfolioS.svg" : "/images/portfolioU.svg"
                                    }
                                    width={"20px"}
                                    height={"20px"}
                                    alt="screener"
                                />
                            }
                        />
                        
                    </BottomNavigation>
                </div>
            )}

      {isLoginModalOpen && (
                        <LoginPopUp
                            isLoginModalOpen={isLoginModalOpen}
                            setLoginModalOpen={setLoginModalOpen}
                            onClose={handleModalClose}
                        />
                    )}
    </div>
  );
};

export default CryptoAlerts;
