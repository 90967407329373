import React, {useState, useEffect, useMemo, useRef, useCallback} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {addWithdrawrewards, getNormalUserData, getUserReward} from "../../../apihelper/portfolio";
import {Alert, Card, CardContent, CircularProgress, Grid, Snackbar, Typography, useMediaQuery} from "@mui/material";
import json2mq from "json2mq";
import {CheckCircle, Error} from "@mui/icons-material";
import LoginPopUp from "../../loginpopup";
import {ReactComponent as TransactionHandImage} from "../profile/profile_icon/tranction-hand-image.svg";
const TransactionCard2 = ({clickable = true}) => {
    const [userReward, setUserReward] = useState(null);
    const [errorReward, setErrorReward] = useState(false);
    const [walletModelOpen, setWalletModelOpen] = useState(false);
    const [isWalletAvailable, setIsWalletAvailable] = useState(false);
    const [walletAddress, setWalletAddress] = useState("");
    const [proceedMessage, setProceedMessage] = useState("");
    const [processErrorStatus, setProcessErrorStatus] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [isLoginModalOpen, setLoginModalOpen] = useState(false);
    const location = useLocation();
    const userData = useMemo(() => JSON.parse(localStorage.getItem("cw_portfolio_user")), []);
    const navigate = useNavigate();

    const mediaQueryVar = useMediaQuery(
        json2mq({
            minWidth: 900,
        })
    );
    const styles = useMemo(() => getStyles(mediaQueryVar), [mediaQueryVar]);

    const fetchUserRewards = useCallback(async () => {
        if (!userData) {
            setErrorReward(true);
        }
        try {
            const data = await getUserReward(userData);
            setUserReward(data);
            setErrorReward(false);
        } catch (error) {
            setErrorReward(true);
            console.error("Error fetching rewards:", error);
        }
    }, [userData]);

    useEffect(() => {
        fetchUserRewards();
    }, [fetchUserRewards]);

    const handleWithdraw = useCallback(async () => {
        if (!userData) {
            return;
        }
        const user = {user_id: userData.user_id, Token: userData.token};
        try {
            const userDetails = await getNormalUserData(user);
            setWalletModelOpen(true);
            if (userDetails?.data?.[0]?.wallet_address) {
                setIsWalletAvailable(true);
                setWalletAddress(userDetails.data[0].wallet_address);
            } else {
                setIsWalletAvailable(false);
            }
        } catch (error) {
            console.error("Error fetching wallet details:", error);
        }
    }, [userData]);

    const handleWithdrawProceed = async () => {
        try {
            const userDetails = await addWithdrawrewards({
                user_id: userData.user_id,
                token: userData.token,
            });
            setUserReward((prev) => ({...prev, rewards: userDetails.rewards})); // Update state directly
            setProceedMessage(userDetails?.message);
            setProcessErrorStatus(false);
            setWalletModelOpen(false);
            setOpenAlert(true);
            fetchUserRewards();
        } catch (error) {
            setProceedMessage(error.message);
            setProcessErrorStatus(true);
            setOpenAlert(true);
            console.error("Error withdrawing rewards:", error.message);
        } finally {
            setWalletModelOpen(false);
        }
    };
    const handleModalClose = useCallback(() => {
        setLoginModalOpen(false);
        navigate(location.pathname, {replace: true});
    }, [navigate, location.pathname]);

    const navigateToRewardHistory = useCallback(() => navigate("/reward-transaction-history"), [navigate]);

    return (
        <>
            <Grid
                item
                xs={12}
                style={{
                    borderRadius: "5px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                }}
            >
                <div className="d-flex  align-items-center justify-content-between gap-3">
                    {/* First Card */}
                    <Card className="reward-card w-100">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                padding: "3px"
                            }}
                            onClick={navigateToRewardHistory}
                        >
                            <TransactionHandImage alt="Hand with coins" width="87px" height="87px" />

                            <p className="transaction-text">
                                Airdrop Reward:
                                {mediaQueryVar === false && <br/>}
                                
                                <span style={{fontWeight: "500"}}>
                                    {!errorReward ? (
                                        userReward?.rewards === undefined || userReward?.rewards === null ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    margin: "20px 0",
                                                }}
                                            >
                                                <CircularProgress />
                                            </div>
                                        ) : (
                                            userReward?.rewards
                                        )
                                    ) : (
                                        "Not Found"
                                    )}
                                </span>
                            </p>
                        </div>
                    </Card>

                    {/* Second Card */}
                    <Card className="reward-card w-100">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                padding: "3px"
                            }}
                            onClick={navigateToRewardHistory}
                        >
                            <img
                                src="/images/profile_image/account_image/clockicon-1.png"
                                alt="Hand with coins"
                                width="87px"
                                height="87px"
                                style={{alignSelf: "center", padding: "5px"}}
                            />
                            <p className="transaction-text">
                                Withdrawal History:
                                {mediaQueryVar === false && <br/>}
                                <span style={{fontWeight: "500"}}>
                                    {!errorReward ? (
                                        userReward?.withdraw_rewards === undefined ||
                                        userReward?.withdraw_rewards === null ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    margin: "20px 0",
                                                }}
                                            >
                                                <CircularProgress />
                                            </div>
                                        ) : (
                                            userReward?.withdraw_rewards
                                        )
                                    ) : (
                                        "Not Found"
                                    )}
                                </span>
                            </p>
                        </div>
                    </Card>
                </div>
            </Grid>

            {walletModelOpen && (
                <div style={styles.overlay}>
                    <div style={styles.popupContainer}>
                        <div style={{display: "flex", justifyContent: "flex-end"}}>
                            <img
                                className="frameIcon"
                                alt="close"
                                onClick={() => {
                                    setWalletModelOpen(false);
                                }}
                                src={`${window.constants.asset_path}/images/frame17.svg`}
                                style={{
                                    background: "#F3F3F3",
                                    width: "30px",
                                    height: "30px",
                                    marginTop: "10px",
                                    marginRight: "10px",
                                }}
                            />
                        </div>
                        <div style={styles.popupContent}>
                            <img
                                src={`${window.constants.asset_path}/images/login.png`}
                                alt="Wise coins logo"
                                style={styles.image}
                            />
                            <div style={styles.text}>
                                {isWalletAvailable ? (
                                    <div style={{textAlign: "center", fontSize: "16px", color: "#333"}}>
                                        ✅ <b>Your Wallet Address</b>: <br />
                                        <span
                                            style={{
                                                display: "inline-block",
                                                padding: "8px 12px",
                                                background: "#f3f3f3",
                                                borderRadius: "6px",
                                                marginTop: "5px",
                                                fontSize: "14px",
                                                wordBreak: "break-all",
                                            }}
                                        >
                                            {walletAddress}
                                        </span>
                                        <br />
                                        <br />⚡ <b>Please verify your wallet address before proceeding.</b> <br />
                                        <span style={{fontSize: "14px", color: "#555"}}>
                                            If this wallet address is incorrect, please update it in your profile
                                            settings.
                                        </span>
                                    </div>
                                ) : (
                                    <div style={{textAlign: "center", fontSize: "16px", color: "#333"}}>
                                        ⚠️ <b>Wallet Address Not Found</b> <br />
                                        <span style={{fontSize: "14px", color: "#555"}}>
                                            You have not added a wallet address yet. Please set one in your profile
                                            settings to proceed.
                                        </span>
                                        <br />
                                        <br />
                                        🔒{" "}
                                        <i>Ensure that you enter a valid wallet address to receive rewards securely.</i>
                                    </div>
                                )}
                            </div>
                            {isWalletAvailable ? (
                                <div className="d-flex justify-content-center mt-1">
                                    <button
                                        type="button"
                                        className="btn btn-primary w-100"
                                        onClick={() => handleWithdrawProceed()}
                                    >
                                        Proceed
                                    </button>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            )}
            {openAlert && (
                <Snackbar
                    anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                    open={openAlert}
                    onClose={() => setOpenAlert(false)}
                    autoHideDuration={3000}
                    key={"bottom-center"}
                >
                    <Alert
                        onClose={() => setOpenAlert(false)}
                        severity={processErrorStatus ? "error" : "success"}
                        icon={
                            processErrorStatus ? <Error sx={{color: "red"}} /> : <CheckCircle sx={{color: "green"}} />
                        }
                        sx={{
                            backgroundColor: "white",
                            color: processErrorStatus ? "red" : "green", // Red text for error, green for success
                            fontWeight: "bold",
                        }}
                    >
                        {proceedMessage}
                    </Alert>
                </Snackbar>
            )}
            {isLoginModalOpen && (
                <LoginPopUp
                    isLoginModalOpen={isLoginModalOpen}
                    setLoginModalOpen={setLoginModalOpen}
                    onClose={handleModalClose}
                />
            )}
        </>
    );
};

// Example function for styles based on media query
const getStyles = (mediaQueryVar) => ({
    overlay: {
        position: "fixed",
        top: mediaQueryVar ? 0 : "auto",
        bottom: mediaQueryVar ? "auto" : "30%",
        left: 0,
        width: "100vw",
        height: mediaQueryVar ? "100%" : "auto",
        backgroundColor: mediaQueryVar ? "rgba(0, 0, 0, 0.8)" : "rgba(0, 0, 0, 0.0)",
        display: "flex",
        justifyContent: "center",
        alignItems: mediaQueryVar ? "center" : "flex-end",
        zIndex: 1001,
        paddingBottom: 0,
    },
    popupContainer: {
        width: mediaQueryVar ? "480px" : "90%",
        padding: mediaQueryVar ? "10px" : "0px",
        background: "linear-gradient(90deg, #9AEB37 0%, #FFF73F 100%)",
        borderRadius: "24px",
        textAlign: "center",
        paddingTop: "0px",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        height: mediaQueryVar ? "425px" : "330px",
    },
    popupContent: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "78%",
    },
    image: {
        width: mediaQueryVar ? "160px" : "130px",
        height: mediaQueryVar ? "160px" : "130px",
        padding: "10px",
        borderRadius: "50%",
    },
    text: {
        fontSize: "20px",
        color: "rgb(24, 119, 242)",
        marginBottom: "0px",
        lineHeight: "24px",
        fontWeight: "700",
    },
});
export default TransactionCard2;
