import React, { use, useEffect } from "react";
import "./portfolioNetworthGraph.css";
import {Helmet} from "react-helmet";
import Header2 from "../../common/header/Header2";
import MainContent from "./others/MainContent";
import LoginPopUp from "../loginpopup";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import BottomNavigationApp from "./others/BottomNavigation";
import { isDesktop } from "../../common/constants";
import json2mq from "json2mq";
import { useMediaQuery } from "@mui/material";
import {ReactComponent as NotificationIcon} from "../../pages/account/profile/profile_icon/notification-icon.svg"

const PortfolioNetworthGraph = (props) => {
    const [isLoginModalOpen, setLoginModalOpen] = React.useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const {indexName} = useParams();
    const index = indexName ? indexName.split("-")[0] : "Crypto";
    const [index_name, setIndexName] = React.useState(index);
    const mediaQueryVar = useMediaQuery(json2mq(isDesktop));   

    const handleModalClose = () => {
        // Close the modal and reset the URL to the welcome page
        setLoginModalOpen(false);
        navigate(location.pathname, {replace: true});
    };

    React.useEffect(() => {  
        const index = indexName ? indexName.split("-")[0] : "Crypto";
        setIndexName(index)
    },[indexName])

    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));

    const [unreadAlerts, setUnreadAlerts] = React.useState(0);
    useEffect(() => {
    
                const userId = userData?.user_id || null;
    
                const fetchAlertsCount = async () => {
                  try {
                    const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/crypto_alerts_count', {
                      method: "POST", // Use POST instead of GET
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({ user_id: userId }), // Send user_id in the body
                    });
              
                    if (!response.ok) {
                      throw new Error(`Error: ${response.status}`);
                    }
              
                    const data = await response.json();
                    console.log(data);
                    setUnreadAlerts(data.unread_notifications);    
    
                  } catch (error) {
                    console.error("Failed to fetch alerts:", error);
                  } finally {
                  }
                };
              
                fetchAlertsCount();
              }
              , []);


    return (
        <div className="Indices-page">
            <Helmet>
                <title>Crowdwisdom360 {index_name} Indices</title>
            </Helmet>
            <div>
                <Header2 index={index_name}>
                    <h6
                        style={{
                            textAlign: mediaQueryVar ? "left" : "center", // Simplified the condition
                            paddingLeft: mediaQueryVar ? "340px" : "0", // Adjust padding based on screen size
                        }}
                    >
                        <span
                            style={{
                                fontSize: "24px",
                                fontFamily: "Satoshi,sans-serif",
                                fontWeight: mediaQueryVar ? "700" : "",
                            }}
                        >
                            Cryptocurrency
                            {mediaQueryVar ? (
                                "Indices"
                            ) : (
                                <span className="bg-primary px-2 py-1 rounded text-white">Indices</span>
                            )}
                        </span>
                    </h6>
                    <div
  className="notification-section position-relative"
  onClick={() => navigate("/alerts")}
  style={{
    marginLeft: "auto",  // pushes it closer to the right
    marginRight: "5px", // optional tweak
    cursor: "pointer"
  }}
>
  <NotificationIcon className="notification-icon fa-2x" />
  <span className="badge rounded-pill badge-notification bg-danger position-absolute top-0 start-100">
    {unreadAlerts}
  </span>
</div>


                </Header2>
                <MainContent setIndexName={setIndexName} index={index_name} setLoginModalOpen={setLoginModalOpen}/>
                <BottomNavigationApp setLoginModalOpen={setLoginModalOpen} />
                {isLoginModalOpen && (
                    <LoginPopUp
                        // isLoginModalOpen={isLoginModalOpen}
                        setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
                        onClose={handleModalClose}
                    />
                )}
            </div>
        </div>
    );
};

export default PortfolioNetworthGraph;
