import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';
import './influencerpredictions.css'; // Assuming you have a CSS file for styling
import { fontSize } from '@mui/system';
import {ReactComponent as NotificationIcon} from "../../pages/account/profile/profile_icon/notification-icon.svg";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from '@mui/material/Tooltip';
import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { ButtonGroup,BottomNavigation,Paper,Chip } from "@mui/material";
import SidebarMenuList from "../../includes/sidebarMenuList.jsx";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Grid, useStepContext } from "@mui/material";
import Drawer from '@mui/material/Drawer';
import { Helmet } from 'react-helmet';
import { useLocation } from "react-router-dom";
import LoginPopUp from '../loginpopup';

const drawerWidth = 292;

const useStyles = styled((theme) => ({
  tableContainer: {
    maxWidth: 900,
    margin: "0 auto",
  },
  table: {
    display: "none", // Hide the table on all screens
    [theme.breakpoints.down("sm")]: {
      display: "table", // Show the table on small screens only (mobile view)
    },
  },
}));

const InfluencerPredictions = (props) => {
  const { window1 } = props;
  const { crypto } = useParams();
  const { portfolioName } = useParams();
  const mPortfolioName = portfolioName.replace(/-/g, ' ');
  console.log("Influcencer name : ",mPortfolioName);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  const handleLoginClick = () => {
    // Mask the URL by adding /login at the end without navigating
    window.history.pushState({}, "", "/login");
    localStorage.setItem("isPopupManuallyOpened", "true");
    // Open the login modal
    setLoginModalOpen(true);
  };
  const handleModalClose = () => {
    // Close the modal and reset the URL to the welcome page
    setLoginModalOpen(false);
    navigate(location.pathname, { replace: true });
  };
   
  const [dropdownVisible, setDropdownVisible] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortType, setSortType] = useState('fd_asc');
  const [sortDropdownVisible, setSortDropdownVisible] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [slug, setSlug] = useState(false);
  const [sevenDays, setSevenDays] = useState(false);
  const [thirtyDays, setThirtyDays] = useState(false);
  const [yearDays, setYearDays] = useState(false);
  const [buyPercent, setBuyPercent] = useState(false);
  const [holdings, setHoldings] = useState(false);
  const [symbolLogo, setSymbolLogo] = useState(false);
  const [currentPrice, setCurrentPrice] = useState(false);
  const [rowsToShow, setRowsToShow] = useState(7);
  const [selectedSort, setSelectedSort] = useState('');
  const sortRef = useRef();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation(); 
  const loc = location.pathname;
  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 900,
    })
  );

  const mediaQueryVar1 = useMediaQuery(
    json2mq({
      minWidth: 400,
    })
  );

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: 0,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
   
    "& td, & th": {
      border: 0,

    },
  }));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
};

const drawer = (
    <div className='d-flex flex-column h-100' style={{overflowY:'hidden'}}>
<SidebarMenuList/>
    </div>
);


const container = window1 !== undefined ? () => window1().document.body : undefined;

  useEffect(() => {
    const fetchData = async () => {
      console.log(mPortfolioName+' hello');
      try {
        const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/showCryptoFutureDataInvesting', { // Replace with your actual API endpoint
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            forecasterName: mPortfolioName, // Adjust if necessary
            sortType: 'desc'
          }),
        });

        const result = await response.json();
       console.log("Crypto Data",result);

        if (result.code === '200') {
          setLoader(true);
          setSlug(result.symbol);
          setCurrentPrice(result.current_price);
          setSymbolLogo(result.symbol_logo);
          setSevenDays(result.last_7_days);
          setThirtyDays(result.last_30_days);
          setYearDays(result.last_365_days);
          setBuyPercent(result.buy_percent);
          setHoldings(result.holdings);
          setData(result.response);
          console.log(result.response);
        } else {
          setError(result.message);
        }
      } catch (err) {
        setError('An error occurred while fetching data.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [mPortfolioName]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sortRef.current && !sortRef.current.contains(event.target)) {
        setSortDropdownVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = (index) => {
    setDropdownVisible(dropdownVisible === index ? null : index);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase()); // Convert to lowercase for case-insensitive search
  };
  

  const handleSortChange = (sortType) => {
    setSortType(sortType);
    setSortDropdownVisible(false);
    setSelectedSort(sortType);
  };

  const openModal = async (data) => {
    if (data.accuracy.toLowerCase() !== 'pending') {
      try {
        const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/showCryptoPastData', { // Replace with your actual API endpoint
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            searchVal: data.crypto_symbol,
            forecasterName: data.forecaster_name,
          }),
        });
  
        const result = await response.json();
       console.log("Past accuracy data: ",result);
  
        if (result.code === '200') {
          setModalData(result.response);
          setModalIsOpen(true);
        } else {
          setError(result.message);
        }
      } catch (err) {
        setError('An error occurred while fetching data.');
      }
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalData(null);
  };

  const filteredData = data.filter(forecaster =>
    forecaster.forecaster_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleLoadMore = () => {
    setRowsToShow(rowsToShow + 7);
  };

  const parseDate = (dateStr) => {
  const [month, day, year] = dateStr.split(' ');
  const monthMap = {
    Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06',
    Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12'
  };
  const formattedDate = `${year}-${monthMap[month]}-${day.padStart(2, '0')}`;
  return new Date(formattedDate);
};

const compareValues = (val1, val2, order = 'asc') => {
  if (val1 === 'Pending') return 1;  // Ensure 'Pending' is always last
  if (val2 === 'Pending') return -1; // Ensure 'Pending' is always last
  if (order === 'asc') return val1 > val2 ? 1 : val1 < val2 ? -1 : 0;
  return val1 < val2 ? 1 : val1 > val2 ? -1 : 0;
};

const sortedData = [...filteredData].sort((a, b) => {
  switch (sortType) {
    case 'fd_asc':
      return parseDate(a.target_date) - parseDate(b.target_date);
    case 'fd_desc':
      return parseDate(b.target_date) - parseDate(a.target_date);
    case 'ac_asc':
      return compareValues(a.accuracy, b.accuracy, 'asc');
    case 'ac_desc':
      return compareValues(a.accuracy, b.accuracy, 'desc');
    default:
      return 0;
  }
});

  const [sortOrders, setSortOrders] = useState({
    crypto_symbol: "asc",
    forecast_price: "asc",
    accuracy: "asc",
    target_percent: "asc",
    target_date: "asc",
  });
  
  const [sortColumn, setSortColumn] = useState("target_date");
  

  const sortedDataArray = [...data]
  .filter((item) => 
    item.crypto_symbol.toLowerCase().includes(searchTerm) // Filter based on search term
  )
  .sort((a, b) => {
    const parseValue = (value, column) => {
      if (column === "forecast_price") {
        // Remove '$' and ',' then convert to float
        return parseFloat(value.replace("$", "").replace(/,/g, ""));
      }
      if (column === "target_percent") {
        // Remove '%' and convert to float
        return parseFloat(value.replace("%", ""));
      }
      return value; // Return as-is for other columns
    };

    const valueA = parseValue(a[sortColumn], sortColumn);
    const valueB = parseValue(b[sortColumn], sortColumn);

    if (sortOrders[sortColumn] === "asc") {
      return valueA > valueB ? 1 : -1;
    } else {
      return valueA < valueB ? 1 : -1;
    }
  });

  const handleSort = (column) => {
    if (column === sortColumn) {
      // Toggle sort order for the same column
      setSortOrders((prevOrders) => ({
        ...prevOrders,
        [column]: prevOrders[column] === "asc" ? "desc" : "asc",
      }));
    } else {
      // Reset sorting for the new column
      setSortColumn(column);
      setSortOrders((prevOrders) => ({
        ...prevOrders,
        [column]: "asc", // Default to ascending for the new column
      }));
    }
  };

  const getArrow = (column) => {
    if (sortOrders[column] === "asc") {
      return "↑"; // Ascending arrow
    } else {
      return "↓"; // Descending arrow
    }
  };

  const [unreadAlerts, setUnreadAlerts] = useState(0);
    useEffect(() => {
    
                const userId = userData?.user_id || null;
    
                const fetchAlertsCount = async () => {
                  try {
                    const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/crypto_alerts_count', {
                      method: "POST", // Use POST instead of GET
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({ user_id: userId }), // Send user_id in the body
                    });
              
                    if (!response.ok) {
                      throw new Error(`Error: ${response.status}`);
                    }
              
                    const data = await response.json();
                    console.log(data);
                    setUnreadAlerts(data.unread_notifications);    
    
                  } catch (error) {
                    console.error("Failed to fetch alerts:", error);
                  } finally {
                  }
                };
              
                fetchAlertsCount();
              }
              , []);
  
  return (
    <div className="crypto-page">
       <Helmet>
       <title>{mPortfolioName} Predictions With Accuracy</title>
       </Helmet>
      <Grid item xs={12} align="left">
    <div style={{ 
        display: "flex", 
        alignItems: "center", 
        justifyContent: "space-between", // Changed from space-between to flex-end
        marginTop: "0px", 
        marginBottom: "50px",
        marginLeft: "5px"
    }}>
    {mediaQueryVar===false ? (
        <img src={window.constants.asset_path + '/images/cwbeta.png'} alt="Logo" className="button-logo" />
    ) : (null)}


<div style={{display:"flex", alignItems:"center"}}>
                                   <div className="notification-section position-relative" onClick={() => navigate("/alerts")}>
                                        <NotificationIcon className="notification-icon fa-2x" />
                                        <span className="badge rounded-pill badge-notification bg-danger position-absolute top-0 start-100 ">
                                              {unreadAlerts}
                                        </span>
                                    </div>
                                  
        <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none" } }}
        >
            <img 
                src='/images/menubar.svg'
                alt="Menu Icon" 
                style={{ 
                    width: "24px", 
                    height: "24px",
                    marginRight: "10px",
                    marginTop: "5px" 
                }} 
            />
        </IconButton>
        </div>
    </div>
</Grid>
<Drawer
    container={container}
    variant="temporary"
    open={mobileOpen}
    onClose={handleDrawerToggle}
    ModalProps={{
        keepMounted: true, // Better open performance on mobile.
    }}
    sx={{
        display: { xs: 'block', sm: 'none' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: "400", backgroundColor: 'white', color: '#727376' },
    }}
>
    {drawer}
</Drawer>

      

{mediaQueryVar ? ( null) : (

      <div className="search-sort-row" >
        <input
          type="text"
          placeholder="Search Crypto"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        
      </div>
)}
      {mediaQueryVar ? (
        
        <div className="layout">
          <div className>
          <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}

                    sx={{

                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white', color: '#727376' },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{

                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white', color: '#727376'},
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            </div>
            
        <div className="content">
       
        <div className="search-sort-row" style={{marginTop:"-50px", gap:"0px"}}>
        

      <input
        type="text"
        placeholder="Search Crypto"
        className="search-input"
        style={{  padding: "10px",background:"white",
          margin: "15px 15px 15px 0px",
        marginRight:"0px"}}
        onChange={handleSearchChange}
      />
                                  <div 
  className="notification-section position-absolute" 
  style={{ right: "40px" }}  
  onClick={() => navigate("/alerts")}
>
  <NotificationIcon className="notification-icon fa-2x" />
  <span className="badge rounded-pill badge-notification bg-danger position-absolute top-0 start-100">
    {unreadAlerts}
  </span>
</div>

      
    </div>
        <div>
        <table className="predictions-table">
        <thead>
        <tr>
          <th
            style={{
              textAlign: "left",
              width: "15%",
              fontWeight: "500",
              color: "#727376",
              fontSize: "16px",
              cursor: "pointer",
            }}
            onClick={() => handleSort("crypto_symbol")}
          >
            Crypto {getArrow("crypto_symbol")}
          </th>
          <th
            style={{
              textAlign: "center",
              fontWeight: "500",
              color: "#727376",
              fontSize: "16px",
              cursor: "pointer",
            }}
            onClick={() => handleSort("forecast_price")}
          >
            Target {getArrow("forecast_price")}
          </th>
          <th
            style={{
              textAlign: "center",
              fontWeight: "500",
              color: "#727376",
              fontSize: "16px",
              cursor: "pointer",
            }}
            onClick={() => handleSort("accuracy")}
          >
            Past Accuracy {getArrow("accuracy")}
          </th>
          <th
            style={{
              textAlign: "center",
              fontWeight: "500",
              color: "#727376",
              fontSize: "16px",
              cursor: "pointer",
            }}
            onClick={() => handleSort("target_percent")}
          >
            %Target Achieved {getArrow("target_percent")}
          </th>
          <th
            style={{
              textAlign: "center",
              fontWeight: "500",
              color: "#727376",
              fontSize: "16px",
              cursor: "pointer",
            }}
            onClick={() => handleSort("target_date")}
          >
            Forecast For {getArrow("target_date")}
          </th>
          <th></th>
        </tr>
      </thead>
        <tbody>
        {sortedDataArray.map((data, index) => (
          <tr key={index}>
            <td
              style={{
                width: "23%",
                background:
                  "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                borderBottom: "6px solid #fff",
                padding: "5px 7px",
                color: "#000",
                fontSize: "16px",
                fontWeight: "500",
                marginLeft: "5px",
              }}
            >
              {data.crypto_symbol}
            </td>
            <td
              style={{
                textAlign: "center",
                color: "black",
                fontWeight: "500",
                fontSize: "14px",
                paddingLeft: "10px",
              }}
            >
              {data.forecast_price}
            </td>
            <td style={{ textAlign: "center" }} onClick={() => openModal(data)}>
              <span
                className={
                  data.accuracy.toLowerCase() !== "pending"
                    ? "clickable"
                    : "pending"
                }
              >
                {data.accuracy}
              </span>
            </td>
            <td
              style={{
                textAlign: "center",
                color: "black",
                fontWeight: "500",
                fontSize: "14px",
                paddingLeft: "10px",
              }}
            >
              {data.target_percent}
            </td>
            <td
              style={{
                textAlign: "center",
                color: "black",
                fontWeight: "500",
                fontSize: "14px",
                paddingLeft: "10px",
              }}
            >
              {data.target_date}
            </td>
            <td>
              <div className="dropdown">
                <button
                  style={{ width: "20px" }}
                  onClick={() => toggleDropdown(index)}
                  className="dropdown-button"
                >
                  <img
                    src={window.constants.asset_path + "/images/more.png"}
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "5px",
                    }}
                    alt="More"
                  />
                </button>
                {dropdownVisible === index && (
                  <div className="dropdown-content">
                    <a href={data.source_link} target="_blank">
                      <img
                        src={
                          window.constants.asset_path +
                          "/images/external-link-symbol.png"
                        }
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "5px",
                        }}
                        alt="Copy"
                      />
                      Source link
                    </a>
                  </div>
                )}
              </div>
            </td>
          </tr>
        ))}
          {rowsToShow < sortedData.length && (
              <StyledTableRow>
                <StyledTableCell colSpan={7} align="center">
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button onClick={handleLoadMore} 
                    className="load-more"
                    style={{ 
                      maxWidth: "360px",
                      width: "80vw",
                      backgroundColor: "rgba(67, 97, 238, 0.15)",
                      borderRadius: "6px",
                      border: "none",
                      textTransform: "none",
                      borderStyle: "solid",
                      borderColor: "rgba(67, 97, 238, 0.15)",
                      borderWidth: "1px",
                      color: "#4361ee",
                      width: "15%",
                      height: "35px",
                      cursor: "pointer" }}>
                      Explore More
                    </button>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            )}
        </tbody>
      </table>
      </div>
      </div>
      </div>
      ) : (
      <div style={{ position: 'relative', overflowX: 'auto', marginBottom:"12%" }}>
      <table className="predictions-table">
      <thead>
        <tr>
          <th
            style={{minWidth: mediaQueryVar1===false ? "" : "150px" ,textAlign:"left", fontWeight: "500", color: "#727376", fontSize: "16px"}}
            onClick={() => handleSort("crypto_symbol")}
          >
            Crypto {getArrow("crypto_symbol")}
          </th>
          <th
            style={{
              textAlign: "center",
              fontWeight: "500",
              color: "#727376",
              fontSize: "16px",
              cursor: "pointer",
            }}
            onClick={() => handleSort("forecast_price")}
          >
            Target {getArrow("forecast_price")}
          </th>
          <th
            style={{
              textAlign: "center",
              fontWeight: "500",
              color: "#727376",
              fontSize: "16px",
              cursor: "pointer",
            }}
            onClick={() => handleSort("accuracy")}
          >
            Past Accuracy {getArrow("accuracy")}
          </th>
          <th
            style={{
              textAlign: "center",
              fontWeight: "500",
              color: "#727376",
              fontSize: "16px",
              cursor: "pointer",
            }}
            onClick={() => handleSort("target_percent")}
          >
            %Target Achieved {getArrow("target_percent")}
          </th>
          <th
            style={{
              textAlign: "center",
              fontWeight: "500",
              color: "#727376",
              fontSize: "16px",
              cursor: "pointer",
            }}
            onClick={() => handleSort("target_date")}
          >
            Forecast For {getArrow("target_date")}
          </th>
          <th></th>
        </tr>
      </thead>
        <tbody>
        {sortedDataArray.map((data, index) => (
          <tr key={index}>
            <td
              style={{
                width: "23%",
                background:
                  "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                borderBottom: "6px solid #fff",
                padding: "5px 7px",
                color: "#000",
                fontSize: "16px",
                fontWeight: "500",
                marginLeft: "5px",
              }}
            >
              {data.crypto_symbol}
            </td>
            <td
              style={{
                textAlign: "center",
                color: "black",
                fontWeight: "500",
                fontSize: "14px",
                paddingLeft: "10px",
              }}
            >
              {data.forecast_price}
            </td>
            <td style={{ textAlign: "center" }} onClick={() => openModal(data)}>
              <span
                className={
                  data.accuracy.toLowerCase() !== "pending"
                    ? "clickable"
                    : "pending"
                }
              >
                {data.accuracy}
              </span>
            </td>
            <td
              style={{
                textAlign: "center",
                color: "black",
                fontWeight: "500",
                fontSize: "14px",
                paddingLeft: "10px",
              }}
            >
              {data.target_percent}
            </td>
            <td
              style={{
                textAlign: "center",
                color: "black",
                fontWeight: "500",
                fontSize: "14px",
                paddingLeft: "10px",
              }}
            >
              {data.target_date}
            </td>
            <td>
              <div className="dropdown">
                <button
                  style={{ width: "20px" }}
                  onClick={() => toggleDropdown(index)}
                  className="dropdown-button"
                >
                  <img
                    src={window.constants.asset_path + "/images/more.png"}
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "5px",
                    }}
                    alt="More"
                  />
                </button>
                {dropdownVisible === index && (
                  <div className="dropdown-content">
                    <a href={data.source_link} target="_blank">
                      <img
                        src={
                          window.constants.asset_path +
                          "/images/external-link-symbol.png"
                        }
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "5px",
                        }}
                        alt="Copy"
                      />
                      Source link
                    </a>
                  </div>
                )}
              </div>
            </td>
          </tr>
        ))}
          {rowsToShow < sortedData.length && (
              <StyledTableRow>
                <StyledTableCell colSpan={7} align="center">
          <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
            <button
              onClick={handleLoadMore}
              className="load-more"
              style={{
                backgroundColor: "rgba(67, 97, 238, 0.15)",
                borderRadius: "6px",
                border: "none",
                textTransform: "none",
                borderStyle: "solid",
                borderColor: "rgba(67, 97, 238, 0.15)",
                borderWidth: "1px",
                color: "#4361ee",
                height: "35px",
                cursor: "pointer",
                marginTop:"15px"
              }}
            >
              Explore More
            </button>
          </div>
        </StyledTableCell>
        </StyledTableRow>
            )}
        </tbody>
      </table>
      </div>
      )}
      {mediaQueryVar === false && (


<div
  style={{
    position:  "fixed",
    bottom: "0",
    backgroundColor: "white",
    width: "100%",
    zIndex: "100",
    marginBottom:"0px",
    marginLeft:"-20px"
  }}
>
<BottomNavigation showLabels>
  <BottomNavigationAction
        label="Top Portfolios"
        onClick={() =>
          navigate("/portfolio-gallery")
        }
        icon={<img src={loc === '/portfolio-gallery' ? "/images/dashboardS.svg" : "/images/dashboardU.svg"} />}
        
      />
      <BottomNavigationAction
        label="My Portfolios"
        onClick={() => {
          navigate(userData ? "/my-portfolio" : "/audit-my-portfolio");
        }}
        icon={<img src={loc === '/my-portfolio' ? "/images/portfolioS.svg" : "/images/portfolioU.svg"}  />}
      />
    <BottomNavigationAction
              label="$1/Month Subscription"
              onClick={() => {
                if (userData) {
                  navigate("/subscriptions/screener360");
                } else {
                  const originalUrl = '/subscriptions/screener360';
                  sessionStorage.setItem("originalUrl", originalUrl);
                  console.log("original url: " + originalUrl);
                  handleLoginClick();
                }
              }}
              icon={
                <img
                  src={
                    
                       loc === "/subscriptions/screener360"
                        ? "/images/dollarS.svg"
                        : "/images/dollarU.svg"
                     
                  }
                  width={24}
                  height={24}
                  alt="Icon"
                  style={{ marginBottom: "-3px" }}
                />
              }
            />
</BottomNavigation>
</div>
)}
{isLoginModalOpen && (
        <LoginPopUp 
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}
      <Modal 
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1000,
          marginLeft:mediaQueryVar===true ? "20%" :""
        },
        content: {
          align:"center",
          width:"80%",
          marginBottom:"20%",
          height:"auto",
          borderRadius: '8px',
          position: 'center',
        }
      }}
       isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Accuracy Details">
        <img
            className="frameIcon"
            alt=""
            onClick={closeModal}
            src="/images/frame17.svg"
            style={{ background: "#F3F3F3", width: "25px", height: "25px", marginTop:"1.5%", marginBottom:"1%"
            }}
         />
        <h4>Past Accuracy</h4>
        {modalData ? (
          <table className="modal-table">
            <thead>
              <tr>
                <th>Published On</th>
                <th>Forecast Date</th>
                <th style={{textAlign:"center"}}>Forecast Price</th>
                <th style={{textAlign:"center"}}>Accuracy</th>
              </tr>
            </thead>
            <tbody>
              {modalData.map((detail, index) => (
                <tr key={index}>
                  <td>{detail.publish_date}</td>
                  <td >{detail.target_date} 11:59 PM</td>
                  <td style={{textAlign:"center"}}>{detail.forecast_price}</td>
                  <td style={{textAlign:"center"}}>{detail.accuracy}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No data available</p>
        )}
      </Modal>
    
    </div>
  );
      
};

export default InfluencerPredictions;